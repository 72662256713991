<template>
    <div class="main-block cart-special-bottom-and-marging loading-container" :class="{'loading-container-transparent': transparent}">
        <div class="spinner-border" style="width: 4rem; height: 4rem; color: #2196f3" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "c-loading-in-card",
        props: {
            transparent: {
                type: Boolean,
                default() {
                    return false
                }
            }
        }
    }
</script>

<style scoped>
.loading-container {
    box-shadow: none;
    position:absolute;
    left:0;
    top:0;
    height: 100%;
    width: 100%;
    z-index: 500;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
}

    .loading-container-transparent {
        background-color: rgba(255,255,255,0.7);
    }
</style>