<template>
    <div @click="openDetail" class="catalogue-main-container-item animate__animated animate__zoomIn">
        <c-fav-toggler
                :product="product"
                :key="favTogglerKey"
        >
        </c-fav-toggler>
        <div class="catalogue-main-container-item-head">
            <p class="catalogue-main-container-item-title">{{ product.name }}</p>
            <img class="catalogue-main-container-item-image" v-lazy="product.picture_url">
        </div>
        <div class="catalogue-main-container-item-prices">
            <p v-if="product.is_personal === 1" class="catalogue-main-container-item-prices-price-old">{{ product.base_price | splitDigits }} p.</p>
            <p class="catalogue-main-container-item-prices-price" :class="{'catalogue-main-container-item-prices-price-special': product.is_personal === 1}">{{ product.price | splitDigits }} p.</p>
        </div>
        <div class="catalogue-main-container-item-category">
            <p class="catalogue-main-container-item-category-category">{{ product.category.name }}</p>
            <p class="catalogue-main-container-item-category-country">{{product.country.name }}</p>
        </div>
        <div class="catalogue-main-container-item-fields">
            <p>
<!--                <span class="catalogue-main-container-item-fields-code">Арт.:</span>-->
                {{product.code}}
            </p>
            <p>{{product.volume}} л</p>
        </div>
        <div class="catalogue-main-container-item-bottom">
            <p v-if="addAllowed" class="catalogue-main-container-item-bottom-available">
                в наличии
            </p>
            <p v-else class="catalogue-main-container-item-bottom-unavailable">
                нет в наличии
            </p>
            <c-cart-controls
                :product="product"
                :key="cartControlsKey"
            >
            </c-cart-controls>
        </div>
    </div>
</template>

<script>
    import splitDigits from "@/utils/filters/splitDigits";
    import Cart from "@/utils/cart";
    import {mapMutations, mapGetters} from 'vuex';
    import eventHub from "@/utils/event_hub";
    import cCartControls from "@/components/products/cCartControls";
    import cProductDetail from "@/components/products/cProductDetail";
    import cFavToggler from "@/components/products/cFavToggler";
    import openDetailMixin from "@/utils/mixins/open_detail";

    export default {
        name: "c-product-item",
        props: {
            product_item: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        mixins: [openDetailMixin],
        computed: {
            ...mapGetters([
                'USER_INFO',
                'CART',
                'WINDOW_WIDTH'
            ])
        },
        data() {
            return {
                product: this.product_item,
                addAllowed: this.product_item.cart_add_params.allowed === 1,
                loading: false,
                loaded: false,
                favTogglerKey: 1,
                cartControlsKey: 1
            }
        },
        methods: {
            openDetail() {
                this.openDetailProduct(this.product.id)
            },
            favToggled(params) {
                if(params.id === this.product.id) {
                    this.product.is_favourite = params.state ? 1 : 0;
                    this.favTogglerKey++;
                }
            }
        },
        filters: {
            splitDigits
        },
        components: {
            cCartControls,
            cFavToggler
        },
        mounted() {
            this.cartControlsKey++;
            eventHub.$on('fav-toggled', this.favToggled)
        },
        destroyed() {
            eventHub.$off('fav-toggled', this.favToggled)
        }
    }
</script>

<style scoped>

</style>
