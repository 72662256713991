<template>
    <div class="filters-price">
        <p class="filters-title">{{ caption }}</p>
        <div class="filter-price-container">
            <div class="row">
                <div class="col-sm-12">
                    <label class="subheader-switch" style="margin-left: 1vw;">
                        <input @change="toggleItem" :name="name" type="checkbox">
                        <span class="subheader-slider round"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //    import ToggleButton from 'vue-js-toggle-button';
    export default {
        name: "c-toggle-filter",
        props: {
            caption: {
                type: String,
                default() {
                    return ''
                }
            },
            name: {
                type: String,
                default() {
                    return '';
                }
            },
        },
        data() {
            return {
                filter: {}
            }
        },
        methods: {
            toggleItem: function(event) {
                this.filter.name = event.target.name;
                this.filter.value = event.target.checked === true ? '1' : '';
                this.$emit('apply-filter', this.filter);
            }
        }
    }
</script>

<style scoped>

</style>