import Vue from "vue";
import VueRouter from "vue-router";
import cOrderList from "@/components/orders/cOrderList";
import cOrderDetail from "@/components/orders/cOrderDetail";
import cReport from "@/components/reports/cReport";
import cProductList from "@/components/products/cProductList";
import cCart from "@/components/cart/cCart";
import cLogin from "@/components/cLogin";
import cProfile from "@/components/profile/cProfile";
import cFinance from "@/components/finance/cFinance";
import cReports from "@/components/reports/cReports";
import cAdmMain from "@/components/adm/cAdmMain";
import cFeedback from "@/components/feedback/cFeedback";

import result from '@/utils/check'
import store from "@/store/store";
import eventHub from "@/utils/event_hub";
import Cart from "@/utils/cart";
import cError from "@/components/cError";


Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'root',
            component: cProductList
        },
        {
            path: '/products/',
            name: 'products',
            component: cProductList
        },
        {
            path: '/orders/',
            name: 'orders',
            component: cOrderList
        },
        {
            path: '/cart/',
            name: 'cart',
            component: cCart
        },
        {
            path: '/login/',
            name: 'login',
            component: cLogin
        },
        {
            path: '/profile/',
            name: 'profile',
            component: cProfile
        },
        {
            path: '/reports/',
            name: 'reports',
            component: cReports
        },
        {
            path: '/report/:slug',
            name: 'report',
            component: cReport
        },
        {
            path: '/feedback/',
            name: 'feedback',
            component: cFeedback
        },
        {
            path: '/adm/',
            name: 'adm',
            component: cAdmMain
        },
        {
            path: '/error/',
            name: 'error',
            component: cError
        }
    ]
});

/**
 * Маршрутизация с учетом логина. Если открываем страницу логина: метод result - это простая проверка логина
 * (в API запрос /check/). Если все в порядке - сразу редирект на страницу с товарами.
 * Если ответ 403 - остаемся на странице логина.
 *
 * Если идем на любую другую страницу - получаем user_info. Если 403 - отправляем на логин.
 * Если 500 - показываем страницу с ошибкой во весь экран (в компоненте cBase показываем вместо хедера, контента и
 * футера компонент cError).
 */
router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        result()
            .then(() => {
                store.commit('SET_LOGGED', true);
                if (store.state.user_info.user_type === 'supplier') {
                    next({name: 'adm'})
                } else {
                    next()
                }
                // else {
                //     next({name: 'products'})
                // }
            })
            .catch(() => {
                store.commit('SET_LOGGED', false);
                next()
            });
    }
    else if (from.name !== to.name || JSON.stringify(from.query) !== JSON.stringify(to.query)) {
        store.dispatch('GET_USER_INFO')
            .then(() => {
                if (store.state.user_info.username === 'admin') {
                    window.location = store.state.backend_host + '/admin/';
                }
                if (store.state.user_info.user_type === 'client' && (to.name === 'adm' || to.name === null)) {
                    next({name: 'products'})
                }
                if (store.state.user_info.user_type === 'supplier' && (to.name !== 'adm' || to.name === null)) {
                    next({name: 'adm'})
                }
                store.commit('SET_LOGGED', true);
                if (to.name !== 'adm') {
                    store.commit('SET_CART_LOADING', true);
                    store.dispatch('GET_CART').then(() => {
                        store.commit('SET_CART_LOADING', false)
                    })
                }
                next()
            })
            .then(() => {
                eventHub.$emit('user-loaded');
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    store.commit('SET_LOGGED', false);
                    next({name: 'login'})
                } else if (error.response.status >= 500) {
                    store.commit('SET_ERROR', true);
                    next()
                }
            });
    }
});

export default router
