<template>
    <div class="login">
        <div v-if="!LOGGED" class="login-content">
            <img class="login-content-logo" src="../assets/images/logo_long_welcome.png" alt="">
            <div class="login-content-form">
                <p>Войти в M-trade:</p>
                <form class="mt-2" action="" @keypress="authenticateOnEnter">
                    <imask-input
                        :mask="'+{7} (000) 000-00-00'"
                        :lazy="true"
                        :unmask="true"
                        @accept="setLogin"
                        type="tel"
                        class="login-content-form-input"
                        name="username"
                        placeholder="+7 ("
                    ></imask-input>
                    <input v-model="password" type="password" class="login-content-form-input" name="pwd" placeholder="Пароль"><br>
                    <div v-if="login_error" class="login-content-form-failed">{{error_message}}</div>
                    <button @click="authenticate" type="button" role="button" class="button button-fill login-content-form-button">Войти</button>
                </form>
            </div>
        </div>
        <div v-else class="login-content login-content-company-select">
            <img class="login-content-logo" src="../assets/images/logo_long_welcome.png" alt="">
            <h5>Пожалуйста, выберите компанию</h5>
            <div class="login-content-company-select-container animate__animated animate__fadeInLeft">
                <c-choose-company />
            </div>
        </div>
        <div v-if="!LOGGED" class="login-background">
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {saveToStorage} from "@/store/local_storage/local_storage";
import axiosClient from "@/utils/axios/axios";
import {IMaskComponent} from 'vue-imask';
import cChooseCompany from "@/components/common/cChooseCompany";

export default {
    name: "c-login",
    data() {
        return {
            username: '',
            password: '',
            login_error: false,
            error_message: ''
        }
    },
    computed: {
        ...mapGetters([
            'API_URL',
            'LOGGED'
        ])
    },
    methods: {
        ...mapMutations([
            'SET_API_KEY',
            'SET_LOGGED'
        ]),
        ...mapActions([
            'GET_USER_INFO'
        ]),
        setLogin(value) {
            this.username = value;
        },
        authenticate() {
            let formData = new FormData();
            formData.append('username', this.username);
            formData.append('password', this.password);
            formData.append('session_auth', '1');
            axiosClient({
                method: 'POST',
                url: this.API_URL + 'authenticate/',
                data: formData,
            })
                .then((response) => {
                    if (response.data.result === 'ok') {
                        if (response.data.api_key) {
                            saveToStorage('key', response.data.api_key);
                            this.SET_API_KEY(response.data.api_key)
                        }
                        this.SET_LOGGED(true);
                        if (response.data.user_type === 'supplier') {
                            this.$router.push({name: 'adm'})
                        }
                        else {
                            this.GET_USER_INFO();
                        }
                    }
                })
                .catch((error) => {
                    this.error_message = error.response.data !== '' ? error.response.data : 'Ошибка авторизации!';
                    this.login_error = true;
                })
        },
        authenticateOnEnter(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                this.authenticate();
            }
        }
    },
    mounted() {
        if (this.LOGGED) {
            this.GET_USER_INFO();
        }
    },
    components: {
        'imask-input': IMaskComponent,
        cChooseCompany
    }
}
</script>

<style scoped>

</style>
