<template>
    <div class="modal-internal-container orders-details">
        <c-loading-in-card :transparent="loading_inline" v-if="loading || loading_inline"></c-loading-in-card>
        <div class="modal-title">
            <button @click="close" class="close" data-dismiss="modal" aria-label="Close">
                <img class="close-cross" src="../../assets/images/x-mark-icon-w.png" alt="">
            </button>
            <p>
                <template v-if="!is_draft">ОФОРМЛЕННЫЙ ЗАКАЗ</template><template v-else>ОТЛОЖЕННЫЙ ЗАКАЗ</template>
            </p>
            <p class="modal-subtitle">
                № {{ORDER_DETAIL.number}}{{'\xa0'}}от{{'\xa0'}}{{ORDER_DETAIL.creation_date}}
            </p>
        </div>
        <div class="orders-details-content">
            <div class="orders-details-content-table">
                <div class="orders-details-content-table-row orders-details-content-table-head">
                    <div class="orders-details-content-table-index">
                        №
                    </div>
                    <div class="orders-details-content-table-name">
                        <span>позиция</span>
                        <div class="orders-details-content-table-divider"></div>
                    </div>
                    <div class="orders-details-content-table-fields">
                        <div class="orders-details-content-table-fields-volume">
                            объём
                        </div>
                        <div class="orders-details-content-table-fields-amount">
                            кол-во
                        </div>
                        <div class="orders-details-content-table-fields-price">
                            цена
                        </div>
                        <div class="orders-details-content-table-fields-total">
                            стоимость
                        </div>
                    </div>
                </div>
                <c-order-detail-product
                    v-for="(product, index) in ORDER_DETAIL.products"
                    :index="index"
                    :key="product.id"
                    :product-item="product"
                    :no_active_in_order="no_active_in_order"
                >
                </c-order-detail-product>
            </div>

            <div class="orders-details-content-divider"></div>
            <template v-if="is_draft">
                <div class="orders-details-content-sum">
                    <p>Сумма:</p>
                    <p>{{ORDER_DETAIL.total | splitDigits}} р.</p>
                </div>
            </template>
            <template v-else>
                <div class="orders-details-content-sum">
                    <p>Сумма плановая:</p>
                    <p>{{ORDER_DETAIL.total_plan | splitDigits}} р.</p>
                </div>
                <div class="orders-details-content-sum">
                    <p>Сумма фактическая:</p>
                    <p>{{ORDER_DETAIL.total_fact | splitDigits}} р.</p>
                </div>
            </template>
            <div class="orders-details-content-buttons">
                <button v-if="!is_draft" @click="exportPdf" class="button" aria-label="Export">экспорт в PDF</button>
                <button @click="moveToCart" class="button" type="button" role="button">{{repeat_order_text + '*'}}</button>
            </div>
            <h4>Внимание! При нажатии кнопки "{{repeat_order_text}}" перечисленные выше позиции будут добавлены в корзину (перед этим она будет очищена)!</h4>
            <p v-if="no_active_in_order" class="orders-details-content-warning mt-2">
                <img class="orders-details-content-warning-icon cursor-default" src="../../assets/images/red-triangle-exclamation-mark.png" alt=""><span>данный товар недоступен для повторного заказа.</span>
            </p>
            <div class="orders-details-content-divider"></div>
            <div class="orders-details-content-parameters">
                <template v-if="is_draft">
                    <p class="orders-details-content-parameters-item">
                        <span>Комментарий:</span> {{ORDER_DETAIL.comment}}
                    </p>
                </template>
                <template v-else>
                    <p class="orders-details-content-parameters-item">
                        <span>Дата доставки:</span> {{ORDER_DETAIL.execution_date}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>Время доставки:</span> {{ORDER_DETAIL.delivery_time}}
                    </p>
                    <p v-if="ORDER_DETAIL.forwarder_info.name !== ''" class="orders-details-content-parameters-item">
                        <span>Экспедитор:</span> {{ORDER_DETAIL.forwarder_info.name}}, {{ORDER_DETAIL.forwarder_info.phone}}
                    </p>
                    <p v-if="ORDER_DETAIL.forwarder_info.car_number !== ''" class="orders-details-content-parameters-item">
                        <span>Марка и номер машины:</span> {{ORDER_DETAIL.forwarder_info.car_name}}, {{ORDER_DETAIL.forwarder_info.car_number}}
                    </p>

                    <p class="orders-details-content-parameters-item mt-3">
                        <span>Состояние заказа:</span> {{ORDER_DETAIL.status.condition.name}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>Дата оплаты:</span> {{ORDER_DETAIL.payment_date}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>Вид платежа:</span> {{ORDER_DETAIL.payment_type.name}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>Способ платежа:</span> {{ORDER_DETAIL.payment_condition.name}}
                    </p>

                    <p class="orders-details-content-parameters-item mt-3">
                        <span>Юр. лицо:</span> {{ORDER_DETAIL.company.legal_name}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>ИНН/КПП:</span> {{ORDER_DETAIL.company.inn}}/{{ORDER_DETAIL.company.kpp}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>Фактический адрес:</span> {{ORDER_DETAIL.company.fact_address}}
                    </p>
                    <p class="orders-details-content-parameters-item">
                        <span>Комментарий:</span> {{ORDER_DETAIL.comment}}
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import axiosClient from "@/utils/axios/axios";
import splitDigits from "@/utils/filters/splitDigits";
import cOrderDetailProduct from "@/components/orders/cOrderDetailProduct";
import cLoadingInCard from "@/components/common/loading/cLoadingInCard";
import {closeByHashChange} from "@/utils/mixins/close_by_history";

export default {
    name: "c-order-detail",
    props: {
        orderId: {
            type: Number,
            default() {
                return Number(this.$route.params.slug)
            }
        },
        is_draft: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    mixins: [closeByHashChange],
    data() {
        return {
            loading: false,
            loading_inline: false,
            no_active_in_order: false,
            repeat_order_text: this.is_draft ? 'отправить в корзину' : 'повторить заказ',
        }
    },
    computed: {
        ...mapGetters([
            'ORDER_DETAIL',
            'CART',
            'API_URL'
        ])
    },
    methods: {
        ...mapActions([
            'GET_ORDER_DETAIL',
            'GET_ORDER_DRAFT_DETAIL'
        ]),
        close() {
            this.$modal.hide('modal_order_details')
        },
        closeByHistory(e) {
            this.close();
            this.closed_by_history = true
        },
        moveToCart() {
            this.CART.clear().then(() => {
                if (this.is_draft) {
                    this.CART.comment = this.ORDER_DETAIL.comment
                }
                let product_list = [];
                this.ORDER_DETAIL.products.forEach((el) => {
                    if (el.product_info.cart_add_params.allowed === 1 && el.product_info.is_active === 1) {
                        product_list.push(
                            {
                                product_info: el.product_info,
                                quantity: el.amount
                            }
                        )
                    }
                });
                this.CART.addBulk(product_list).then(() => {
                    this.closed_by_history = true;
                    this.$router.push('cart')
                    this.close();
                })
            });
        },
        loadData() {
            this.loading = true;
            if (this.is_draft) {
                this.GET_ORDER_DRAFT_DETAIL(this.orderId)
                    .then((response) => {
                        this.loading = false;
                        this.ORDER_DETAIL.products.forEach((el) => {
                            if (el.product_info.is_active === 0 || el.product_info.cart_add_params.allowed === 0) {
                                this.no_active_in_order = true
                            }
                        })
                    })
            } else {
                this.GET_ORDER_DETAIL(this.orderId)
                    .then((response) => {
                        this.loading = false;
                        this.ORDER_DETAIL.products.forEach((el) => {
                            if (el.product_info.is_active === 0 || el.product_info.cart_add_params.allowed === 0) {
                                this.no_active_in_order = true
                            }
                        })
                    })
            }
        },
        exportPdf() {
            this.loading_inline = true;

            let data = {
                order_id: this.ORDER_DETAIL.id,
            };
            axiosClient({
                method: 'POST',
                url: this.API_URL + 'orders/export/',
                data: data
            }).then((response) => {
                this.loading_inline = false;
                if(response.data.file !== undefined && response.data.file !== '') {
                    window.location.href = response.data.file
                }
            }).catch(() => {
                this.loading_inline = false;
            });

        },
    },
    filters: {
        splitDigits
    },
    components: {
        cOrderDetailProduct,
        cLoadingInCard
    },
    created() {
        this.window_state = {key: '#order_' + this.orderId};
    },
    mounted() {
        this.loadData()
    }
}
</script>

<style scoped>

</style>
