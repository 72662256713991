<template>
            <div class="modal-internal-container">
                <template v-if="order_result.errors.length > 0">
                    <h1>К сожалению, не удалось разместить заказ.</h1>
                    <p
                            v-for="(error, index) in order_result.errors"
                            :key="index"
                            class="text-center"
                    >{{error}}</p>
                </template>
                <template v-else-if="order_result.order_type === 'order'">
                    <h1>Спасибо за Ваш заказ!</h1>
                    <a @click.prevent="openDetail" class="modal-link" href="javascript:void(0)">
                        <img src="../../assets/images/link_icon.png" alt="">
                        Заказ № {{order_result.number}}
                    </a>
                    <p v-if="comment !== ''" class="text-center mt-3">{{comment}}</p>
                </template>
                <template v-else-if="order_result.order_type === 'draft'">
                    <h1>Ваш заказ сохранён в отложенные!</h1>
                    <a @click.prevent="openDetail" class="modal-link" href="javascript:void(0)">
                        <img src="../../assets/images/link_icon.png" alt="">
                        Отложенный заказ № {{order_result.number}}
                    </a>
                </template>
                <div class="modal-confirmation-buttons">
                    <button @click="close" class="button button-red" aria-label="Exit">закрыть</button>
                </div>
            </div>
</template>

<script>
    import cOrderDetail from "@/components/orders/cOrderDetail";
    export default {
        name: "c-order-result-modal",
        props: {
            order_result: {
                type: Object,
                default() {
                    return {
                        order_type: '',
                        number: '',
                        id: '',
                        errors: []
                    }
                }
            },
            comment: {
                type: String,
                default() {
                    return '';
                }
            }
        },
        methods: {
            close() {
                this.$modal.hide('modal_order_result')
            },
            openDetail(id) {
                this.$modal.hide('modal_order_result');
                this.$modal.show(
                    cOrderDetail,
                    {
                        orderId: this.order_result.id,
                        is_draft: this.order_result.order_type === 'draft'
                    },
                    {
                        name: 'modal_order_details',
                        resizable: false,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-cart-details', 'modal-mobile-fullscreen']
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>
