//import {saveToStorage, loadFromStorage} from "@/store/local_storage/local_storage";
import axiosClient from "@/utils/axios/axios";
import store from "@/store/store";

/**
 * Корзина. Хранится во Vuex в геттере CART. Инициализируется в роутере при каждом заходе на страницу.
 */
export default class Cart {
    constructor(supplierId, companyId, items){
        if (items !== undefined) {
            this.items = items
        } else {
            this.items = [];
        }
        this.comment = '';
        this.supplier_id = supplierId;
        this.company_id = companyId;
        this.name = 'cart_n_' + supplierId + '_' + companyId;
        this.timer = null;
        //this.load();
    }

    /**
     * Отправка текущей корзины на сервер
     * @returns {*}
     */
    #send() {
        let data = {
            supplier_id: this.supplier_id,
            company_id: this.company_id,
            items: this.items,
            comment: this.comment
        }
        return axiosClient({
            method: 'POST',
            url: store.state.api + 'cart/',
            data: data
        })
    }

    /**
     * Отправка корзины на сервер с задержкой (для кнопок +/-, например, чтобы не спамить запросами)
     */
    #saveWithTimeout(timeout=300) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {this.#send()}, timeout)
    }

    /**
     * Добавить товар в список текущей корзины или изменить количество имеющемуся.
     * @param product
     */
    #appendProduct(product) {
        if (product.quantity > 0) {
            let existing = this.items.filter(item => item.product.id === product.product_info.id);
            if (existing.length > 0) {
                this.modify(existing[0].product.id, existing[0].quantity + product.quantity)
            } else {
                this.items.push(
                    {
                        product: product.product_info,
                        quantity: product.quantity
                    }
                );
            }
        }
    }

    /**
     * Получение текущей корзины с сервера
     */
    load() {
        axiosClient({
            method: 'GET',
            url: store.state.api + 'cart/',
        }).then((response) => {
            this.items = response.data.items
        })
    }

    /**
     * Добавить товар в корзину.
     * @param product
     */
    add(product) {
        this.#appendProduct(product);
        return this.#send()
    }

    /**
     * Добавить несколько товаров в корзину
     * @param products
     * @returns {*}
     */
    addBulk(products) {
        products.forEach((product) => {
            this.#appendProduct(product)
        })
        return this.#send()
    }

    /**
     * Изменить количество имеющегося товара
     * @param product_id
     * @param new_qty
     */
    modify(product_id, new_qty) {
        if (new_qty <= 0) {
            this.delete(product_id)
        } else {
            this.items.forEach((item, index) => {
                if (item.product.id === product_id) {
                    this.items[index].quantity = new_qty
                }
            });
            this.#saveWithTimeout()
        }
    }

    /**
     * Удаление товара из корзины
     * @param product_id
     * @returns {*}
     */
    delete(product_id) {
        this.items = this.items.filter(item => item.product.id !== product_id);
        return this.#send()
    }

    /**
     * Очистить корзину
     * @returns {*}
     */
    clear() {
        this.items = [];
        this.comment = '';
        return this.#send()
    }

    /**
     * Получить количество товара в корзине по ID.
     * @param product_id
     * @returns {number}
     */
    getCurrentQty(product_id) {
        let currentQty = 0;
        if (this.items.length > 0) {
            this.items.forEach((item) => {
                if (item.product.id === product_id) {
                    currentQty = item.quantity
                }
            });
        }
        return currentQty
    }
}