import Cart from "@/utils/cart";

export default {
    SET_WINDOW_WIDTH: (state, width) => {
        state.window_width = width;
    },
    SET_USER_DATA: (state, user_data) => {
        state.user_info = user_data;
    },
    SET_ORDER_LIST: (state, order_list) => {
        state.order_list = order_list
    },
    SET_ORDER_DETAIL: (state, order_detail) => {
        state.order_detail = order_detail
    },
    SET_PRODUCT_LIST: (state, product_list) => {
        state.product_list = product_list
    },
    SET_FAVORITE_LIST: (state, favorite_list) => {
        state.favorite_list = favorite_list
    },
    SET_CART: (state, cart) => {
        state.cart = new Cart(
            cart.supplier_id,
            cart.company_id,
            cart.items
            )
    },
    SET_CART_LOADING: (state, cart_loading) => {
        state.cart_loading = cart_loading;
    },
    SET_LOGGED: (state, logged) => {
        state.logged = logged
    },
    SET_ERROR: (state, error) => {
        state.error = error
    },
    SET_API_KEY: (state, key) => {
        state.token = key
    },
    SET_USER_DEFAULT_FIRM: (state, default_firm) => {
        state.user_info.active_company_info = default_firm
    },
    SET_FINANCE: (state, finance) => {
        state.finance = finance
    },
    SET_ORDER_DRAFTS: (state, drafts) => {
        state.order_drafts = drafts
    },
    SET_ORDER_DRAFT_DETAIL: (state, draft_detail) => {
        state.order_draft = draft_detail
    },
    SET_CART_PAGE: (state, cart_page) => {
        state.cart_page = cart_page
    },
    TOGGLE_MOBILE_MENU_OPEN: (state) => {
        state.mobile_menu_open = !state.mobile_menu_open
    },
    SET_MOBILE_MENU_OPEN: (state, value) => {
        state.mobile_menu_open = value
    },
    SET_CURRENT_FILTER: (state, value) => {
        state.current_filter = value
    },
}
