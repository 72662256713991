<template>
    <div @click="openDetail" class="catalogue-main-container-lineview-item animate__animated animate__zoomIn">
        <p class="catalogue-main-container-lineview-item-info-code catalogue-main-container-lineview-item-info-code-mobile">артикул: {{ product.code }}</p>
        <div class="catalogue-main-container-lineview-item-info-title-mobile">
            <p class="catalogue-main-container-item-title catalogue-main-container-lineview-item-info-title">{{ product.name }}</p>
        </div>
        <div class="catalogue-main-container-lineview-item-info">
            <p class="catalogue-main-container-item-title catalogue-main-container-lineview-item-info-title">{{ product.name }}</p>
            <div class="catalogue-main-container-lineview-item-info-fields">
                <p class="catalogue-main-container-lineview-item-info-category">
                    <span class="catalogue-main-container-item-category-category">{{ product.category.name }}</span>{{ product.country.name }}
                </p>
                <p class="catalogue-main-container-lineview-item-info-code">артикул: {{ product.code }}</p>
                <p class="catalogue-main-container-lineview-item-info-volume">{{product.volume}} л</p>
                <p v-if="this.product.cart_add_params.allowed === 1" class="catalogue-main-container-item-bottom-available">
                    в наличии
                </p>
                <p v-else class="catalogue-main-container-item-bottom-unavailable">
                    нет в наличии
                </p>
            </div>
            <div class="catalogue-main-container-lineview-item-price catalogue-main-container-lineview-item-price-mobile nowrap">
                <p v-if="product.base_price > 0 && product.base_price !== product.price" class="catalogue-main-container-lineview-item-price-old">{{ product.base_price | splitDigits }} p.</p>
                <p :class="{'catalogue-main-container-lineview-item-price-special': product.is_personal === 1}">{{ product.price | splitDigits }} p.</p>

            </div>
        </div>
        <div class="catalogue-main-container-lineview-item-price nowrap">
            <p v-if="product.base_price > 0 && product.base_price !== product.price" class="catalogue-main-container-lineview-item-price-old">{{ product.base_price | splitDigits }} p.</p>
            <p :class="{'catalogue-main-container-lineview-item-price-special': product.is_personal === 1}">{{ product.price | splitDigits }} p.</p>
        </div>
        <div class="catalogue-main-container-lineview-item-controls">
            <c-cart-controls
                :product="product"
                :key="cartControlsKey"
            >
            </c-cart-controls>
        </div>
        <div class="catalogue-main-container-lineview-item-favorite">
            <c-fav-toggler
                :product="product"
                :key="favTogglerKey"
                :in_modal="in_fav"
            >
            </c-fav-toggler>
        </div>
    </div>
</template>

<script>
import splitDigits from "@/utils/filters/splitDigits";
import Cart from "@/utils/cart";
import {mapMutations, mapGetters} from 'vuex';
import eventHub from "@/utils/event_hub";
import cCartControls from "@/components/products/cCartControls";
import cProductDetail from "@/components/products/cProductDetail";
import cFavToggler from "@/components/products/cFavToggler";
import openDetailMixin from "@/utils/mixins/open_detail";

export default {
    name: "c-product-item-row",
    props: {
        product_item: {
            type: Object,
            default() {
                return {}
            }
        },
        in_fav: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    mixins: [openDetailMixin],
    computed: {
        ...mapGetters([
            'USER_INFO',
            'CART',
            'WINDOW_WIDTH'
        ])
    },
    data() {
        return {
            product: this.product_item,
            loading: false,
            loaded: false,
            favTogglerKey: 1,
            cartControlsKey: 1
        }
    },
    methods: {
        openDetail() {
            this.openDetailProduct(this.product.id)
        },
        favToggled(params) {
            if(params.id === this.product.id) {
                this.product.is_favourite = params.state ? 1 : 0;
            }
        }
    },
    filters: {
        splitDigits
    },
    components: {
        cCartControls,
        cFavToggler
    },
    mounted() {
        this.cartControlsKey++;
        eventHub.$on('fav-toggled', this.favToggled)
    },
    destroyed() {
        eventHub.$off('fav-toggled', this.favToggled)
    }
}
</script>

<style scoped>

</style>
