<template>
    <div class="modal-internal-container product-details">
        <c-loading-in-card v-if="loading"></c-loading-in-card>
        <div class="product-details-title">
            <button @click="close" class="close" data-dismiss="modal" aria-label="Close">
                <img class="close-cross" src="../../assets/images/x-mark-icon-w.png" alt="">
            </button>
            <p class="product-details-content-info-code product-details-title-code">
                <span>Артикул:</span> {{ product.code }}
            </p>
            <h1>
                {{product.name}}
            </h1>
        </div>
        <div class="product-details-content">
            <div class="product-details-content-image">
                <div v-if="product.is_personal === 1" class="product-details-content-image-special animate__animated animate__infinite">
                    спец. цена
                </div>
                <img v-lazy="product.picture_url">
            </div>
            <div class="product-details-content-info">
                <p class="product-details-content-info-code">
                    <span>Артикул:</span> {{ product.code }}
                </p>

                <h1 class="product-details-content-info-title">
                    {{product.name}}
                </h1>
                <div class="catalogue-main-container-item-category product-details-content-info-category">
                    <p class="catalogue-main-container-item-category-category">{{ product.category.name }}</p>
                    <p class="catalogue-main-container-item-category-country">{{product.country.name }}</p>
                </div>
                <div class="product-details-content-info-fields">
                    <span>{{product.strength}}%</span>
                    <span>{{product.volume}} л</span>
                </div>
                <div class="catalogue-main-container-lineview-item-price product-details-content-info-price nowrap">
                    <p v-if="product.base_price > 0 && product.base_price !== product.price" class="catalogue-main-container-lineview-item-price-old">{{ product.base_price | splitDigits }} p.</p>
                    <p :class="{'catalogue-main-container-lineview-item-price-special': product.is_personal === 1}">{{ product.price | splitDigits }} p.</p>
                </div>
                <p class="product-details-content-info-pack product-details-content-info-code">
                    <span>Фасовка:</span> {{product.pack}} бут.
                </p>
                <div class="product-details-content-info-controls">
                    <div v-if="product.rest > 0" class="product-details-content-info-controls-available">
                        <img src="../../assets/images/white-green-tick.png" alt="">
                        <span>есть в наличии</span>
                    </div>
                    <div v-else class="product-details-content-info-controls-unavailable">
                        нет в наличии
                    </div>
                    <c-cart-controls
                        :product="product"
                        :key="cart_controls_key"
                        :detail_card="true"
                    >
                    </c-cart-controls>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Product from "@/store/models/Product";
import axiosClient from "@/utils/axios/axios";
import splitDigits from "@/utils/filters/splitDigits";
import cCartControls from "@/components/products/cCartControls";
import cLoadingInCard from "@/components/common/loading/cLoadingInCard";
import {closeByPopstate} from "@/utils/mixins/close_by_history";

export default {
    name: "c-product-detail",
    props: {
        product_id: {
            type: Number,
            default() {
                return -1;
            }
        }
    },
    mixins: [closeByPopstate],
    data() {
        return {
            loading: false,
            product: Product,
            cart_controls_key: 1
        }
    },
    computed: {
        ...mapGetters(['API_URL'])
    },
    methods: {
        loadData() {
            this.loading = true;
            axiosClient({
                url: this.API_URL + 'products/get_detail/' + this.product_id + '/',
                method: 'GET'
            }).then((response) => {
                this.product = response.data;
                this.loading = false;
                this.cart_controls_key++
            })
        },
        close() {
            this.$modal.hide('modal_' + this.product.id)
        }
    },
    components: {
        cCartControls,
        cLoadingInCard
    },
    filters: {
        splitDigits
    },
    created() {
        this.window_state = {key: '#product_' + this.product_id};
    },
    mounted() {
        this.loadData();
    }
}
</script>

<style scoped>
</style>
