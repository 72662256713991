<template>
    <div class="page">
        <c-loading-overlay-page v-if="loading"></c-loading-overlay-page>
        <h1 class="page-title">Форма обратной связи</h1>
        <div class="feedback-form">
            <input class="input" v-model="topic" type="text" placeholder="Тема">
            <c-select
                :options="feedback_types"
                @option-selected="setType"
            ></c-select>
            <textarea v-model="message" class="textarea" placeholder="Описание"></textarea>
            <div class="feedback-form-buttons">
                <label class="button">
                    <img src="../../assets/images/attach_file.png" alt="">
                    <input id="file_input" ref="file_input" @change="setFile" type="file" class="d-none"/>
                    прикрепить <template v-if="file!==null">(1)</template>
                </label>
                <button class="button" @click="sendFeedback"><img src="../../assets/images/send.png" alt="">отправить</button>
            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from "@/utils/axios/axios";
import {mapGetters} from 'vuex';
import cFeedbackResultModal from "@/components/feedback/cFeedbackResultModal";
import cLoadingOverlayPage from "@/components/common/loading/cLoadingOverlayPage";
import cSelect from '@/components/common/cSelect'
export default {
    name: "c-feedback",
    data() {
        return {
            topic: '',
            feedback_types: [
                {
                    value: 0,
                    caption: 'Выберите тип запроса',
                    picture: ''
                }
            ],
            feedback_type: '',
            message: '',
            file: null,
            loading: false
        }
    },
    computed: {
        ...mapGetters(['API_URL'])
    },
    methods: {
        setFile(event) {
            if(event.target.files.length > 0) {
                this.file = event.target.files[0]
            }

        },
        setType(option) {
          this.feedback_type = option.value
        },
        init() {
            this.topic = '';
            this.message = '';
            this.feedback_type = '';
            this.file = null;
            this.$refs['file_input'].value='';
            this.$refs['file_input'].style.width='10vw';
            axiosClient({
                method: 'GET',
                url: this.API_URL + 'ref/feedback_types/'
            }).then((res) => {
                if (res.data !== undefined) {
                    res.data.forEach((el) => {
                        this.feedback_types.push({
                            value: el.id,
                            caption: el.name,
                            picture: ''
                        })
                    })
                }
            })

        },
        sendFeedback() {
            this.loading = true;
            let formData = new FormData();
            formData.append('topic', this.topic);
            formData.append('feedback_type_id', this.feedback_type);
            formData.append('message', this.message);
            if (this.file !== null) {
                formData.append('file', this.file);
            }

            axiosClient({
                method: 'POST',
                url: this.API_URL + 'feedback/create/',
                data: formData
            }).then((res) => {
                this.$modal.show(
                    cFeedbackResultModal,
                    {},
                    {
                        name: 'modal_feedback_result',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-thank-you-error-message']
                    }
                );
                this.init();
                this.loading = false
            }).catch(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.init();
    },
    components: {
        cLoadingOverlayPage,
        cSelect
    }
}
</script>

<style scoped>

</style>
