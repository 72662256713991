<template>
    <div class="cart-loading-container">
        <div class="spinner-border" style="width: 4rem; height: 4rem; color: #2196f3" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "c-cart-loading"
    }
</script>

<style scoped>
    .cart-loading-container {
        border-radius: 20px 20px 0 0;
        background-color: rgba(255,255,255,0.8);
        position:fixed;
        top:0;
        left:0;
        width:100vw;
        height:100vh;
        z-index:900;
        display:flex;
        justify-content: center;
        align-items: center;
    }
</style>
