import {mapGetters} from 'vuex';

let chatraMixin = {
    computed: {
        ...mapGetters(['USER_INFO']),
    },
    methods: {
        initChatra() {
            if (window.Chatra !== undefined) {
                window.Chatra('kill');
                window.Chatra = undefined;
                window.ChatraID = undefined;
            }
            window.ChatraIntegration = {
                name: this.USER_INFO.first_name + ' ' + this.USER_INFO.last_name + ' (' + this.USER_INFO.active_company_info.legal_name + ')',
                email: this.USER_INFO.email,
            };
            if (this.USER_INFO.user_type === 'client') {
                (function (d, w, c) {
                    w.ChatraID = 'BMBh9ooiNGGFWdDs9';
                    var s = d.createElement('script');
                    w[c] = w[c] || function () {
                        (w[c].q = w[c].q || []).push(arguments);
                    };
                    s.async = true;
                    s.src = 'https://call.chatra.io/chatra.js';
                    if (d.head) d.head.appendChild(s);
                })(document, window, 'Chatra');
            }
        },
    }

};

export default chatraMixin