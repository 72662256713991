<template>
    <div class="catalogue-main-subheader-sort-wrapper">
        <img alt="Сортировка" @click="toggleSelect" class="catalogue-main-subheader-sort-icon" :class="{'d-none': sort_open}" src="../../../assets/images/icons_new/sort.svg">
        <c-select
            :options="options"
            :current_value="current_value.value"
            @option-selected="orderBy"
            ref="sort_select"
        >
        </c-select>
    </div>
</template>

<script>
import cSelect from '@/components/common/cSelect';
import {mapGetters} from "vuex";

export default {
    name: "c-sort",
    data: function () {
        return {
            sort_open: false,
            options: [
                {
                    caption: 'по умолчанию',
                    value: '',
                    picture: require('../../../assets/images/icons_new/sort_default.svg')
                },
                {
                    caption: 'по цене (дешевле)',
                    value: 'price__asc',
                    picture: require('../../../assets/images/icons_new/sort_asc.svg')
                },
                {
                    caption: 'по цене (дороже)',
                    value: 'price__desc',
                    picture: require('../../../assets/images/icons_new/sort_desc.svg')
                },
                {
                    caption: 'по наименованию',
                    value: 'name__asc',
                    picture: require('../../../assets/images/icons_new/sort_name.svg')
                },
            ],
            current_value: {}
        }
    },
    computed: {
        ...mapGetters(['CURRENT_FILTER']),
    },
    methods: {
        orderBy(option) {
            this.$emit('apply-filter', {
                name: 'order_by',
                value: option.value
            })
            this.setCurrentValue()
        },
        setCurrentValue() {
            let currentOption = this.options.filter(option => option.value === '')[0];
            if (this.CURRENT_FILTER.order_by !== undefined && this.CURRENT_FILTER.order_by !== '') {
                let selected = this.options.filter(option => option.value === this.CURRENT_FILTER.order_by);
                if (selected.length > 0) {
                    currentOption = selected[0];
                }
            }
            this.current_value = currentOption;
        },
        toggleSelect() {
            this.$refs.sort_select.toggle();
        }
    },
    mounted() {
        this.setCurrentValue()
    },
    components: {
        cSelect
    }
}
</script>

<style scoped>

</style>
