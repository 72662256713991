<template>
    <div ref="overlay_container" class="loading-container-overlay">
        <div ref="spinner_page_overlay" class="spinner-border" style="width: 4rem; height: 4rem; color: #2196f3" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "c-loading-overlay-page",
        props: {
            fixed: {
                type: Boolean,
                default() {
                 return false;
                }
            }
        },
        /**
         * Сдвиг спиннера, чтобы он был всегда виден на экране, даже если страница прокручена вниз.
         */
        mounted() {
            let spinner = this.$refs.spinner_page_overlay;
            let overlay = this.$refs.overlay_container;
            spinner.style.left = (spinner.offsetLeft - spinner.offsetWidth/2) + 'px';
            let coords = overlay.getBoundingClientRect();
            let overlay_top = coords.top;
            if (overlay_top < 0) {
                spinner.style.position = 'fixed';
                spinner.style.top = '40vh';
                spinner.style.left = ((coords.left + coords.width / 2) - spinner.offsetWidth/2) + 'px';
            } else {
                spinner.style.top = '100px';
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "src/assets/scss/common/variables";

.spinner-border {
    position: absolute;
    top: 0;
    left: 50%;
}

    .loading-container-overlay {
        position:absolute;
        left:0;
        top:0;
        height: 100%;
        width: 100%;
        z-index: $z-loading-overlay;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        margin-left: 0;
    }
</style>
