<template>
        <div
                @click.stop="toggleFav"
                v-on:mouseleave="mouse_over = false"
                class="add-to-favorites">
                <svg
                        :class="{'fav-star-active': this.product.is_favourite === 1, 'fav-star-no-hover': mouse_over, 'fav-star': !mouse_over} "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 31 32"
                        preserveAspectRatio="xMinYMin meet"
                >
                    <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
                </svg>
        </div>
</template>

<script>
    import axiosClient from "@/utils/axios/axios";
    import {mapGetters} from 'vuex';
    import eventHub from "@/utils/event_hub";
    export default {
        name: "c-fav-toggler",
        props: {
            product: {
                type: Object,
                default() {
                    return {}
                }
            },
            in_modal: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        computed: {
          ...mapGetters([
              'API_URL'
          ]),
        },
        data() {
            return {
                is_favourite: this.product.is_favourite === 1,
                mouse_over: false
            }
        },
        methods: {
            toggleFav() {
                this.mouse_over = true;
                if (!this.is_favourite) {
                    axiosClient({
                        method: 'POST',
                        url: this.API_URL + 'fav/add/' + this.product.id + '/',
                    })
                } else {
                    axiosClient({
                        method: 'POST',
                        url: this.API_URL + 'fav/delete/' + this.product.id + '/',
                    })
                }
                this.is_favourite = !this.is_favourite;
                eventHub.$emit('fav-toggled', {id: this.product.id, state: this.is_favourite});
            }
        }
    }
</script>

<style scoped>

</style>