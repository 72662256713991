/**
 * Закрытие модальных окон по кнопке "назад" в браузере. В компоненте обязан существовать метод close(),
 * закрывающий модалку.
 */

/**
 * Для модалок ВТОРОГО уровня
 * @type {{destroyed(): void, data(): {closed_by_history: boolean, window_state: {key: string}}, methods: {closeByHistory(): void}, mounted(): void}}
 */
let closeByPopstate = {
    data() {
        return {
            closed_by_history: false,
            window_state: {key: '#modal_' + Math.round(Math.random() * 1000000).toString()}
        }
    },
    methods: {
        closeByHistory() {
            this.closed_by_history = true;
            this.close();
        }
    },
    mounted() {
        window.history.pushState(this.window_state, null, document.location);
        addEventListener('popstate', this.closeByHistory)
    },
    destroyed() {
        removeEventListener('popstate', this.closeByHistory)
        if (!this.closed_by_history && window.history.state.key === this.window_state.key) {
            history.back();
        }
    }
}

/**
 * Для модалок ПЕРВОГО уровня
 *
 * @type {{destroyed(): void, data(): {closed_by_history: boolean, window_state: {key: string}}, methods: {closeByHistory(): void}, mounted(): void}}
 */
let closeByHashChange = {
    data() {
        return {
            closed_by_history: false,
            window_state: {key: '#modal_' + Math.round(Math.random() * 1000000).toString()}
        }
    },
    methods: {
        closeByHistory() {
            this.closed_by_history = true;
            this.close();
        }
    },
    mounted() {
        window.history.pushState(this.window_state, null, document.location + this.window_state.key);
        addEventListener('hashchange', this.closeByHistory)
    },
    destroyed() {
        removeEventListener('hashchange', this.closeByHistory)
        if (!this.closed_by_history && window.history.state.key === this.window_state.key) {
            history.back();
        }
    }
}

export {closeByPopstate, closeByHashChange}
