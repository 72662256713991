<template>
    <div class="orders-details-content-table-row cart-products-item">
        <div class="orders-details-content-table-index">
            {{ index }}
        </div>
        <div @click.stop="openDetail" class="orders-details-content-table-name cart-products-item-name">
            <div class="orders-details-content-table-name-text">
                <span>{{ cart_item.product.name }}</span>
            </div>
            <div class="orders-details-content-table-divider"></div>
        </div>
        <div class="orders-details-content-table-fields cart-products-item-fields">
            <div class="orders-details-content-table-fields-volume">
                {{cart_item.product.volume}} л
            </div>
            <div class="orders-details-content-table-fields-amount cart-products-amount">
                        <div class="catalogue-controls-added">
                            <div @click.stop="modifyQuantity('reduce')" class="catalogue-controls-added-button">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 604.02 604.03">
                                    <path d="M400,702a302,302,0,1,1,213.55-88.45A300.06,300.06,0,0,1,400,702Zm0-577c-151.64,0-275,123.36-275,275S248.36,675,400,675,675,551.64,675,400,551.64,125,400,125ZM533.33,425H266.67a25,25,0,0,1,0-50H533.33a25,25,0,0,1,0,50Z" transform="translate(-97.99 -97.99)"/>
                                </svg>
                            </div>
                            <div class="catalogue-controls-added-input">
                                <input type="text" :value="qty" @input="manualQtyInput" @keydown="inputRestrictInt" :readonly="quantityStep > 1">
                            </div>
                            <div @click.stop="modifyQuantity('increase')" class="catalogue-controls-added-button" :class="{'catalogue-controls-added-button-disabled': !can_increase}">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 602.61 602.61">
                                    <path d="M400,701.31a301.32,301.32,0,1,1,213-88.25A299.39,299.39,0,0,1,400,701.31Zm0-576.42c-151.64,0-275,123.39-275,275.05S248.36,675,400,675,675,551.61,675,399.94,551.64,124.89,400,124.89Zm0,466.78a25,25,0,0,1-25-25V425H233.33a25,25,0,0,1,0-50H375V233.33a25,25,0,0,1,50,0V375H566.67a25,25,0,0,1,0,50H425V566.67A25,25,0,0,1,400,591.67Z" transform="translate(-98.69 -98.69)"/>
                                </svg>
                            </div>
                        </div>
            </div>
            <div class="orders-details-content-table-fields-price">
                {{ cart_item.product.price | splitDigits }} p.
            </div>
            <div class="orders-details-content-table-fields-total">
                {{ sum | splitDigits }} p.
            </div>
            <div @click="deleteFromCart" class="cart-products-item-remove">
                <img src="../../assets/images/remove.png" alt="">
            </div>
        </div>
    </div>




<!--    <li class="draft-orders-line-container">-->
<!--        <p class="draft-orders-line cart-orders-line-number">{{ index }}</p>-->
<!--        <div @click="openDetail" class="draft-orders-line cart-orders-line-position">-->
<!--            {{ cart_item.product.name }}-->
<!--            <p class="cart-orders-line-position-full">{{ cart_item.product.name }}</p>-->
<!--        </div>-->
<!--        <div class="draft-orders-line cart-orders-line-volume">-->
<!--            {{cart_item.product.volume}} л-->
<!--        </div>-->
<!--        <div class="draft-orders-line cart-orders-line-quantity">-->
<!--            <div @click="modifyQuantity('reduce')" class="catalogue-item-added-to-cart-decrease">-->
<!--                <img class="catalogue-item-added-to-cart-decrease-image" src="../../assets/images/decrease_button.png" alt="">-->
<!--            </div>-->
<!--            <p v-if="quantityStep === 1" class="draft-orders-added-to-cart-input">-->
<!--                <input type="text" :value="qty" @input="manualQtyInput" @keydown="inputRestrictInt">-->
<!--            </p>-->
<!--            <p v-else>{{qty}}</p>-->
<!--            <div @click="modifyQuantity('increase')" class="catalogue-item-added-to-cart-increase" :class="{'catalogue-item-added-to-cart-increase-disabled': !can_increase}">-->
<!--                <img class="catalogue-item-added-to-cart-increase-image" src="../../assets/images/increase_button.png" alt="">-->
<!--            </div>-->
<!--        </div>-->
<!--        <p class="draft-orders-line cart-orders-line-price">{{ cart_item.product.price | splitDigits }} p.</p>-->
<!--        <p class="draft-orders-line cart-orders-line-cost">{{ sum | splitDigits }} p.</p>-->
<!--        <div @click="deleteFromCart" class="draft-orders-line cart-orders-line-remove"><img class="draft-orders-remove-item" src="../../assets/images/remove.png" alt=""></div>-->
<!--    </li>-->
</template>

<script>
    import splitDigits from "@/utils/filters/splitDigits";
    import {mapMutations, mapGetters} from 'vuex';
    import cProductDetail from "@/components/products/cProductDetail";
    import {inputRestrictInt} from "@/utils/input_restrict";
    import eventHub from "@/utils/event_hub";
    import openDetailMixin from "@/utils/mixins/open_detail";

    export default {
        name: "c-cart-item",
        props: {
            cart_item: {
                type: Object,
                default() {
                    return {}
                }
            },
            index: {
                type: Number,
                default() {
                    return 0;
                }
            }
        },
        mixins: [openDetailMixin],
        data() {
            return {
                qty: this.cart_item.quantity,
                quantityStep: this.cart_item.product.cart_add_params.step,
                timer: null
            }
        },
        computed: {
          ...mapGetters([
              'CART',
              'WINDOW_WIDTH',
              'USER_INFO'
          ]),
            sum() {
                return this.cart_item.product.price * this.qty
            },
            can_increase() {
                return this.qty + this.quantityStep <= this.cart_item.product.rest || this.USER_INFO.supplier_parameters.no_rest === 1
            }
        },
        methods: {
            ...mapMutations([
                'SET_CART'
            ]),
            modifyQuantity(type) {
                if (type === 'increase') {
                    if (this.can_increase) {
                        this.qty += this.quantityStep;
                    }
                }
                if (type === 'reduce') {
                    this.qty -= this.quantityStep;
                    if (this.qty < this.quantityStep) {
                        this.qty = 0
                    }
                }
                this.CART.modify(this.cart_item.product.id, this.qty)

            },
            deleteFromCart() {
                this.CART.delete(this.cart_item.product.id)
                //this.$emit('delete-from-cart', this.cart_item.product.id);
            },
            manualQtyInput(event) {
                if (event.target.value !== '') {
                    this.qty = parseInt(event.target.value);
                    if (this.qty > 0) {
                        if (this.qty > this.cart_item.product.rest && this.USER_INFO.supplier_parameters.no_rest === 0) {
                            this.qty = this.cart_item.product.rest
                        }
                        this.CART.modify(this.cart_item.product.id, this.qty)
                        //this.$emit('modify-cart', {id: this.cart_item.product.id, qty: this.qty});
                    }
                }
            },
            inputRestrictInt: inputRestrictInt,
            updateQty() {
              let currentQty = this.CART.getCurrentQty(this.cart_item.product.id);
              if (currentQty === 0) {
                  this.deleteFromCart();
              } else if (currentQty !== this.qty) {
                  this.qty = currentQty;
                  this.$emit('modify-cart', {id: this.cart_item.product.id, qty: this.qty});
              }
            },
            openDetail() {
                this.openDetailProduct(this.cart_item.product.id, this.onDetailClosed)
            },
            onDetailClosed() {
                //this.updateQty()
            }
        },
        filters: {
            splitDigits
        }
    }
</script>

<style scoped>

</style>
