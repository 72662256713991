<template>
    <div class="modal-internal-container favorites">
        <div class="favorites-header">
            <button @click="close" class="close">
                <img class="close-cross" src="../../../assets/images/x-mark-icon-w.png" alt="">
            </button>
            <p class="favorites-header-title">Избранное</p>
            <div class="favorites-header-search">
                <input v-model="search_string" @input="search" type="text" class="input" placeholder="Поиск" name="search">
                <svg data-v-20a05a50="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="input-icon"><path data-v-20a05a50="" d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
            </div>
        </div>
        <c-loading v-if="loading">
        </c-loading>
        <div v-else class="catalogue-main-container catalogue-main-container-lineview catalogue-main-container-lineview-favorites">
            <template v-if="FAVORITE_LIST.products.length > 0">
                <c-product-item-row
                        v-for="(product_item, index) in FAVORITE_LIST.products"
                        :key="index"
                        v-bind:product_item="product_item"
                        :in_fav="true"
                >
                </c-product-item-row>
            </template>
            <template v-else>
                <div class="favorites-empty">
                    <p>
                        В избранном нет ни одного товара!
                    </p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import cProductItemRow from "@/components/products/cProductItemRow";
    import cLoading from "@/components/common/loading/cLoading";
    import {mapGetters, mapActions, mapMutations} from 'vuex';
    import {closeByHashChange} from "@/utils/mixins/close_by_history";

    export default {
        name: "cFavList",
        mixins: [closeByHashChange],
        computed: {
            ...mapGetters([
                'FAVORITE_LIST'
            ])
        },
        data() {
          return {
              loading: false,
              order_by: '',
              is_personal: true,
              filter: {},
              search_string: '',
              timer: null
          }
        },
        methods: {
            ...mapActions([
                'GET_FAVORITE_LIST'
            ]),
            ...mapMutations([
               'SET_FAVORITE_LIST'
            ]),
            close() {
                this.$modal.hide('modal_fav_list')
            },
            applyFilter() {
                if (this.order_by !== '') {
                    this.filter.order_by = this.order_by
                } else (
                    delete this.filter.order_by
                );
                if (this.is_personal === false) {
                    this.filter.is_personal = 1
                } else {
                    delete this.filter.is_personal
                }
                if (this.search_string !== '') {
                    this.filter.search = this.search_string
                } else {
                    delete this.filter.search
                }
                this.loadData()
            },
            search() {
                clearTimeout(this.timer);
                this.timer = setTimeout(this.applyFilter, 500)
            },
            loadData() {
                this.loading = true;
                this.GET_FAVORITE_LIST(this.filter)
                    .then(() => {
                        this.loading = false;
                    })
            }
        },
        components: {
          cProductItemRow,
            cLoading
        },
        created() {
            this.window_state = {key: '#fav'}
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
