<template>
    <div class="modal-internal-container">
        <div class="modal-title">
            <button @click="close" class="close" data-dismiss="modal" aria-label="Close">
                <img class="close-cross" src="../../assets/images/x-mark-icon-w.png" alt="">
            </button>
            <p>
                {{company.fact_name}}
            </p>
            <p class="modal-subtitle">
                {{company.legal_name}}
            </p>
        </div>
        <div class="profile-companies-detail">
            <div class="profile-companies-detail-section">
                <div class="profile-companies-detail-section-item">
                    <div class="profile-companies-detail-section-item-title">
                        ИНН:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.inn}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        КПП:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.kpp}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Фактческий адрес:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.fact_address}}
                    </div>
                </div>
            </div>
            <div class="profile-companies-detail-section">
                <div class="profile-companies-detail-section-item">
                    <div class="profile-companies-detail-section-item-title">
                        Номер(-a) лицензии(й):
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.license.number}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Дата(-ы) лицензии(й):
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.license.date}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Срок истечения:
                    </div>
                    <div class="profile-companies-detail-section-item-value" :class="{'profile-companies-detail-section-item-value-warning': license_exclamation}">
                        <img v-if="license_exclamation" src="../../assets/images/red-triangle-exclamation-mark.png" alt="">{{company.license.expire}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Максимальная крепость:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.contract.strength}}%
                    </div>
                </div>
            </div>
            <div class="profile-companies-detail-section">
                <div class="profile-companies-detail-section-item">
                    <div class="profile-companies-detail-section-item-title">
                        Номер договора:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.contract.number}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Дата договора:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.contract.date}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Срок истечения:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.contract.expire}}
                    </div>
                </div>
            </div>
            <div class="profile-companies-detail-section">
                <div class="profile-companies-detail-section-item">
                    <div class="profile-companies-detail-section-item-title">
                        Способ оплаты:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.payment_type.name}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Тип оплаты:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.payment_condition.name}}
                    </div>
                </div>
            </div>
            <div class="profile-companies-detail-section">
                <div class="profile-companies-detail-section-item">
                    <div class="profile-companies-detail-section-item-title">
                        Имя менеджера:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        {{company.manager.name}}
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Телефон менеджера:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        <a :href="'tel:+' + company.manager.phone">{{company.manager.phone}}</a>
                    </div>
                    <div class="profile-companies-detail-section-item-title">
                        Email менеджера:
                    </div>
                    <div class="profile-companies-detail-section-item-value">
                        <a :href="'mailto:' + company.manager.email">{{company.manager.email}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment/moment";
import {closeByHashChange} from "@/utils/mixins/close_by_history";
import company from "@/store/models/Company";

export default {
    name: "c-company-details",
    props: {
        company: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    mixins: [closeByHashChange],
    computed: {
        ...mapGetters(['USER_INFO']),
        license_exclamation() {
            return this.company.active !== 0 ? moment(this.company.license.expire, 'DD.MM.YYYY') - moment() <= this.USER_INFO.supplier_parameters.license_expire_notification_days : false
        }
    },
    methods: {
        close() {
            this.$modal.hide('modal_company_details')
        },
    },
    created() {
        this.window_state = {key: '#company_' + this.company.id}
    }
}
</script>

<style scoped>

</style>
