<template>
    <div @click.prevent="showCompanyModal" class="profile-companies-item" :class="{'profile-companies-item-active': company.id === active_firm_id}">
        <div v-if="company.active === 0" class="profile-companies-item-grid">
        </div>
        <div class="profile-companies-item-column">
            <p class="profile-companies-item-name">{{company.fact_name}}</p>
            <p>{{company.legal_name}}</p>
            <p>ИНН: {{company.inn}}</p>
            <p :class="{'profile-companies-item-warning': license_exclamation}">Срок лицензии - {{company.license.expire}}</p>
        </div>
        <div class="profile-companies-item-column">
            <p>{{company.legal_address}}</p>
        </div>
        <div class="profile-companies-item-column profile-companies-item-column-manager">
            <p class="profile-companies-item-name">Менеджер: {{company.manager.name}}</p>
            <p>{{company.manager.phone}}</p>
            <p>{{company.manager.email}}</p>
        </div>
    </div>
</template>

<script>
    import cCompanyDetails from "@/components/profile/cCompanyDetails";
    import {mapActions, mapGetters} from 'vuex';
    import Company from "@/store/models/Company";
    import moment from 'moment';

    export default {
        name: "c-user-company-item",
        props: {
            company: {
                type: Object,
                default() {
                    return Company
                }
            },
            active_firm_id: {
                type: Number,
                default() {
                    return -1;
                }
            }
        },
        computed: {
            ...mapGetters(['USER_INFO', 'API_URL']),
            license_exclamation() {
                return this.company.active !== 0 ? moment(this.company.license.expire, 'DD.MM.YYYY') - moment() <= this.USER_INFO.supplier_parameters.license_expire_notification_days : false
            }
        },
        methods: {
            showCompanyModal(){
                this.$modal.show(
                    cCompanyDetails,
                    {
                        company: this.company
                    },
                    {
                        name: 'modal_company_details',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'profile-counterparty-details-modal', 'modal-mobile-fullscreen']
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>
