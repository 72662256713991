import axiosClient from "@/utils/axios/axios";
import cAdmResultModal from "@/components/adm/cAdmResultModal";
import {mapGetters} from 'vuex';
import eventHub from "@/utils/event_hub";
let admSetValueMixin = {
    computed: {
        ...mapGetters(['USER_INFO', 'API_URL']),
    },
    methods: {
        setValueToApi(value) {
            let formData = new FormData();
            formData.append(value.name, value.value);
            return axiosClient({
                method: 'POST',
                url: this.API_URL + 'user/supplier_parameters/',
                data: formData
            }).then((response) => {
                if (response.data[value.name] !== undefined){
                    this.USER_INFO.supplier_parameters = response.data;
                }
            })
        },
        showResultModal(result = {errors: []}){
            this.$modal.show(
                cAdmResultModal,
                {
                    result: result
                },
                {
                    name: 'modal_adm_result',
                    resizable: true,
                    scrollable: true,
                    height: 'auto',
                    classes: ['modal-external-container', 'modal-thank-you-error-message']
                }
            )
        }
    },
    mounted() {
        if (this.USER_INFO.supplier_parameters === undefined) {
            eventHub.$on('user-loaded', this.init);
        } else {
            if (typeof this.init === 'function') {
                this.init()
            }
        }
    },
    destroyed() {
        eventHub.$off('user-loaded', this.init);
    }
};
export default admSetValueMixin
