import Vue from 'vue';
import Vuex from 'vuex';
import actions from "@/store/actions/actions";
import mutations from "@/store/mutations/mutations";
import getters from "@/store/getters/getters";
import Cart from "@/utils/cart";
import {loadFromStorage} from "@/store/local_storage/local_storage";
import UserInfo from "@/store/models/UserInfo";
import Order from "@/store/models/Order";

Vue.use(Vuex);

let store = new Vuex.Store(
    {
        state: {
            token: loadFromStorage('key', String),
            backend_host: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000',
            api: process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:8000/api/',
            logged: false,
            error: false,
            cart_loading: false,
            window_width: window.innerWidth,
            user_info: UserInfo,
            order_list: {
                filter_sets: {
                    checkboxes: [
                        {
                            id: 0,
                            name: ''
                        }
                    ]
                },
                orders: [],
                drafts: []
            },
            order_detail: Order,
            product_list: {
                products: [],
                filter_sets: {
                    ranges: [],
                    checkboxes: [],
                    toggles: []
                },
            },
            favorite_list: {
                products: []
            },
            finance: {
                firm_debt: {},
                payments: []
            },
            order_drafts: [],
            order_draft: {},
            cart: {items: []},
            mobile_menu_open: false,
            current_filter: []
        },
        actions,
        mutations,
        getters
    }
);

export default store
