<template>
    <ul class="companies">
        <li
                v-for="(company, index) in USER_INFO.company_list"
                :key="company.id"
                @click="()=>{changeDefaultCompany(company)}"
                class="companies-item"
        >
            <p class="companies-item-count">
                {{index + 1}}
            </p>
            <div class="companies-item-title-wrapper">
                <div class="companies-item-title">
                    <p>
                        {{company.fact_name}}
                    </p>
                    <p>
                        {{'\xa0'}}/{{'\xa0'}}
                    </p>
                    <p class="companies-item-title-official">
                        {{company.legal_name}}
                    </p>
                </div>
                <p class="companies-item-address">
                    {{company.fact_address}}
                </p>
            </div>
        </li>
    </ul>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import eventHub from "@/utils/event_hub";
import checkLicenceMixin from "@/utils/mixins/check_licence";
export default {
    name: "c-choose-company",
    mixins: [checkLicenceMixin],
    props: {
        in_modal: {
            type: Boolean,
            default(){
                return false
            }
        }
    },
    computed: {
        ...mapGetters([
            'USER_INFO'
        ])
    },
    methods: {
        ...mapActions([
            'GET_USER_INFO',
            'CHANGE_USER_DEFAULT_FIRM',
            'GET_CART'
        ]),
        changeDefaultCompany(company) {
            if (company.active !== 0) {
                this.CHANGE_USER_DEFAULT_FIRM(company)
                    .then(() => {
                        if (this.$route.name === 'login') {
                            this.$router.push({name: 'products'})
                        } else {
                            this.GET_USER_INFO()
                                .then(() => {
                                    if (this.in_modal) {
                                        this.$modal.hide('modal_choose_company')
                                    }
                                    this.GET_CART();
                                    eventHub.$emit('user-loaded');
                                    this.checkLicence()
                                });
                        }
                    })
            }
        },
    }

}
</script>

<style scoped>

</style>