import Supplier from './Supplier'

let SupplierParameters = {
    supplier: Supplier,
    no_rest: 0,
    alco_free_pack: {
        id: 0,
        name: ''
    },
    license_expire_notification_days: 0,
    lo_shipment_week_days: [],
    shipment_restrict_days: [],
    next_day_order_time_restrict: '',
    current_day_shipment_cond: [],
    delivery_time_intervals: [],
    notification_emails: []
};

export default SupplierParameters