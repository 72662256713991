<template>
    <div @click="openDetail" class="orders-details-content-table-row">
        <div class="orders-details-content-table-index">
            {{index+1}}
        </div>
        <div class="orders-details-content-table-name">
            <div class="orders-details-content-table-name-text">
                <span>{{productItem.product_info.name}}</span>
                <div class="orders-details-content-table-name-icon">
                    <img v-if="no_active_actual" class="orders-details-content-warning-icon cursor-default" src="../../assets/images/red-triangle-exclamation-mark.png" alt="">
                </div>
            </div>
            <div class="orders-details-content-table-divider"></div>
        </div>
        <div class="orders-details-content-table-fields">
            <div class="orders-details-content-table-fields-volume">
                {{productItem.product_info.volume}} л
            </div>
            <div class="orders-details-content-table-fields-amount">
                {{productItem.amount}}
            </div>
            <div class="orders-details-content-table-fields-price">
                {{productItem.deal_price | splitDigits}} p.
            </div>
            <div class="orders-details-content-table-fields-total">
                {{productItem.total | splitDigits}} p.
            </div>
        </div>
    </div>
</template>

<script>
    import splitDigits from "@/utils/filters/splitDigits";
    import cProductDetail from "@/components/products/cProductDetail";
    import openDetailMixin from "@/utils/mixins/open_detail";
    export default {
        name: "c-order-detail-product",
        props: {
            productItem: {
                type: Object,
                default() {
                    return {}
                }
            },
            index: {
                type: Number,
                default() {
                    return 1;
                }
            },
            no_active_in_order: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        mixins: [openDetailMixin],
        computed: {
          no_active_actual() {
              return this.productItem.product_info.is_active === 0 || this.productItem.product_info.cart_add_params.allowed === 0
          }
        },
        methods: {
            openDetail() {
                this.openDetailProduct(this.productItem.product_info.id)
            },
        },
        filters: {
            splitDigits
        }
    }
</script>

<style scoped>

</style>
