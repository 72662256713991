<template>
    <tr @click="openDetail">
        <td class="nowrap">{{ order.number }}</td>
        <td>{{ order.creation_date }}</td>
        <td>{{ order.date }}</td>
        <td>{{ order.execution_date }}</td>
        <td>{{ order.status.condition.name }}</td>
        <td class="nowrap">{{ order.total_fact | splitDigits }}</td>
        <td class="nowrap">{{ order.debt | splitDigits }}</td>
    </tr>
</template>

<script>
    import splitDigits from '../../utils/filters/splitDigits';
    import cOrderDetail from "@/components/orders/cOrderDetail";

    export default {
        name: "c-order-item",
        props: {
            order: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        methods: {
            openDetail() {
                this.$modal.show(
                    cOrderDetail,
                    {
                        orderId: this.order.id
                    },
                    {
                        name: 'modal_order_details',
                        //adaptive: true,
                        resizable: true,
                        scrollable: true,
                        //width: this.WINDOW_WIDTH > 768 ? '65%' : '90%',
                        //width: 'auto',
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-cart-details', 'modal-mobile-fullscreen']
                    }
                )
            }
        },
        filters: {
            splitDigits
        }
    }
</script>

<style scoped>

</style>
