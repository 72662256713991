<template>
    <nav aria-label="Page navigation">
        <ul class="pagination-list">
            <li class="page-item">
                <a
                        href="javascript:void(0)"
                        @click="applyPage(current_page - 1)"
                        class="page-link"
                        id="previous-page"
                        aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item">
                <a
                        href="javascript:void(0)"
                        @click="applyPage(1)"
                        class="page-link"
                        :class="{ 'page-link-active': current_page === 1 }"
                >1</a>
            </li>
            <li
                    v-if="current_page > 3"
                    class="page-item disabled"
            >
                <a href="javascript:void(0)" class="page-link">...</a>
            </li>
            <li
                    v-for="(page, index) in middle_page_list"
                    :key="index"
                    class="page-item"
            >
                <a href="javascript:void(0)" v-on:click="applyPage(page)" class="page-link" v-bind:class="{ 'page-link-active': current_page === page }">{{ page }}</a>
            </li>
            <li
                    v-if="current_page < total_pages - 2"
                    class="page-item disabled"
            >
                <a href="javascript:void(0)" class="page-link">...</a>
            </li>
            <li
                    v-if="total_pages > 1"
                    class="page-item"
            >
                <a
                        href="javascript:void(0)"
                        v-on:click="applyPage(total_pages)"
                        role="button"
                        type="button"
                        class="page-link"
                        v-bind:class="{ 'page-link-active': current_page === total_pages }"
                >
                    {{ total_pages }}
                </a>
            </li>
            <li class="page-item">
                <a
                        href="javascript:void(0)"
                        v-on:click="applyPage(current_page + 1)"
                        class="page-link"
                        id="next-page"
                        aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "c-pagination",
        props: {
            current_page: {
                type: Number,
                default() {
                    return 1
                }
            },
            total_pages: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        methods: {
            applyPage: function (page) {
                if(page < 1) {
                    page = 1
                }
                if (page > this.total_pages) {
                    page = this.total_pages
                }
                let filter = {
                    name: 'page',
                    value: page.toString()
                };
                this.$emit('apply-filter', filter)
            }
        },
        computed: {
            middle_page_list: function () {
                let list = [];
                if (this.current_page > 2) {
                    list.push(this.current_page - 1)
                }
                if (this.current_page !== 1 && this.current_page !== this.total_pages) {
                    list.push(this.current_page)
                }
                if (this.current_page < this.total_pages - 1) {
                    list.push(this.current_page + 1)
                }
                return list
            }
        },
    }
</script>

<style scoped>

</style>