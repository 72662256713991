<template>
    <div class="admin-restrict-days">
        <h1 class="admin-content-title">Запрет отгрузки по дням года</h1>
        <p class="text-center">Выберите дни, в которые не возможно осуществлять отгрузку</p>
        <div class="admin-restrict-days-calendar">
            <datepicker
                :calendar-class="'datepicker-calendar-fix'"
                :input-class="'datepicker-input'"
                :format="'dd.MM.yyyy'"
                :language="ru"
                :monday-first="true"
                :disabledDates="disabled_dates"
                :inline="true"
                v-model="selected_day"
                v-on:selected=setValue
            > </datepicker>
        </div>
        <div class="admin-restrict-days-selected">
            <p class="text-center">Выбранные дни</p>
            <div class="admin-restrict-days-selected-container">
                <div v-for="(day, index) in days" :key="index" class="admin-restrict-days-selected-item">
                    <p class="nowrap">
                        <span class="admin-restrict-days-selected-item-year">{{day.year}} </span>
                        <span class="admin-restrict-days-selected-item-month">{{day.month}}</span>
                    </p>
                    <p>{{day.day}}, {{day.weekday}}</p>
                    <img @click="deleteValue(day.date.format('DD.MM.YYYY'))" src="../../../assets/images/remove.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    // import Slick from 'vue-slick';
    import moment from 'moment'
    import Datepicker from 'vuejs-datepicker';
    import {ru} from 'vuejs-datepicker/dist/locale';
    export default {
        name: "c-adm-restrict-days",
        mixins: [admSetValueMixin],
        data(){
            return {
                days: [],
                days_actual: [],
                selected_day: null,
                slickParams: {
                    infinite: false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    dots: false,
                    arrows: true,
                    variableWidth: false,
                    prevArrow: '<div class="adm-07-arrows-next-prev adm-07-arrow-prev"></div>',
                    nextArrow: '<div class="adm-07-arrows-next-prev adm-07-arrow-next"></div>'
                },
                slickKey: 1,
                ru: ru,
                disabled_dates: {
                    dates: [],
                },
                months_ru: {
                    1: 'Янв',
                    2: 'Фев',
                    3: 'Мар',
                    4: 'Апр',
                    5: 'Май',
                    6: 'Июн',
                    7: 'Июл',
                    8: 'Авг',
                    9: 'Сен',
                    10: 'Окт',
                    11: 'Ноя',
                    12: 'Дек'
                }
            }
        },
        methods: {
            init() {
                this.days = [];
                this.days_actual = [];
                this.disabled_dates.dates = [];
                this.USER_INFO.supplier_parameters.shipment_restrict_days.forEach((el) => {
                    let date = moment(el, 'DD.MM.YYYY');
                    this.days.push({
                        date: date,
                        year: date.format('YYYY'),
                        month: this.months_ru[date.format('M')],
                        day: date.format('DD'),
                        weekday: date.locale('ru').format('dd')
                    });
                });
                this.days.sort((first, second) => {return first.date >= second.date ? -1 : 1});
                this.days.forEach((el) => {
                    this.days_actual.push(el.date.format('DD.MM.YYYY'));
                    this.disabled_dates.dates.push(el.date.toDate());
                });
            },
            setValue(day){
                this.days_actual.push(moment(day).format('DD.MM.YYYY'));
                this.setValueToApi({name: 'shipment_restrict_days', value: JSON.stringify(this.days_actual)})
                    .then(() => {
                        this.init()
                    })
            },
            deleteValue(date) {
                this.days_actual = this.days_actual.filter((el) => {return el !== date});
                this.setValueToApi({name: 'shipment_restrict_days', value: JSON.stringify(this.days_actual)})
                    .then(() => {
                        this.init()
                    })
            }
        },
        components: {
            // Slick,
            Datepicker
        }
    }
</script>

<style>
    /*@import "../../../node_modules/slick-carousel/slick/slick.css";*/
</style>
