<template>
    <tr @click="openDetail">
        <td>{{order_draft.number}}</td>
        <td>{{order_draft.creation_date}}</td>
        <td>{{order_draft.product_count}}</td>
        <td>
            <div class="orders-content-table-actions">
                <svg
                    @click.stop="deleteDraft"
                    class="remove-icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1024 1024"
                    preserveAspectRatio="xMinYMin meet"
                >
                    <path d="M516.02,8.13c284.58,4.63,503.33,225.61,503.14,505.31c-0.19,283.32-223.9,505.31-506.29,503.86
                c-281.95-1.45-502.81-223.93-501.99-505.73C11.69,230.54,233.26,11.97,516.02,8.13z M307.86,213.65
                c-3.77,3.1-8.6,6.48-12.72,10.56c-23.01,22.78-45.22,46.41-68.99,68.36c-14.09,13.01-13.49,21.89,0.11,35.17
                c54.25,52.98,107.51,106.98,161.12,160.62c24.72,24.73,24.72,24.77,0.86,48.64c-53,53.04-105.37,106.74-159.44,158.67
                c-16.91,16.24-15.49,25.96,0.85,40.53c22.23,19.81,43.29,41.1,63.24,63.22c12.85,14.25,20.95,13.99,34.46,0.1
                c54.65-56.19,111.01-110.72,165.68-166.88c14.79-15.2,24.27-15.34,39.11-0.1c54.69,56.16,110.95,110.78,165.72,166.85
                c12.82,13.12,21.17,15.92,34.67,1.01c21.14-23.34,43.66-45.54,66.82-66.89c14.32-13.19,12.85-22.17-0.52-35.25
                c-54.81-53.62-108.16-108.75-163.19-162.15c-17.3-16.79-18.55-28.21-0.53-45.63c56.34-54.5,110.81-110.91,166.9-165.67
                c13.07-12.76,12.68-20.56-0.36-32.51c-21.97-20.12-43.4-41.04-63.2-63.27c-15.1-16.96-25.18-18.69-42.22-0.88
                c-53.01,55.39-108.57,108.33-161.9,163.43c-16.03,16.56-26.11,17.86-42.83,0.53C437.7,336.3,382.25,282.07,327.33,227.33
                C322.04,222.06,317.76,215.16,307.86,213.65z"/>
                </svg>
            </div>
        </td>
    </tr>
</template>

<script>
    import splitDigits from '../../utils/filters/splitDigits';
    import cOrderDetail from "@/components/orders/cOrderDetail";

    export default {
        name: "c-order-draft-item",
        props: {
            order_draft: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        methods: {
            openDetail() {
                this.$modal.show(
                    cOrderDetail,
                    {
                        orderId: this.order_draft.id,
                        is_draft: true
                    },
                    {
                        name: 'modal_order_details',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-cart-details', 'modal-mobile-fullscreen']
                    }
                )
            },
            deleteDraft() {
                this.$emit('delete-draft', this.order_draft.id)
            }
        },
        filters: {
            splitDigits
        }
    }
</script>

<style scoped>

</style>
