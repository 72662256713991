<template>
    <div ref="_header" class="header">
        <div class="full-page-overlay" :class="{'d-block': MOBILE_MENU_OPEN || profile_menu_open}" @click="collapseMenu"></div>
        <div class="header-logotype" @click="$router.push({name:'root'})">
            <img src="../assets/images/logo_M.png" alt="">
        </div>
        <template v-if="USER_INFO.user_type==='client'">
            <div class="header-suppliers">
                <img class="header-suppliers-logotype" src="../assets/images/centrobalt_logo.png" alt="">
                <div class="navbar-collapse d-none" id="navbarSupportedContent-1">
                    <ul class="navbar-nav">
                        <li class="nav-item-suppliers">
                            <a class="nav-link dropdown-toggle header-nav-link-dropdown animate__animated animate__slideInLeft" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                            <div class="dropdown-menu dropdown-suppliers animate__animated animate__fadeInLeft" aria-labelledby="navbarDropdown">
                                <ul class="header-suppliers-container">
                                    <li
                                        v-for="(supplier, index) in USER_INFO.suppliers"
                                        :key="supplier.id"
                                        class="header-suppliers-dropdown-menu">
                                        <div class="header-counterparty-dropdown-menu-number">
                                            {{index + 1}}
                                        </div>
                                        <div class="vl"></div>
                                        <div class="header-counterparty-menu-title">
                                            {{supplier.name}}
                                        </div>
                                        <div class="header-counterparty-menu-official-title">
                                            {{supplier.legal_name}}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="header-menu">
                <button class="header-menu-toggler" type="button" data-toggle="collapse">
                    <svg @click="openMenu" :class="{'d-none': MOBILE_MENU_OPEN}" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>
                    <svg @click="closeMenu" class="d-none" :class="{'d-block': MOBILE_MENU_OPEN}" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"/></svg>
                </button>
                <div class="header-menu-items-wrapper">
                    <ul class="header-menu-items-wrapper-items">
                        <li>
                            <a
                                class="header-menu-items-wrapper-items-item"
                                @click.prevent="goTo('products')"
                                :class="{'header-menu-items-wrapper-items-item-active': route_name === 'products' || route_name === 'root'}"
                                href="/products/"
                            >
                                каталог
                            </a>
                        </li>
                        <li>
                            <a
                                class="header-menu-items-wrapper-items-item"
                                @click.prevent="goTo('orders')"
                                :class="{'header-menu-items-wrapper-items-item-active': route_name === 'orders'}"
                                href="/orders/"
                            >
                                заказы
                            </a>
                        </li>
                        <li>
                            <a
                                class="header-menu-items-wrapper-items-item"
                                @click.prevent="goTo('reports')"
                                :class="{'header-menu-items-wrapper-items-item-active': route_name === 'reports'}"
                                href="/reports/"
                            >
                                отчёты
                            </a>
                        </li>
                        <li>
                            <a
                                class="header-menu-items-wrapper-items-item"
                                @click.prevent="goTo('profile')"
                                :class="{'header-menu-items-wrapper-items-item-active': route_name === 'profile'}"
                                href="/profile/"
                            >
                                профиль
                            </a>
                        </li>
                    </ul>
                </div>
                <div ref="_mobile_menu" class="header-menu-mobile-main d-none animate__animated animate__faster">
                    <div @click.prevent="goTo('products')" class="header-menu-mobile-main-item">

                        <p class="header-menu-mobile-main-item-text header-menu-mobile-main-item-text-clear" :class="{'header-menu-items-wrapper-items-item-active': route_name === 'products' || route_name === 'root'}">
                            каталог
                        </p>
                    </div>
                    <div @click="goTo('orders')" class="header-menu-mobile-main-item">

                        <p class="header-menu-mobile-main-item-text header-menu-mobile-main-item-text-clear" :class="{'header-menu-items-wrapper-items-item-active': route_name === 'orders'}">
                            заказы
                        </p>
                    </div>
                    <div @click="goTo('reports')" class="header-menu-mobile-main-item">
                        <p class="header-menu-mobile-main-item-text header-menu-mobile-main-item-text-clear" :class="{'header-menu-items-wrapper-items-item-active': route_name === 'reports'}">
                            отчёты
                        </p>
                    </div>
                    <div @click="openFav" class="header-menu-mobile-main-item header-menu-mobile-main-item-fav">
                        <div class="header-menu-mobile-main-item-icon">
                            <img src="../assets/images/icons_new/favorites.svg">
                        </div>
                        <p class="header-menu-mobile-main-item-text">
                            избранное
                        </p>
                    </div>
                    <div @click="goTo('profile')" class="header-menu-mobile-main-item">
                        <div class="header-menu-mobile-main-item-icon">
                            <img src="../assets/images/icons_new/profile.svg">
                        </div>
                        <p class="header-menu-mobile-main-item-text" :class="{'header-menu-items-wrapper-items-item-active': route_name === 'profile'}">
                            профиль
                        </p>
                    </div>
                </div>
                <div ref="_mobile_menu_profile" class="header-menu-mobile-main header-menu-mobile-profile d-none animate__animated animate__faster">
                    <div @click="showChooseCompanyModal" class="header-menu-mobile-main-item">
                        <div class="header-menu-mobile-main-item-text">
                            <p class="header-profile-title-name">{{USER_INFO.first_name + ' ' + USER_INFO.last_name}}</p>
                            <p class="header-profile-title-company">{{USER_INFO.active_company_info.fact_name}}</p>
                        </div>
                        <div class="header-menu-mobile-main-item-icon">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L14.7874 12.2126V12.2126C14.9048 12.0952 14.9048 11.9048 14.7874 11.7874V11.7874L9 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div @click="goTo('profile')" class="header-menu-mobile-main-item">
                        <div class="header-menu-mobile-main-item-icon">
                            <img src="../assets/images/icons_new/profile.svg">
                        </div>
                        <p class="header-menu-mobile-main-item-text">
                            Профиль
                        </p>
                        <div class="header-menu-mobile-main-item-icon">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L14.7874 12.2126V12.2126C14.9048 12.0952 14.9048 11.9048 14.7874 11.7874V11.7874L9 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div @click="logout" class="header-menu-mobile-main-item">
                        <div class="header-menu-mobile-main-item-icon">
                            <img src="../assets/images/icons_new/exit.svg">
                        </div>
                        <p class="header-menu-mobile-main-item-text">
                            Выйти
                        </p>
                    </div>
                </div>
            </div>
            <div class="header-actions">
                <div @click="goTo('cart')" class="header-actions-item header-actions-item-cart">
                    <div v-if="cart_sum > 0" class="header-actions-item-stub header-actions-item-cart-price" :class="{'header-actions-item-cart-price': cart_sum > 0}">
                        {{cart_sum | splitDigits}} р.
                    </div>
                    <img src="../assets/images/icons_new/cart.svg" alt="">
                    <div class="header-actions-item-subtitle" :class="{'d-block': cart_sum === 0}">
                        корзина
                    </div>
                </div>
                <div @click="openFav" class="header-actions-item header-actions-item-additional">
                    <img src="../assets/images/icons_new/favorites.svg" alt="">
                    <div class="header-actions-item-subtitle">
                        избранное
                    </div>
                </div>
                <div @click="download(USER_INFO.supplier_parameters.price_list)" v-if="USER_INFO.supplier_parameters.price_list !== ''" class="header-actions-item">
                    <img src="../assets/images/icons_new/download_catalogue.svg" alt="">
                    <div class="header-actions-item-subtitle">
                        прайс
                    </div>
                </div>
            </div>
            <div class="header-profile">
                <div class="header-profile-title">
                    <span class="header-profile-title-name">{{USER_INFO.first_name + ' ' + USER_INFO.last_name}}</span>
                    <span class="header-profile-title-separator">{{'\xa0'}}/{{'\xa0'}}</span>
                    <span @click="showChooseCompanyModal" class="header-profile-title-company">{{USER_INFO.active_company_info.fact_name}}</span>
                </div>
                <div @click="openProfileMenu" class="header-actions-item header-profile-mobile">
                    <!--                    <div class="header-actions-item-stub">-->
                    <!--                    </div>-->
                    <img src="../assets/images/icons_new/profile.svg" alt="">
                    <div class="header-actions-item-subtitle">
                        <span class="header-profile-title-company">{{USER_INFO.active_company_info.fact_name}}</span>
                    </div>
                </div>
                <!--                <div class="header-profile-icon">-->
                <!--                    <img src="../assets/images/icons_new/profile.svg" alt="">-->
                <!--                </div>-->
            </div>
        </template>
        <template v-else>
            <div class="header-menu header-menu-admin">
                <button class="header-menu-toggler header-menu-toggler-admin" type="button" data-toggle="collapse">
                    <svg @click="openMenu" :class="{'d-none': MOBILE_MENU_OPEN}" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>
                    <svg @click="closeMenu" class="d-none" :class="{'d-block': MOBILE_MENU_OPEN}" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"/></svg>
                </button>
            </div>
            <div class="header-menu-admin-fill">
            </div>
            <div class="header-actions-item header-actions-item-upload">
                <label for="price_list_file_input">
                    <img src="../assets/images/upload.png" alt="">
                </label>
                <input id="price_list_file_input" ref="price_list_file_input" @change="uploadPriceList" type="file" class="d-none"/>
                <div class="header-actions-item-subtitle">
                    загрузить прайс
                </div>
            </div>
            <div class="header-logout">
                <img @click="logout" src="../assets/images/exit_icon_red.png" alt="">
            </div>
        </template>
    </div>
</template>

<script>
import cFavList from "@/components/products/favorites/cFavList";
import Cart from "@/utils/cart";
import axiosClient from "@/utils/axios/axios";
import cLicenceNotificationModal from "@/components/common/cLicenceNotificationModal";
import cAdmResultModal from "@/components/adm/cAdmResultModal";
import cChooseCompanyModal from '@/components/common/cChooseCompanyModal'
import chatraMixin from "@/utils/mixins/chatra";
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {saveToStorage} from "@/store/local_storage/local_storage";
import moment from 'moment';
import eventHub from "@/utils/event_hub";
import checkLicenceMixin from "@/utils/mixins/check_licence";
import splitDigits from "@/utils/filters/splitDigits";

export default {
    name: "c-header",
    mixins: [checkLicenceMixin, chatraMixin],
    computed: {
        ...mapGetters([
            'USER_INFO',
            'CART',
            'LOGGED',
            'API_URL',
            'MOBILE_MENU_OPEN',
            'WINDOW_WIDTH'
        ]),
        user_info_loaded() {
            return this.USER_INFO.username !== undefined
        },
        route_name() {
            return this.$route.name;
        },
        cart_sum() {
            let sum = 0;
            return this.CART.items.reduce((acc, cur) => acc + cur.product.price * cur.quantity, sum)
        }

    },
    data() {
        return {
            date: '',
            time: '',
            mobile_menu_open: false,
            profile_menu_open: false
        }
    },
    methods: {
        ...mapActions([
            'GET_USER_INFO',
            'GET_CART',
            'CHANGE_USER_DEFAULT_FIRM'
        ]),
        ...mapMutations([
            'SET_API_KEY',
            'SET_LOGGED',
            'SET_CART',
            'TOGGLE_MOBILE_MENU_OPEN',
            'SET_MOBILE_MENU_OPEN'
        ]),
        logout() {
            axiosClient({
                method: 'GET',
                url: this.API_URL + 'logout/',
            }).then(() => {
                localStorage.removeItem('key');
                //localStorage.removeItem('cart');
                this.SET_API_KEY('');
                this.SET_LOGGED(false);
                this.$router.push({name: 'login'})
            });

        },
        goTo(page) {
            this.$router.push({name: page});
            if (this.MOBILE_MENU_OPEN || this.profile_menu_open) {
                this.collapseMenu()
            }

        },
        download(url) {
            window.location.href = url;
        },
        uploadPriceList(event) {
            if(event.target.files.length > 0) {
                let formData = new FormData();
                formData.append('file', event.target.files[0]);
                axiosClient({
                    url: this.API_URL + 'user/upload_price_list/',
                    method: 'POST',
                    data: formData
                }).then((response) => {
                    if (response.data.price_list !== '') {
                        this.showResultModal(response.data)
                    } else {
                        this.showResultModal({errors: ['Произошла ошибка при загрузке прайс-листа']})
                    }
                }).catch(()=> {
                    this.showResultModal({errors: ['Произошла ошибка при загрузке прайс-листа']})
                })
            }

        },
        collapseMenu() {
            // eslint-disable-next-line no-undef
            //$('#navbarSupportedContent').collapse('hide');
            if (this.profile_menu_open) {
                this.closeProfileMenu();
            } else {
                this.closeMenu();
            }

        },
        openMenu(type) {
            if (this.$refs._mobile_menu) {
                this.$refs._mobile_menu.classList.remove('d-none')
                this.$refs._mobile_menu.classList.remove('animate__slideOutLeft')
                this.$refs._mobile_menu.classList.add('animate__slideInLeft')
            }
            this.SET_MOBILE_MENU_OPEN(true);
        },
        closeMenu() {
            if (this.$refs._mobile_menu) {
                this.$refs._mobile_menu.classList.remove('d-none')
                this.$refs._mobile_menu.classList.remove('animate__slideInLeft')
                this.$refs._mobile_menu.classList.add('animate__slideOutLeft')
            }
            this.SET_MOBILE_MENU_OPEN(false);
        },
        openProfileMenu() {
            if (this.WINDOW_WIDTH > 768) {
                this.showChooseCompanyModal()
            } else {
                this.$refs._mobile_menu_profile.classList.remove('d-none')
                this.$refs._mobile_menu_profile.classList.remove('animate__slideOutRight')
                this.$refs._mobile_menu_profile.classList.add('animate__slideInRight')
                //this.SET_MOBILE_MENU_OPEN(true);
                this.profile_menu_open = true
            }
        },
        closeProfileMenu() {
            this.$refs._mobile_menu_profile.classList.remove('d-none')
            this.$refs._mobile_menu_profile.classList.remove('animate__slideInRight')
            this.$refs._mobile_menu_profile.classList.add('animate__slideOutRight')
            this.profile_menu_open = false
        },
        updateTimer() {
            this.date = moment().locale('ru').format('dd') + ', ' + moment().locale('ru').format('LL');
            this.time = moment().locale('ru').format('HH:mm')
        },
        showResultModal(result){
            this.$modal.show(
                cAdmResultModal,
                {
                    result: result
                },
                {
                    name: 'modal_adm_result',
                    resizable: true,
                    scrollable: true,
                    height: 'auto',
                    classes: ['modal-external-container', 'modal-thank-you-error-message']
                }
            )
        },
        showChooseCompanyModal() {
            this.collapseMenu();
            this.$modal.show(
                cChooseCompanyModal,
                {
                },
                {
                    name: 'modal_choose_company',
                    resizable: true,
                    scrollable: true,
                    height: 'auto',
                    classes: ['modal-external-container', 'modal-company-select', 'modal-mobile-fullscreen']
                }
            )
        },
        openFav() {
            this.$modal.show(
                cFavList,
                {},
                {
                    name: 'modal_fav_list',
                    resizable: false,
                    scrollable: true,
                    height: 'auto',
                    classes: ['modal-external-container', 'modal-mobile-fullscreen', 'favorites-main-block']
                }
            )
            if (this.MOBILE_MENU_OPEN || this.profile_menu_open) {
                this.collapseMenu()
            }
        }
    },
    filters: {
        splitDigits
    },
    mounted() {
        // this.updateTimer();
        // setInterval(this.updateTimer, 1000);
        eventHub.$on('user-loaded', this.initChatra);

    },
    destroyed() {
        eventHub.$off('user-loaded', this.initChatra);
    }

}
</script>

<style lang="scss">


</style>
