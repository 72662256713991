<template>
    <div class="h-100">
        <section class="blog-banner-area" id="category">
            <div class="container h-100 d-flex flex-column align-content-center blog-banner">
                <div class="my-auto">
                    <h1>Финансовая информация</h1>
                </div>
            </div>
        </section>

        <section class="my-4 h-100">
            <div class="container">
                <div class="card text-left card-product">
                    <div class="card-body h-100 d-flex flex-column product-row pt-0 px-0">
                        <div class="order-header-bar d-flex flex-row align-items-center">
                            <h4 class="card-product__title mb-0 mr-2">Информация о задолженности</h4>
                        </div>
                        <div class="mb-1 px-2">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3">
                                    Сумма текущей задолженности
                                </div>
                                <div class="col-12 col-sm-12 col-md-9 text-nowrap">
                                    <b>{{ FINANCE.firm_debt.current_debt | splitDigits}} ₽</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3">
                                    Сумма просроченной задолженности
                                </div>
                                <div class="col-12 col-sm-12 col-md-9 text-nowrap">
                                    <b>{{ FINANCE.firm_debt.overdue_debt | splitDigits }} ₽</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3">
                                    Сумма всех платежей
                                </div>
                                <div class="col-12 col-sm-12 col-md-9 text-nowrap">
                                    <b>{{ FINANCE.firm_debt.payment | splitDigits }} ₽</b>
                                </div>
                            </div>
                        </div>
                        <div class="order-header-bar d-flex flex-row align-items-center mt-3">
                            <h4 class="card-product__title mb-0 mr-2">Список платежей</h4>
                        </div>
                        <div class="table-responsive px-2">
                            <div class="d-flex align-items-center mb-3">
                                <div class="input-group filter-bar-search d-flex flex-wrap flex-md-nowrap align-items-center">
                                    <datepicker
                                            :calendar-class="'datepicker-calendar-fix'"
                                            :format="'dd.MM.yyyy'"
                                            :language="ru"
                                            :monday-first="true"
                                            @input="applyFilter"
                                            v-model="date_from"
                                    > </datepicker>
                                    <p class="mx-2">—</p>
                                    <datepicker
                                            :wrapper-class="'mt-2 mt-md-0'"
                                            :calendar-class="'datepicker-calendar-fix'"
                                            :format="'dd.MM.yyyy'"
                                            :language="ru"
                                            :monday-first="true"
                                            @input="applyFilter"
                                            v-model="date_to"
                                    > </datepicker>
                                </div>
                            </div>
                            <table v-if="FINANCE.payments.length > 0" class="table table-borderless">
                                <thead>
                                <tr class="border-bottom">
                                    <th scope="col" class="text-center">Номер</th>
                                    <th scope="col" class="text-center">Дата</th>
                                    <th scope="col" class="text-center">Сумма</th>
                                    <th scope="col" class="text-center">Аванс</th>
                                    <th scope="col" class="text-center">Фрма расчета</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="(pay, index) in FINANCE.payments"
                                        :key="index"
                                        class="border-bottom"
                                >
                                    <td class="text-center">{{ pay.payment_number }}</td>
                                    <td class="text-center">{{ pay.payment_date | formatDate }}</td>
                                    <td class="text-nowrap text-right">{{ pay.total | splitDigits }} ₽</td>
                                    <td class="text-nowrap text-right">{{ pay.prepaid | splitDigits }} ₽</td>
                                    <td class="text-center">{{ pay.payment_type_name }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div v-else>
                                <h6>Платежей за выбранный диапазон нет!</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import splitDigits from "@/utils/filters/splitDigits";
    import formatDate from "@/utils/filters/formatDate";
    import Datepicker from 'vuejs-datepicker'
    import {ru} from 'vuejs-datepicker/dist/locale'
    import moment from 'moment'

    export default {
        name: "c-finance",
        computed: {
            ...mapGetters([
                'FINANCE'
            ])
        },
        data() {
            return {
                ru: ru,
                date_from: undefined,
                date_to: undefined
            }
        },
        methods: {
            ...mapActions([
                'GET_FINANCE'
            ]),
            applyFilter() {
                let range = moment(this.date_from).format('DD.MM.YYYY') + ' - ' + moment(this.date_to).format('DD.MM.YYYY');
                this.GET_FINANCE(range);
            }
        },
        filters: {
            splitDigits,
            formatDate
        },
        components: {
            Datepicker
        },
        mounted() {
            this.GET_FINANCE('')
                .then(() => {
                    let daterange = this.FINANCE.date_range.split(' - ');
                    this.date_from = moment(daterange[0], 'DD.MM.YYYY').toDate();
                    this.date_to = moment(daterange[1], 'DD.MM.YYYY').toDate();
                })
        }
    }
</script>

<style scoped>

</style>