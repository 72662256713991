<template>
    <div class="admin-users-edit">
        <c-loading-overlay-page v-if="loading"></c-loading-overlay-page>
        <div class="admin-users-edit-block admin-users-edit-form">
            <div class="admin-users-edit-form-item">
                <img src="../../../../assets/images/My_Profile.png" alt="">
                <input type="text" v-model="name" class="input-line" placeholder="Имя Фамилия">
            </div>
            <div class="admin-users-edit-form-item">
                <img src="../../../../assets/images/Phone_Number.png" alt="">
                <imask-input
                    :mask="'+{7} (000) 000-00-00'"
                    v-model="phone"
                    :lazy="true"
                    :unmask="true"
                    @accept="setPhone"
                    type="tel"
                    class="input-line"
                    placeholder="+7 ("
                    :disabled="action==='update'"
                ></imask-input>
            </div>
            <div class="admin-users-edit-form-item">
                <img src="../../../../assets/images/E-mail_.png" alt="">
                <input v-model="email" type="text" class="input-line" placeholder="E-mail">
            </div>
            <div class="admin-users-edit-form-item">
                <img src="../../../../assets/images/E-mail_.png" alt="">
                <input v-model="additional_emails" type="text" class="input-line" placeholder="Доп. email (через ;)">

            </div>
            <div class="admin-users-edit-form-item">
                <img src="../../../../assets/images/Password.png" alt="">
                <input v-model="password" type="password" class="input-line" placeholder="Пароль" autocomplete="new-password">

            </div>
            <div v-if="action === 'update'" class="checkbox checkbox-red">
                <label>
                    <input @change="setActive" type="checkbox" :checked="is_active === 0">
                    <span class="checkbox-checkmark"></span>
                    <span>доступ к порталу заблокирован</span>
                </label>
            </div>
            <button @click="saveUser" class="button button-fill">
                сохранить
            </button>
            <button v-if="action === 'update'" @click="confirmDelete" class="button button-red">
                удалить аккаунт
            </button>
        </div>
        <div class="admin-users-edit-block admin-users-edit-companies">
            <p>Контрагенты, ассоциированные с пользователем:</p>
            <ul v-if="companies.length > 0" class="admin-users-edit-companies-list">
                <li v-for="(company, index) in companies" :key="index" :class="{'adm-search-company-invalid': company.is_problem === 1}">
                    <p>{{company.fact_name}} ({{company.legal_name}})</p>
                    <img @click="deleteCompany(index)" src="../../../../assets/images/remove.png" alt="">
                </li>
            </ul>
            <button @click="openCompanyModal" class="button mt-3" type="button" role="button">добавить</button>
        </div>
    </div>
</template>

<script>
    import cAdmSearchCompanyModal from "@/components/adm/pages/users/cAdmSearchCompanyModal";
    import cAdmConfirmModal from "@/components/adm/cAdmConfirmModal";
    import cLoadingOverlayPage from "@/components/common/loading/cLoadingOverlayPage";
    import {IMaskComponent} from 'vue-imask';
    import axiosClient from "@/utils/axios/axios";
    import {mapGetters} from 'vuex';

    export default {
        name: "c-adm-users-edit",
        props: {
            action: {
                type: String,
                default() {
                    return 'create'
                }
            },
            user_data: {
                type: Object,
                default() {
                    return null;
                }
            }
        },
        data() {
            return {
                name: '',
                phone: '',
                email: '',
                additional_emails: '',
                password: '',
                is_active: -1,
                companies: [],
                loading: false
            }
        },
        computed: {
            ...mapGetters(['API_URL']),
            company_ids() {
                let ids = [];
                this.companies.forEach((el) => {
                    ids.push(el.id)
                });
                return ids
            }
        },
        methods: {
            setPhone(value) {
                this.phone = value;
            },
            saveUser(is_delete) {
                let formData = new FormData();
                formData.append('action', is_delete === true ? 'delete' : this.action);
                formData.append('name', this.name);
                formData.append('phone', this.phone);
                formData.append('email', this.email);
                formData.append('additional_emails', this.additional_emails);
                formData.append('is_active', this.is_active);
                formData.append('password', this.password);
                formData.append('company_ids', this.company_ids.join(','));
                this.loading = true;
                axiosClient({
                    url: this.API_URL + 'user/edit_by_supplier/',
                    method: 'POST',
                    data: formData
                }).then((res) => {
                    this.loading = false;
                    this.$emit('show-result', res.data);
                    if (res.data.errors.length === 0 && this.action === 'create') {
                        this.init()
                    }
                    if (res.data.errors.length === 0 && is_delete === true) {
                        this.$emit('user-deleted')
                    }
                }).catch(() => {
                    this.loading = false;
                    this.$emit('show-result', {errors: ['Ошибка при отправке данных.']});
                })
            },
            init() {
                this.name = '';
                this.phone = '';
                this.email = '';
                this.additional_emails = '';
                this.is_active = -1;
                this.companies = [];
                this.password = ''
            },
            openCompanyModal(){
                this.$modal.show(
                    cAdmSearchCompanyModal,
                    {
                        addCompany: this.addCompany
                    },
                    {
                        name: 'modal_search_company',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-mobile-fullscreen']
                    }
                )
            },
            addCompany(company){
                this.companies.push(company)
            },
            deleteCompany(index) {
                this.companies.splice(index, 1)
            },
            setActive(event){
                this.is_active = event.target.checked === true ? 0 : 1;
            },
            confirmDelete() {
                this.$modal.show(
                    cAdmConfirmModal,
                    {
                        deleteFromModal: this.deleteFromModal
                    },
                    {
                        name: 'modal_confirm_delete',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-thank-you-error-message']
                    }
                )
            },
            deleteFromModal() {
                this.saveUser(true)
            }
        },
        components: {
            'imask-input': IMaskComponent,
            cLoadingOverlayPage
        },
        mounted() {
            if (this.user_data !== null) {
                this.name = this.user_data.first_name + ' ' + this.user_data.last_name;
                this.phone = this.user_data.username;
                this.email = this.user_data.email;
                this.additional_emails = this.user_data.additional_emails;
                this.is_active = this.user_data.is_active;
                this.companies = this.user_data.companies;
            }
        }
    }
</script>

<style scoped>

</style>
