import Product from "@/store/models/Product";
import SimpleReference from "@/store/models/SimpleReference";


let Order = {
    id: 0,
    number: '',
    creation_date: '',
    date: '',
    company: {
      fact_name: '',
      legal_name: '',
      fact_address: '',
      legal_address: '',
      inn: '',
      kpp: ''
    },
    execution_date: '',
    delivery_time: '',
    status: {
        id: 0,
        name: '',
        condition: {
            id: 0,
            name: ''
        }
    },
    total_plan: 0.0,
    total_fact: 0.0,
    payment_type: SimpleReference,
    payment_condition: SimpleReference,
    payment_date: '',
    debt: 0.0,
    overdue_debt: 0.0,
    comment: '',
    forwarder_info: {},
    products: []
};

export default Order