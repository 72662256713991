<template>
    <c-loading v-if="CART_LOADING"></c-loading>
    <div v-else class="page cart">
        <template  v-if="CART.items.length > 0">
            <c-cart-loading v-if="sending_order"></c-cart-loading>
            <h1 class="page-title">Оформление заказа</h1>
            <h2 class="page-subtitle" >Договор №{{USER_INFO.active_company_info.contract.number}}</h2>
            <p class="page-subtitle">Выберите желаемые<span style="color: #175289;">{{'\xa0'}}дату{{'\xa0'}}</span>и<span style="color: #175289;">{{'\xa0'}}время{{'\xa0'}}</span>доставки:</p>
            <div class="cart-datetime" :class="{'input-invalid': date_invalid}">
                <div class="cart-datetime-item">
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :format="'dd.MM.yyyy'"
                        :disabledDates="disabled_dates"
                        :language="ru"
                        :monday-first="true"
                        v-model="shipping_date"
                        v-on:selected="date_invalid = false"
                    > </datepicker>
                    <div v-if="date_invalid" class="input-invalid-message">
                        Выберите дату доставки!
                    </div>
                </div>
                <div class="cart-datetime-item">
                    <c-select
                        :options="delivery_time_intervals"
                        :current_value="delivery_time_interval"
                        @option-selected="timeSelect"
                        ref="sort_select"
                    >
                    </c-select>
                </div>
            </div>
            <div class="cart-products">
                <div class="orders-details-content-table">
                    <div class="orders-details-content-table-row orders-details-content-table-head cart-products-head">
                        <div class="orders-details-content-table-index">
                            №
                        </div>
                        <div class="orders-details-content-table-name">
                            <span>позиция</span>
                            <div class="orders-details-content-table-divider"></div>
                        </div>
                        <div class="orders-details-content-table-fields">
                            <div class="orders-details-content-table-fields-volume">
                                объём
                            </div>
                            <div class="orders-details-content-table-fields-amount">
                                кол-во
                            </div>
                            <div class="orders-details-content-table-fields-price">
                                цена
                            </div>
                            <div class="orders-details-content-table-fields-total">
                                стоимость
                            </div>
                            <div class="cart-products-item-remove">
                            </div>
                        </div>
                    </div>
                    <c-cart-item
                        v-for="(item, index) in CART.items"
                        :key="item.product.id + '_' + list_remount_key"
                        :index="index+1"
                        :cart_item="item"
                    >
                    </c-cart-item>
                </div>
                <div class="cart-divider"></div>
                <div class="cart-total">
                    <p>Итог:</p>
                    <p class="cart-total-sum">
                        {{total_sum | splitDigits}} p.
                    </p>
                </div>
            </div>

            <textarea v-model="comment" class="cart-comment animate__animated animate__fadeInDown" id="feedback-message" name="feedbackMessage" placeholder="Ваш комментарий:">
            </textarea>

            <div class="cart-buttons">
                <button @click="exportPdf" class="button">экспорт в PDF</button>
                <button @click="saveDraft" class="button">сохранить в отложенные</button>
                <button @click="sendOrder" class="button button-fill" type="button" role="button">отправить заказ</button>
            </div>
            <p v-if="total_sum > 0 && total_sum < USER_INFO.supplier_parameters.min_total" class="cart-sum-warning"><span class="cart-sum-warning-red">Заказ НЕ БУДЕТ доставлен</span> — минимальная сумма <span class="nowrap">{{USER_INFO.supplier_parameters.min_total | splitDigits}} р.</span></p>
            <div class="cart-info">
                <div class="cart-info-item">
                    <span>Вид платежа:</span> {{USER_INFO.active_company_info.payment_condition.name}}
                </div>
                <div class="cart-info-item">
                    <span>Способ платежа:</span> {{USER_INFO.active_company_info.payment_type.name}}
                </div>
                <div class="cart-info-item">
                    <span>Адрес доставки:</span> {{USER_INFO.active_company_info.fact_address}}
                </div>
            </div>
        </template>
        <template v-else>
            <h1 class="page-title">Корзина пуста!</h1>
            <div class="cart-buttons">
                <input class="button" type="button" role="button" @click="$router.push({name:'products'})" value="перейти в каталог">
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import cCartItem from "@/components/cart/cCartItem";
import cLoading from "@/components/common/loading/cLoading";
import cOrderResultModal from "@/components/cart/cOrderResultModal";
import axiosClient from "@/utils/axios/axios";
import splitDigits from "@/utils/filters/splitDigits";
import eventHub from "@/utils/event_hub";
import cCartLoading from "@/components/common/loading/cCartLoading";
import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import checkLicenceMixin from "@/utils/mixins/check_licence";
import cSelect from '@/components/common/cSelect';

export default {
    name: "c-cart",
    mixins: [checkLicenceMixin],
    computed: {
        ...mapGetters([
            'CART',
            'USER_INFO',
            'TOKEN',
            'API_URL',
            'CART_PAGE',
            'CART_LOADING'
        ]),
        delivery_time_intervals() {
            let result = [
                {
                    caption: 'Любое',
                    value: '00:00-00:00'
                }
            ]
            return result.concat(this.USER_INFO.supplier_parameters.delivery_time_intervals.map((el) => {
                let caption = '';
                if (el.start === '') {
                    caption = 'до ' + el.end;
                } else if (el.end === '') {
                    caption = 'c ' + el.start;
                } else {
                    caption = el.start + ' — ' + el.end
                }
                return {caption: caption, value: el.start + '-' + el.end}
            }));

        },
        total_sum() {
            return this.CART.items.reduce((acc, cur) => acc + cur.product.price * cur.quantity, 0)
        },
        loading() {
            return this.CART.company_id !== this.USER_INFO.active_company_info.id
        }
    },
    data() {
        return {
            comment: '',
            shipping_date: null,
            restricted_days: [],
            restricted_weekdays: [],
            disabled_dates: {
                days: [],
                dates: [],
                to: moment().subtract(1, 'days').toDate(),
            },
            date_invalid: false,
            ru: ru,
            sending_order: false,
            items: [],
            first_load: false,
            delivery_time_interval: '00:00-00:00',
            timer: undefined,
            list_remount_key: 0
        }
    },
    methods: {
        ...mapMutations([
            'SET_CART'
        ]),
        ...mapActions([
            'GET_ORDER_DRAFTS',
            'ADD_ORDER_DRAFT',
            'GET_CART'
        ]),
        /**
         * Отправка заказа. Проверяем дату доставки, время. Формируем массив для API.
         */
        sendOrder() {
            if (this.shipping_date !== null) {
                this.sending_order = true;
                let order = {
                    shipping_date: moment(this.shipping_date).format('DD.MM.YYYY'),
                    shipping_time_interval: this.delivery_time_interval,
                    comment: this.comment,
                };
                let products = [];
                this.CART.items.forEach(function (el) {
                    products.push(
                        {
                            product_id: el.product.id,
                            price: parseFloat(el.product.price),
                            quantity: el.quantity,
                            total: el.product.price * el.quantity
                        }
                    );
                });
                order.products = products;
                axiosClient({
                    method: 'POST',
                    url: this.API_URL + 'orders/create/',
                    data: JSON.stringify(order),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response) => {
                        let result = response.data;
                        let comment = '';
                        if (this.total_sum < this.USER_INFO.supplier_parameters.min_total) {
                            comment = 'Заказ НЕ БУДЕТ доставлен — минимальная сумма ' + splitDigits(this.USER_INFO.supplier_parameters.min_total)
                        }
                        if (result.errors.length === 0) {
                            this.CART.clear();
                            if (this.$metrika !== undefined) {
                                this.$metrika.reachGoal('order_save')
                            }
                            //this.loadData();
                        } else {
                            //this.loadData();
                        }
                        this.sending_order = false;
                        this.showResultModal(result, comment)
                    })
                    .catch(() => {
                        this.sending_order = false;
                    });
            } else {
                this.date_invalid = true;
                this.$scrollTo(this.$refs['caption'])
            }
        },
        /**
         * Сохранение черновика. Сохраняем товарный состав и комментарий
         */
        saveDraft() {
            this.sending_order = true;
            let products = [];
            this.CART.items.forEach(function (el) {
                products.push(
                    {
                        product_id: el.product.id,
                        quantity: parseInt(el.quantity),
                    }
                );
            });

            let draft = {
                comment: this.comment,
                products: products
            };
            this.ADD_ORDER_DRAFT(draft)
                .then((response) => {
                    let result = response.data;
                    if (result.errors.length === 0) {
                        this.CART.clear();
                        //this.loadData();
                    } else {
                        //this.loadData();
                    }
                    this.sending_order = false;
                    this.showResultModal(result)
                })
                .catch(() => {
                    this.sending_order = false;
                })

        },
        /**
         * Экспорт товаров из корзины в PDF. Список товаров в таком же формате, как и у заказа.
         * Остальные поля игнорируются.
         */
        exportPdf() {
            this.sending_order = true;
            let products = [];
            this.CART.items.forEach(function (el) {
                products.push(
                    {
                        product_id: el.product.id,
                        price: parseFloat(el.product.price),
                        quantity: el.quantity,
                        total: el.product.price * el.quantity
                    }
                );
            });

            let data = {
                products: products,
                comment: this.comment
            };
            axiosClient({
                method: 'POST',
                url: this.API_URL + 'orders/export/',
                data: data
            }).then((response) => {
                if(response.data.file !== undefined && response.data.file !== '') {
                    window.location.href = response.data.file
                }
                this.sending_order = false
            }).catch(() => {
                this.sending_order = false
            });

        },
        /**
         * [УДАЛЕНО] Загрузка описаний и актуальных цен товаров с сервера. В LocalStorage хранятся только id и количество.
         * Оставлено только обновление дат и времени доставки
         */
        loadData() {
            // this.GET_CART()
            // if (this.first_load === false) {
            //     this.loading = true;
            // }
            // let itemIds = [];
            // this.items_ = this.CART.items;
            // if (this.CART.items.length > 0) {
            //     this.CART.items.forEach((el) => {
            //         itemIds.push(el.id)
            //     });
            //     let itemIdsStr = itemIds.join(',');
            //     this.GET_CART_PAGE(itemIdsStr)
            //         .then(() => {
            //             this.items = [];
            //             this.CART_PAGE.products.forEach((el) => {
            //                 let item = el;
            //                 item.qty = this.CART.getCurrentQty(item.id);
            //                 this.items.push(item)
            //             });
            //             this.first_load = false;
            //             this.loading = false;
            //             this.totalSum()
            //         })
            // } else {
            //     this.first_load = false;
            //     this.loading = false;
            // }
            this.disabled_dates.dates = this.USER_INFO.supplier_parameters.shipment_restrict_days.map(
                (el) => {
                    return moment(el, 'DD.MM.YYYY').toDate()
                }
            );
            this.disabled_dates.days = [1,2,3,4,5,6,7].filter(
                (el) => {
                    return !this.USER_INFO.active_company_info.delivery_zone.delivery_days.includes(el)
                }
            );
            this.disabled_dates.days = this.disabled_dates.days.map((el) => {return el === 7 ? 0 : el})
        },
        /**
         * Сброс всех данных корзины при смене компании
         *
         */
        reloadByCompanyChanged() {
            this.items = [];
            this.shipping_date = null;
            this.delivery_time_interval = '00:00-00:00';
            this.comment = '';
            this.loadData();
        },

        /**
         * Модалка с результатом сохранения заказа/черновика
         * @param order_result
         * @param comment
         */
        showResultModal(order_result, comment = '') {
            this.$modal.show(
                cOrderResultModal,
                {
                    order_result: order_result,
                    comment: comment
                },
                {
                    name: 'modal_order_result',
                    resizable: true,
                    scrollable: true,
                    height: 'auto',
                    classes: ['modal-external-container', 'modal-thank-you-error-message']
                }
            )
        },

        /**
         * Хак для обновления корзины при наступлении глобального события cart-modified. Глобальное событие вызывается,
         * например, из модалки с избранным (из компонента cCartControls).
         * Здесь мы ремаунтим список товаров, чтобы обновить колиечство.
         */
        updateCartFromExternal() {
            this.list_remount_key++;
        },

        timeSelect(value) {
            this.delivery_time_interval = value.value;
        }

    },
    filters: {
        splitDigits
    },
    components: {
        cCartLoading,
        cCartItem,
        cLoading,
        Datepicker,
        cSelect
    },
    mounted() {
        this.comment = this.CART.comment;
        eventHub.$on('user-loaded', this.reloadByCompanyChanged);
        eventHub.$on('user-loaded', this.checkLicence);
        eventHub.$on('cart-modified', this.updateCartFromExternal);
    },
    destroyed() {
        eventHub.$off('user-loaded', this.reloadByCompanyChanged);
        eventHub.$off('user-loaded', this.checkLicence);
        eventHub.$off('cart-modified', this.updateCartFromExternal);
    }
}

</script>

<style scoped>

</style>
