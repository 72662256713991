<template>
    <div class="admin-users-list">
        <input @input="search" v-model="search_string" type="text" class="input-line" placeholder="Поиск">
        <div class="admin-users-list-table-container mt-3" :class="{'overflow-hidden': users_loading}">
<!--            <c-cart-loading v-if="users_loading"></c-cart-loading>-->
            <table class="admin-users-list-table mt-3">
                <tr class="admin-users-list-table-header">
                    <td>наименование</td>
                    <td>юр. наименование</td>
                    <td>ИНН</td>
                    <td>телефон</td>
                    <td>e-mail</td>
                    <td>Имя Фамилия</td>
                    <td>менеджер</td>
                </tr>
                <c-adm-user-company-item
                    v-for="(user_company_item, index) in registered_users"
                    :key="index"
                    :user_company_item="user_company_item"
                ></c-adm-user-company-item>
            </table>
        </div>
    </div>
</template>

<script>
import cAdmUserCompanyItem from "@/components/adm/pages/users/cAdmUserCompanyItem";
import cCartLoading from "@/components/common/loading/cCartLoading";
import axiosClient from "@/utils/axios/axios";
import {mapGetters} from "vuex";
export default {
    name: "c-adm-user-companies",
    data() {
        return {
            registered_users: [],
            timer: null,
            search_string: '',
            users_loading: false
        }
    },
    computed: {
        ...mapGetters(['API_URL'])
    },
    methods: {
        getRegisteredUsers() {
            this.users_loading = true;
            axiosClient({
                method: 'GET',
                url: this.API_URL + 'company/get_registered_list/?search=' + this.search_string
            }).then((response) => {
                this.registered_users = response.data;
                this.users_loading = false;
            }).catch(() => {
                this.users_loading = false;
            })
        },
        search: function () {
            clearTimeout(this.timer);
            this.timer = setTimeout(function(){
                this.getRegisteredUsers();
            }.bind(this), 500)
        },
    },
    mounted() {
        this.registered_users = [];
        this.getRegisteredUsers();
    },
    components: {
        cAdmUserCompanyItem,
        //cCartLoading
    }
}
</script>

<style scoped>

</style>
