<template>
    <div class="admin-orders">
        <h1 class="admin-content-title">Правила добавления продукции и работы с заказами</h1>
        <div class="admin-orders-block">
            <h2 class="admin-orders-block-title">
                Добавление товара, отсутствующего на остатках:
            </h2>

            <div class="admin-orders-rest-buttons">
                <button @click="setValue(1)" class="button" :class="{'button-fill': no_rest === 1}">добавлять</button>
                <button @click="setValue(0)" class="button" :class="{'button-fill': no_rest === 0}">не добавлять</button>
            </div>

        </div>
        <div class="admin-orders-block">
            <h2 class="admin-orders-block-title">
                Правило оформления безалкогольной продукции
            </h2>
            <p class="text-center">Добавлять безалкогольные позиции в заказ можно:</p>
            <div class="admin-orders-alco-free-form">
                <div class="admin-orders-alco-free-form-radio">
                    <label for="packs-only">
                        <input v-model="alco_free_pack" type="radio" id="packs-only" name="packs" value="1">
                        только в упаковках
                    </label>
                    <label for="packs-and-separate-units">
                        <input v-model="alco_free_pack" type="radio" id="packs-and-separate-units" name="packs" value="2">
                        россыпью и в упаковках
                    </label>
                </div>
                <button @click="setValueAlcoFree" class="button">сохранить</button>
            </div>
        </div>

        <div class="admin-orders-block admin-orders-sum">
            <h2 class="admin-orders-block-title">
                Минимальная сумма заказа
            </h2>
            <p class="text-center">Введите значение минимальной суммы заказа (в руб.):</p>
            <div class="admin-users-edit-form-item admin-orders-sum-form">
                <input @keydown="inputRestrictFloat" v-model="min_total" type="text" class="input-line">
                <button @click="setMinTotal" class="button">сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    import {inputRestrictFloat} from "@/utils/input_restrict";
    import splitDigits from "@/utils/filters/splitDigits";

    export default {
        name: "c-adm-no-rest",
        mixins: [admSetValueMixin],
        data() {
            return {
                no_rest: null,
                alco_free_pack: null,
                alco_free_pack_actual: null,
                min_total: null,
                min_total_actual: null
            }
        },
        methods: {
            setValue(val){
                this.setValueToApi({name: 'no_rest', value: val})
                    .then(() => {
                        this.showResultModal()
                        this.init()
                    });
            },
            setValueAlcoFree(){
                this.setValueToApi({name: 'alco_free_pack', value: this.alco_free_pack})
                    .then(() => {
                        this.showResultModal()
                        this.init()
                    });
            },
            setMinTotal() {
                this.setValueToApi({name: 'min_total', value: this.min_total})
                    .then(() => {
                        this.showResultModal()
                        this.init()
                    });
            },
            inputRestrictFloat: inputRestrictFloat,
            init(){
                this.no_rest = this.USER_INFO.supplier_parameters.no_rest;
                this.alco_free_pack = this.USER_INFO.supplier_parameters.alco_free_pack.toString();
                this.alco_free_pack_actual = this.alco_free_pack;
                this.min_total = parseFloat(this.USER_INFO.supplier_parameters.min_total);
                this.min_total_actual = this.min_total
            }
        },
        filters: {
            splitDigits
        }
    }
</script>

<style scoped>

</style>
