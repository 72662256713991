import Company from "@/store/models/Company";
import Supplier from "@/store/models/Supplier";
import SupplierParameters from "@/store/models/SupplierParameters";
import FinanceInfo from "@/store/models/FinanceInfo";

let UserInfo =
    {
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            user_type: '',
            active_company_info: Company,
            company_list: [],
            active_supplier: Supplier,
            suppliers: [],
            supplier_parameters: SupplierParameters,
            finance: FinanceInfo,
        cart: {
                items: []
        }
    };

export default UserInfo