<template>
    <div ref="_filters" class="catalogue-filters" :class="{'catalogue-filters-show': filter_show === true}">
        <div class="catalogue-filters-title">
            <img class="catalogue-filters-title-icon" src="../../../assets/images/filters.png" alt="">
            <div>фильтры</div>
            <div @click="toggleCollapseFilter" class="cross-close"></div>
        </div>
        <c-range-filter
                v-for="filter_set in filterSets.ranges"
                :key="filter_set.name"
                :caption="filter_set.caption"
                :filter_name="filter_set.name"
                :range="filter_set.range"
                :data="filter_set.data"
                :collapsable="false"
                :interval="10"
                v-on:apply-filter="onApplyProductFilter"
        >
        </c-range-filter>
        <c-checkbox-filter
                v-for="filter_set in filterSets.checkboxes"
                :key="filter_set.name"
                :filter_items="filter_set.items"
                v-bind:caption="filter_set.caption"
                v-bind:filter_name="filter_set.name"
                v-bind:name_to_value="false"
                v-on:apply-filter="onApplyProductFilter"
        >
        </c-checkbox-filter>
        <button @click="clearFilters" class="catalogue-filters-block-show-button catalogue-filters-block-show-button-cancel">сбросить все</button>
    </div>
</template>

<script>
    import cCheckboxFilter from "@/components/products/filters/cCheckboxFilter";
    import cRangeFilter from "@/components/products/filters/cRangeFilter";
    import cToggleFilter from "@/components/products/filters/cToggleFilter";
    import cFavList from "@/components/products/favorites/cFavList";
    import eventHub from "@/utils/event_hub";

    export default {
        name: "c-side-filters",
        props: {
            filterSets: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                filter_show: false,
            }
        },
        methods: {
            onApplyProductFilter(filter) {
                this.$emit('apply-filter', filter)
            },
            clearFilters() {
                this.$emit('clear-filters')
            },
            toggleCollapseFilter() {
                this.filter_show = !this.filter_show;
            }
        },
        mounted() {
            eventHub.$on('toggle-filters', this.toggleCollapseFilter)
        },
        destroyed() {
            eventHub.$off('toggle-filters', this.toggleCollapseFilter)
        },
        components: {
            cCheckboxFilter,
            cRangeFilter,
        }
    }
</script>

<style scoped>

</style>
