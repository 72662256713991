<template>
    <div class="page">
        <button @click="logout" class="profile-exit-button">
            <img src="../../assets/images/exit_icon.png" alt="">
            <p>{{'\xa0'}}Выход</p>
        </button>

        <h1 class="page-title">Мой профиль</h1>
        <div class="profile-personal">
            <div class="profile-personal-item">
                <img class="profile-personal-item-icon" src="../../assets/images/My_Profile.png" alt="">
                <input @change="saveUser" v-model="username" type="text" class="input-line">
            </div>
            <div class="profile-personal-item">
                <img class="profile-personal-item-icon" src="../../assets/images/E-mail_.png" alt="">
                <input @change="saveUser" v-model="email" type="text" class="input-line">
            </div>
            <div class="profile-personal-item">
                <img class="profile-personal-item-icon" src="../../assets/images/Phone_Number.png" alt="">
                {{phone}}
            </div>
            <div class="profile-personal-item">
                <button @click="showPasswordModal" class="button button-red" type="button" role="button">изменить пароль</button>
            </div>
        </div>

        <div class="profile-divider"></div>

        <div class="profile-financial">
            <div class="profile-financial-item">
                <img class="exclamation-point" src="../../assets/images/yellow-triangle-exclamation-mark.png" alt="">
                <p class="profile-financial-item-title">общая сумма текущей задолженности:</p>
                <p class="profile-financial-item-sum">{{USER_INFO.finance.current_debt | splitDigits}}</p>
            </div>
            <div class="profile-financial-item">
                <img class="exclamation-point" src="../../assets/images/red-triangle-exclamation-mark.png" alt="">
                <p class="profile-financial-item-title">общая сумма просроченной задолженности:</p>
                <p class="profile-financial-item-sum">{{USER_INFO.finance.overdue_debt | splitDigits}}</p>
            </div>
        </div>

        <div class="profile-divider"></div>

        <div class="profile-companies">
            <h2>Контрагенты</h2>
            <c-user-company-item
                    v-for="company in USER_INFO.company_list"
                    :key="company.id"
                    :company="company"
                    :active_firm_id="USER_INFO.active_company_info.id"
            >
            </c-user-company-item>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import cUserCompanyItem from "@/components/profile/cUserCompanyItem";
    import cPasswordChangeModal from "@/components/profile/cPasswordChangeModal";
    import splitDigits from "@/utils/filters/splitDigits";
    import eventHub from "@/utils/event_hub";
    import axiosClient from "@/utils/axios/axios";

    export default {
        name: "c-profile",
        data() {
            return {
                username: '',
                email: ''
            }
        },
        computed: {
            ...mapGetters([
                'USER_INFO',
                'API_URL'
            ]),
            phone(){
                let normalized = this.USER_INFO.username;
                let arr = normalized.split('');
                return '+' + arr[0] + ' (' + arr.slice(1, 4).join('') + ') ' + arr.slice(4, 7).join('') + '-' + arr.slice(7, 9).join('') + '-' + arr.slice(9).join('')
            }
        },
        methods: {
            ...mapMutations(['SET_USER_DATA', 'SET_API_KEY', 'SET_LOGGED']),
            init() {
                this.username = [this.USER_INFO.first_name, this.USER_INFO.last_name].join(' ');
                this.email = this.USER_INFO.email
            },
            saveUser(){
                let formData = new FormData();
                formData.append('username', this.username);
                formData.append('email', this.email);
                axiosClient({
                    method: 'POST',
                    url: this.API_URL + 'user/change/',
                    data: formData
                }).then((res) => {
                    if (res.data.user_info !== undefined) {
                        this.SET_USER_DATA(res.data.user_info )
                    }
                })
            },
            showPasswordModal(){
                this.$modal.show(
                    cPasswordChangeModal,
                    {
                    },
                    {
                        name: 'modal_save_password',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-thank-you-error-message']
                    }
                )
            },
            logout() {
                axiosClient({
                    method: 'GET',
                    url: this.API_URL + 'logout/',
                }).then(() => {
                    localStorage.removeItem('key');
                    //localStorage.removeItem('cart');
                    this.SET_API_KEY('');
                    this.SET_LOGGED(false);
                    this.$router.push({name: 'login'})
                });

            },

        },
        components: {
            cUserCompanyItem
        },
        filters: {
            splitDigits
        },
        mounted() {
            if (this.USER_INFO.finance === undefined) {
                eventHub.$on('user-loaded', this.init);
            } else {
                this.init()
            }
        },
        destroyed() {
            eventHub.$off('user-loaded', this.init);
        }
    }
</script>

<style scoped>

</style>
