<template>
    <div v-if="!inCart && addAllowed" class="catalogue-controls-add">
        <img @click.stop="addToCart" src="../../assets/images/icons_new/add_to_cart.svg" alt="">
    </div>
    <div v-else-if="inCart && addAllowed" class="catalogue-controls-added">
            <div @click.stop.prevent="minus" class="catalogue-controls-added-button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 604.02 604.03">
                    <path d="M400,702a302,302,0,1,1,213.55-88.45A300.06,300.06,0,0,1,400,702Zm0-577c-151.64,0-275,123.36-275,275S248.36,675,400,675,675,551.64,675,400,551.64,125,400,125ZM533.33,425H266.67a25,25,0,0,1,0-50H533.33a25,25,0,0,1,0,50Z" transform="translate(-97.99 -97.99)"/>
                </svg>
            </div>
            <div class="catalogue-controls-added-input">
                <input
                    @click.stop=""
                    @keydown="restrictQty"
                    @input="manualQtyInput"
                    :value="currentQuantity"
                    name="qty"
                    type="text"
                    :readonly="quantityStep > 1"
                    >
            </div>
            <div @click.stop.prevent="plus" class="catalogue-controls-added-button" :class="{'catalogue-controls-added-button-disabled': !can_increase}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 602.61 602.61">
                    <path d="M400,701.31a301.32,301.32,0,1,1,213-88.25A299.39,299.39,0,0,1,400,701.31Zm0-576.42c-151.64,0-275,123.39-275,275.05S248.36,675,400,675,675,551.61,675,399.94,551.64,124.89,400,124.89Zm0,466.78a25,25,0,0,1-25-25V425H233.33a25,25,0,0,1,0-50H375V233.33a25,25,0,0,1,50,0V375H566.67a25,25,0,0,1,0,50H425V566.67A25,25,0,0,1,400,591.67Z" transform="translate(-98.69 -98.69)"/>
                </svg>
            </div>
    </div>
    <div v-else class="catalogue-controls-unavailable">
        <img v-if="!inNotification" @click.stop="addNotification" src="../../assets/images/icons_new/notification.svg" alt="">
        <img v-else @click.stop="removeNotification" src="../../assets/images/icons_new/cancel_notification_red.svg" alt="">

        <div v-if="!inNotification" @click.stop="addNotification" class="catalogue-controls-unavailable-text">
            уведомить
        </div>
        <div v-else @click.stop="removeNotification" class="catalogue-controls-unavailable-text">
            отписаться
        </div>

        <div v-if="showAdded" class="catalogue-controls-unavailable-notification animate__animated animate__zoomIn">
            <img class="green-tick-circle" src="../../assets/images/icons_new/green-tick-circle.webp" alt="">
            <p>Вы будете уведомлены о поступлении данного товара на склад.</p>
        </div>
        <div v-if="showRemoved" class="catalogue-controls-unavailable-notification animate__animated animate__zoomIn">
            <img class="cancel-notification-circle" src="../../assets/images/icons_new/cancel-notification-circle.webp" alt="">
            <p>Вы больше не будете получать уведомления о поступлении товара.</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import eventHub from "@/utils/event_hub";
import axiosClient from "@/utils/axios/axios";
import {inputRestrictInt} from "@/utils/input_restrict";
export default {
    name: "c-cart-controls",
    props: {
        product: {
            type: Object,
            default() {
                return {};
            }
        },
        detail_card: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            currentQuantity: 0,
            quantityStep: this.product.cart_add_params.step,
            addAllowed: this.product.cart_add_params.allowed === 1,
            inNotification: this.product.in_notification === 1,
            showAdded: false,
            showRemoved: false,
            test: 'hjkjh',
            timer: null
        }
    },
    computed: {
        ...mapGetters([
            'CART',
            'USER_INFO',
            'API_URL'
        ]),
        inCart() {
            return this.currentQuantity > 0
        },
        can_increase() {
            return this.currentQuantity + this.quantityStep <= this.product.rest || this.USER_INFO.supplier_parameters.no_rest === 1
        }
    },
    methods: {
        addToCart() {
            let product = {
                product_info: this.product,
                quantity: this.quantityStep
            };
            this.CART.add(product);
            this.currentQuantity = this.CART.getCurrentQty(this.product.id);
            eventHub.$emit('cart-modified');
            if (this.$metrika !== undefined) {
                this.$metrika.reachGoal('cart_add')
            }
        },
        plus() {
            if (this.can_increase) {
                this.currentQuantity += this.quantityStep;
                this.CART.modify(this.product.id, this.currentQuantity);
                eventHub.$emit('cart-modified');
            }
        },
        minus() {
            this.currentQuantity -= this.quantityStep;
            if (this.currentQuantity <= 0) {
                this.CART.delete(this.product.id)
            } else {
                this.CART.modify(this.product.id, this.currentQuantity)
            }
            eventHub.$emit('cart-modified');
        },
        manualQtyInput(event) {
            if (event.target.value !== '') {
                this.currentQuantity = parseInt(event.target.value);
                if (this.currentQuantity > 0) {
                    if (this.currentQuantity > this.product.rest && this.USER_INFO.supplier_parameters.no_rest === 0) {
                        this.currentQuantity = this.product.rest
                    }
                    this.CART.modify(this.product.id, this.currentQuantity);
                    eventHub.$emit('cart-modified');
                }
            }
            //console.log(this.currentQuantity);

        },
        restrictQty: inputRestrictInt,
        checkQuantity() {
            if (this.currentQuantity !== this.CART.getCurrentQty(this.product.id)) {
                this.currentQuantity = this.CART.getCurrentQty(this.product.id)
            }
        },
        addNotification() {
            axiosClient({
                url: this.API_URL + 'notifications/balance/create/' + this.product.id + '/',
                method: 'POST'
            }).then(() => {
                this.inNotification = true;
                //this.showAdded = true;
                setTimeout(() => {this.showAdded = false;}, 3000)
            })
        },
        removeNotification() {
            axiosClient({
                url: this.API_URL + 'notifications/balance/delete/' + this.product.id + '/',
                method: 'POST'
            }).then(() => {
                this.inNotification = false;
                //this.showRemoved = true;
                setTimeout(() => {this.showRemoved = false;}, 3000)
            })
        }
    },
    mounted() {
        this.currentQuantity = this.CART.getCurrentQty(this.product.id);
        this.addAllowed = this.product.cart_add_params.allowed === 1;
        this.inNotification = this.product.in_notification === 1;
        eventHub.$on('cart-modified', this.checkQuantity)
    },
    updated() {
        this.currentQuantity = this.CART.getCurrentQty(this.product.id);
    },
    destroyed() {
        eventHub.$off('cart-modified', this.checkQuantity)
    }

}
</script>

<style scoped>

</style>
