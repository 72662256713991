import { render, staticRenderFns } from "./cAdmUsers.vue?vue&type=template&id=1c133cc8&scoped=true&"
import script from "./cAdmUsers.vue?vue&type=script&lang=js&"
export * from "./cAdmUsers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c133cc8",
  null
  
)

export default component.exports