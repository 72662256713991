<template>
    <div class="catalogue-filters-block catalogue-filters-block-range">
        <p class="catalogue-filters-block-title">
            {{ caption }}
        </p>
        <div class="catalogue-filters-block-range-slider">
            <template v-if="data.length === 0">
                <vue-slider
                        ref="slider"
                        v-model="value"
                        :min="min"
                        :max="max"
                        :interval="currentInterval"
                        :tooltip="'none'"
                        :dotSize="18"
                        :railStyle="{backgroundColor:'#d6d7d9'}"
                        :processStyle="{backgroundColor:'#2196f3'}"
                        :dotStyle="{borderColor:'#2196f3', backgroundColor:'#2196f3'}"
                        @dragging="onSliderChange"
                        @change="onSliderChange"
                        :silent="true"
                        :lazy="true"
                ></vue-slider>
            </template>
            <template v-else>
                <vue-slider
                        ref="slider"
                        v-model="value"
                        :data="data"
                        :tooltip="'none'"
                        :dotSize="18"
                        :railStyle="{backgroundColor:'#d6d7d9'}"
                        :processStyle="{backgroundColor:'#2196f3'}"
                        :dotStyle="{borderColor:'#2196f3', backgroundColor:'#2196f3'}"
                        @dragging="onSliderChange"
                        @change="onSliderChange"
                        :silent="true"
                        :lazy="true"
                ></vue-slider>
            </template>
        </div>
        <div class="catalogue-filters-block-range-inputs">
            <input @input="onTextInput" v-model.number="currentMin" type="text">
            <input @input="onTextInput" v-model.number="currentMax" type="text">
        </div>

    </div>
</template>

<script>
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import splitDigits from "@/utils/filters/splitDigits";
    export default {
        name: "c-range-filter",
        props: {
            filter_name: {
                type: String,
                default() {
                    return '';
                }
            },
            range: {
                type: Array,
                default() {
                    return [0,10000];
                }
            },
            interval: {
                type: Number,
                default() {
                    return 1;
                }
            },
            caption: {
                type: String,
                default() {
                    return '';
                }
            },
            data: {
                type: Array,
                default() {
                    return []
                }
            },
            collapsable: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                value: this.range,
                currentInterval: this.interval,
                min: this.range[0],
                max: this.range[1],
                currentMin: 0,
                currentMax: 0,
                timeout: null,
                round_to: this.filter_name === 'price' ? 0 : 2,
                collapsed: true
            }
        },
        computed: {
            filter_data: function () {
                return [
                    {
                        name: this.filter_name + '_from',
                        value: this.currentMin
                    },
                    {
                        name: this.filter_name + '_to',
                        value: this.currentMax
                    },
                ];
            }
        },
        mounted() {
            this.currentMin = this.min;
            this.currentMax = this.max;
        },
        methods: {
            onSliderDrag: function(data) {
                this.currentMin = data[0];
                this.currentMax = data[1];
            },
            onSliderChange: function(data) {
                this.onSliderDrag(data);
                clearTimeout(this.timeout);
                this.timeout = setTimeout(function(){
                    this.$emit('apply-filter', this.filter_data)
                }.bind(this), 500)
            },
            onTextInput: function () {
                if (this.data.length > 0) {
                    this.value = [
                        this.data.reduce((prev, cur) => {return this.currentMin - prev < cur - this.currentMin ? prev : cur}, this.data[0]),
                        this.data.reduce((prev, cur) => {return this.currentMax - prev < cur - this.currentMax ? prev : cur}, this.data[0])
                    ]
                } else {
                    this.value = [
                        this.currentMin,
                        this.currentMax
                    ];
                }
                //this.$refs.slider.setValue(this.value);
                clearTimeout(this.timeout);
                this.timeout = setTimeout(function(){
                    this.$emit('apply-filter', this.filter_data)
                }.bind(this), 500)
            }
        },
        filters: {
            splitDigits
        },
        components: {
            VueSlider
        }
    }
</script>

<style scoped>
    .input-range {
        width: 75px;
        height: 32px;
        border: 1px solid #eeeeee;
        border-radius: 3px;
    }
    .vue-slider-process {
        background-color: #384aeb!important;
    }
</style>