<template>
    <div class="page reports">
        <c-loading-overlay-page v-if="loading">
        </c-loading-overlay-page>
        <h1 class="page-title">Отчеты</h1>
        <div class="tabs reports-tabs">
            <ul class="">
                <li>
                    <input type="radio" id="_act_recon" value="act_recon" v-model="current_report">
                    <label class="reports-tabs-item" for="_act_recon">
                        <img src="../../assets/images/reconciliation-act-document.png" alt="">
                        <p class="text-center">Акт сверки</p>
                    </label>
                </li>
                <li>
                    <input type="radio" id="_order_payments" value="order_payments" v-model="current_report">
                    <label class="reports-tabs-item" for="_order_payments">
                        <img src="../../assets/images/reconciliation-act-document.png" alt="">
                        <p class="text-center">Оплата по заказам</p>
                    </label>
                </li>
                <li>
                    <input type="radio" id="_declaration" value="declaration" v-model="current_report">
                    <label class="reports-tabs-item" for="_declaration">
                        <img src="../../assets/images/reconciliation-act-document.png" alt="">
                        <p class="text-center">Декларация</p>
                    </label>
                </li>
            </ul>
        </div>
        <div class="reports-content">
            <section v-if="current_report === 'act_recon'">
                <h2 class="reports-content-title">
                    Выберите дату начала и дату окончания:
                </h2>
                <div class="reports-content-dates">
                        <datepicker
                            :calendar-class="'datepicker-calendar-fix'"
                            :input-class="'input input-date'"
                            :format="'dd.MM.yyyy'"
                            :language="ru"
                            :monday-first="true"
                            v-model="date_from_act"
                            v-on:selected="date_invalid = false"
                        > </datepicker>
                        <datepicker
                            :calendar-class="'datepicker-calendar-fix'"
                            :input-class="'input input-date'"
                            :format="'dd.MM.yyyy'"
                            :language="ru"
                            :monday-first="true"
                            v-model="date_to_act"
                            v-on:selected="date_invalid = false"
                        > </datepicker>
                </div>
                <div class="reports-content-button">
                    <button @click="actRecon" class="button" type="button" role="button" :disabled="date_from_act === null || date_to_act === null">Сформировать</button>
                </div>
            </section>
            <section v-if="current_report === 'order_payments'">
                <h2 class="reports-content-title">
                    Выберите тип отчёта:
                </h2>
                <div class="reports-content-type">
                    <div class="reports-content-type-container">
                        <label>
                            <input v-model="rep_type" type="radio" id="all-payments" name="payments-report" value="cb_all_payments">
                            все платежи
                        </label>
                        <label>
                            <input v-model="rep_type" type="radio" id="order-payments" name="payments-report" value="cb_order_payment_items">
                            платежи по заказам
                        </label>
                        <label>
                            <input v-model="rep_type" type="radio" id="something" name="payments-report" value="cb_order_payments">
                            оплата по заказам
                        </label>
                    </div>
                </div>
                <h2 class="reports-content-title">
                    Выберите дату начала и дату окончания:
                </h2>
                <div class="reports-content-dates">
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :format="'dd.MM.yyyy'"
                        :language="ru"
                        :monday-first="true"
                        v-model="date_from_rep"
                        v-on:selected="date_invalid = false"
                    > </datepicker>
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :format="'dd.MM.yyyy'"
                        :language="ru"
                        :monday-first="true"
                        v-model="date_to_rep"
                        v-on:selected="date_invalid = false"
                    > </datepicker>
                </div>
                <div class="reports-content-button">
                    <button @click="report" class="button" type="button" role="button" :disabled="date_from_rep === null || date_to_rep === null || rep_type === null">Сформировать</button>
                </div>
            </section>
            <section v-if="current_report === 'declaration'">
                <h2 class="reports-content-title">
                    Выберите дату начала и дату окончания:
                </h2>
                <div class="reports-content-dates">
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :format="'dd.MM.yyyy'"
                        :language="ru"
                        :monday-first="true"
                        :disabled-dates="decl_disabled_dates"
                        v-model="date_from_decl"
                        v-on:selected="date_invalid = false"
                    > </datepicker>
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :format="'dd.MM.yyyy'"
                        :language="ru"
                        :monday-first="true"
                        :disabled-dates="decl_disabled_dates"
                        v-model="date_to_decl"
                        v-on:selected="date_invalid = false"
                    > </datepicker>
                </div>
                <div class="reports-content-button">
                    <button @click="declaration" class="button" type="button" role="button" :disabled="date_from_decl === null || date_to_decl === null">Сформировать</button>
                </div>
                <p class="text-center mt-2">
                    Файлы предоставляются исключительно для сверки. АЛКОГОЛЬНАЯ ДЕКЛАРАЦИЯ ФОРМИРУЕТСЯ НА ОСНОВАНИИ ПЕРВИЧНЫХ ДОКУМЕНТОВ. Данные для построения деклараций за последний перед текущим месяц загружаются на портал после процедуры закрытия месяца нашей компанией.
                </p>
            </section>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import {ru} from 'vuejs-datepicker/dist/locale';
    import axiosClient from "@/utils/axios/axios";
    import {mapGetters} from 'vuex';
    import moment from 'moment';
    import cLoadingOverlayPage from "@/components/common/loading/cLoadingOverlayPage";
    import cAdmResultModal from "@/components/adm/cAdmResultModal";

    export default {
        name: "c-reports",
        data() {
            return {
                reports: [],
                date_from_act: null,
                date_to_act: null,
                date_from_rep: null,
                date_to_rep: null,
                date_from_decl: null,
                date_to_decl: null,
                decl_disabled_dates: {},
                current_report: 'act_recon',
                rep_type: null,
                ru: ru,
                loading: false
            }
        },
        computed: {
            ...mapGetters(['API_URL'])
        },
        methods: {
            fileReport(date_from, date_to, report_type) {
                if (date_from !== null && date_to !== null) {
                    this.loading = true;
                    axiosClient({
                        method: 'GET',
                        url: this.API_URL + 'report/'+ report_type + '/',
                        params: {
                            date_from: moment(date_from).format('DD.MM.YYYY'),
                            date_to: moment(date_to).format('DD.MM.YYYY')
                        }
                    }).then((response) => {
                        if (response.data.errors.length > 0) {
                            this.showResultModal(response.data)
                        } else {
                            if (response.data.file !== undefined && response.data.file !== '') {
                                window.location.href = response.data.file
                            }
                        }
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    })
                }
            },
            actRecon() {
                this.fileReport(this.date_from_act, this.date_to_act, 'act_recon');
            },
            declaration() {
                this.fileReport(this.date_from_decl, this.date_to_decl, 'declaration');
            },
            report() {
                if (this.date_from_rep !== null && this.date_to_rep !== null && this.rep_type !== null) {
                    window.open('/report/' + this.rep_type + '?from=' + moment(this.date_from_rep).format('DD.MM.YYYY') + '&to=' + moment(this.date_to_rep).format('DD.MM.YYYY'), '_blank');
                }
            },
            showResultModal(result){
                this.$modal.show(
                    cAdmResultModal,
                    {
                        result: result
                    },
                    {
                        name: 'modal_adm_result',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-thank-you-error-message']
                    }
                )
            }
        },
        mounted() {
            axiosClient({
                method: 'GET',
                url: this.API_URL + 'reports/',
            }).then((response) => {
                this.reports = response.data;
                let declaration = this.reports.filter((el) => el.report_type === 'declaration')[0];
                if (declaration !== undefined) {
                    this.date_from_decl = moment(declaration.max_date, 'DD.MM.YYYY').subtract(3, 'months').toDate();
                    this.date_to_decl = moment(declaration.max_date, 'DD.MM.YYYY').toDate();
                    this.decl_disabled_dates.to = moment(declaration.min_date, 'DD.MM.YYYY').toDate();
                    this.decl_disabled_dates.from = this.date_to_decl;
                }
            })
        },
        components: {
            Datepicker,
            cLoadingOverlayPage
        }
    }
</script>

<style scoped>

</style>
