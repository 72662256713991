<template>
            <div class="modal-internal-container">
                <h1>Внимание!</h1>
                <p class="text-center">
                    <template v-if="days_diff > 0">
                        Лицензия по компании {{USER_INFO.active_company_info.legal_name}} истекает через {{days_diff}} дней ({{expire_date.format('DD.MM.YYYY')}})!
                    </template>
                    <template v-else-if="days_diff === 0">
                        Лицензия по компании {{USER_INFO.active_company_info.legal_name}} истекает сегодня!
                    </template>
                    <template v-else>
                        Лицензия по компании {{USER_INFO.active_company_info.legal_name}} истекла {{expire_date.format('DD.MM.YYYY')}}!
                    </template>
                </p>
                <div class="modal-confirmation-buttons">
                    <button @click="close" class="button" aria-label="Exit">закрыть</button>
                </div>
            </div>
</template>

<script>
    import UserInfo from "@/store/models/UserInfo";
    import {mapGetters} from 'vuex';
    import moment from 'moment'
    export default {
        name: "c-licence-notification-modal",
        props: {
            expire_date: {
                type: moment,
                default() {
                    return undefined;
                }
            },
            days_diff: {
                type: Number,
                default() {
                    return 0;
                }
            }
        },
        data() {
            return {
                expired: false
            }
        },
        computed: {
            ...mapGetters(['USER_INFO'])
        },
        methods: {
            close(){
                this.$modal.hide('modal_licence_notification')
            },
        }
    }
</script>

<style scoped>

</style>
