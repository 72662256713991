<template>
    <div class="catalogue-main-subheader-search" :class="{'catalogue-main-subheader-search-overlay': focused || suggest_container_visible || search_string !== ''}">
        <svg @click="inputFocus" class="catalogue-main-subheader-search-icon-mobile" :class="{'d-none': focused || suggest_container_visible}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
        <input
            @keypress="pressEnterOnSearch"
            @input="searchInputHandler"
            @focus="inputFocus"
            @blur="inputBlur"
            type="text"
            class="input catalogue-main-subheader-search-input"
            :class="{'catalogue-main-subheader-search-input-active': suggest_container_visible}"
            placeholder="Поиск"
            autocomplete="off"
            autocapitalize="off"
            autocorrect="off"
            spellcheck="false"
            ref="_search_input"
        >
        <svg class="input-icon catalogue-main-subheader-search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
        <svg @click="cancelSearch" v-if="focused || suggest_container_visible || search_string !== ''" class="input-icon catalogue-main-subheader-search-close" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"/></svg>
        <div v-if="suggest_container_visible" class="search-suggest">
            <div v-if="search_suggest_items.length > 0">
                <div class="search-suggest-items">
                    <div
                        class="search-suggest-item"
                        v-for="(item, index) in search_suggest_items"
                        :key="index"
                        @click="suggestSelect(item.text)"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style="transform:scale(1)"><path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
                        <span>{{item.text}}</span>
                    </div>
                </div>
            </div>
            <div v-if="fast_search_items.length === 0" class="search-suggest-loading">
                <template v-if="loading">
                    <div class="search-suggest-loading-icon">
                        <div v-if="true" class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                    <p>Быстрый поиск</p>
                </template>
                <template v-else>
                    <div class="search-suggest-loading-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform:scale(1)"><path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
                    </div>
                    <p>Ничего не найдено</p>
                </template>
            </div>
            <div v-else class="search-suggest-fast-search">
                <div
                    v-for="(item, index) in fast_search_items"
                    :key="index"
                    class="search-suggest-fast-search-item"
                    @click="suggest_container_visible = false; openDetailProduct(item.id)"
                >
                    <img class="search-suggest-fast-search-item-icon" v-lazy="item.picture_url" alt="Bottle">
                    <div class="search-suggest-fast-search-item-content">
                        <p class="search-suggest-fast-search-item-content-title">{{item.name}}</p>
                        <p class="search-suggest-fast-search-item-content-code"> {{item.volume}} л, артикул {{item.code}}</p>
                    </div>
                </div>
                <p class="search-suggest-fast-search-show-all"><span @click="search">показать все результаты</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from "@/utils/axios/axios";
import {mapGetters, mapMutations} from "vuex";
import openDetailMixin from "@/utils/mixins/open_detail";

export default {
    name: "c-search",
    mixins: [openDetailMixin],
    data() {
        return {
            search_string: '',
            last_search_string: '',
            search_suggest_items: [],
            fast_search_items: [],
            suggest_container_visible: false,
            loading: false,
            focused: false
        }
    },
    computed: {
        ...mapGetters([
            'API_URL'
        ])
    },
    methods: {
        ...mapMutations(['SET_CURRENT_FILTER']),

        /**
         * Не используется v-model, поскольку не отрабатывает в случае включенного автодополнения
         * на мобильных телефонах. Приходится вручную обрабатывать событие input.
         * @param e
         */
        searchInputHandler(e) {
          this.search_string = e.target.value;
          this.suggest();
        },
        search: function () {
            this.SET_CURRENT_FILTER({});
            console.log(this.search_string);
            if (this.search_string !== '') {
                this.$router.push({path: '/products', query: {search: this.search_string}})
            } else {
                if (this.$route.query.search !== undefined) {
                    this.$router.push({path: '/products'})
                }
            }
            // this.suggest_container_visible = false;
            // if (this.search_string !== this.last_search_string) {
            //     this.$emit('apply-filter', {
            //         name: 'search',
            //         value: this.search_string
            //     })
            // }
            // this.last_search_string = this.search_string;
        },
        suggest: function () {
            if (this.search_string !== '' && this.search_string.length >= 3) {
                this.suggest_container_visible = true;
                clearTimeout(this.timer);
                this.loading = true;
                this.timer = setTimeout(() => {
                    axiosClient({
                        url: this.API_URL + 'products/search/suggest/?q=' + this.search_string,
                        method: 'GET'
                    }).then((response) => {
                        this.search_suggest_items = response.data.suggest;
                        this.fast_search_items = response.data.fast_search;
                        this.loading = false;
                    })
                }, 200)
            } else {
                this.suggest_container_visible = false;
                //this.search()
            }
        },
        suggestSelect: function(text) {
            this.search_string = text;
            this.$refs._search_input.value = this.search_string;
            this.search_suggest_items = [];
            this.suggest()
        },
        pressEnterOnSearch(event) {
            if (event.keyCode === 13) {
                this.search();
            }
        },
        focusInput() {
            this.focused = true;
            this.$nextTick(() => this.$refs._search_input.focus())
        },
        cancelSearch() {
            this.suggest_container_visible = false;
            if (this.search_string !== '') {
                this.search_string = '';
                this.$refs._search_input.value = this.search_string;
                this.search()
            }
        },
        inputBlur() {
            setTimeout(() => {this.focused = false}, 200);
        },
        inputFocus(e) {
            this.focused = true;
            this.$nextTick(() => this.$refs._search_input.focus())

        },
    },
    mounted() {
        if (this.$route.query.search !== undefined) {
            this.search_string = this.$route.query.search
            this.$refs._search_input.value = this.search_string;
        }
    }
}
</script>

<style scoped>

</style>
