import Vue from 'vue';
import App from './App.vue';
import store from "@/store/store";
import router from "@/router/router";
import VueLazyload from "vue-lazyload";
import VModal from "vue-js-modal";
import VueYandexMetrika from 'vue-yandex-metrika'

let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

const placeholderImage = require('./assets/images/placeholder.png');
Vue.use(VueLazyload, {
  error: placeholderImage,
  loading: placeholderImage,
  attempt: 1
});

Vue.use(VModal);

Vue.use(VueYandexMetrika, {
  id: 92863614,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
  }
})

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app');

