<template>
    <div class="footer">
        <div class="footer-content">
            <p>© 2023</p>
            <p><a href="/feedback" @click.prevent="$router.push({name: 'feedback'})">Обратная связь</a></p>
        </div>
        <a class="arrow-up" href="#">
            <div class="arrow-up-inner">
                <img src="../assets/images/white_arrow_up.png" alt="">
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "c-footer",
    }
</script>

<style lang="scss">

</style>
