<template>
    <c-loading v-if="loading"></c-loading>
    <div v-else class="page">
        <h1 class="page-title">{{report_name}}</h1>
        <h2 class="page-subtitle nowrap">{{$route.query.from}} — {{$route.query.to}}</h2>
        <div class="report-table">
            <table>
                <tr>
                    <td v-for="key in Object.keys(report_fields)" :key="key" class="report-table-item report-table-header">{{report_fields[key]}}</td>
                </tr>
                <tr v-for="(item, index) in report_data" :key="index">
                    <td v-for="key in Object.keys(report_fields)" :key="key" class="report-table-item"><template v-if="money_fields.includes(key)">{{item[key] | splitDigits}}</template><template v-else>{{item[key]}}</template></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import cLoading from "@/components/common/loading/cLoading";
    import axiosClient from "@/utils/axios/axios";
    import splitDigits from "@/utils/filters/splitDigits";
    import {mapGetters} from 'vuex';
    export default {
        name: "c-report",
        data() {
            return {
                loading: false,
                report_types: ['cb_order_payments', 'cb_all_payments', 'cb_order_payment_items'],
                money_fields: ['dolg', 'sum_opl_zak', 'sum_zak', 'sum_opl', 'sum_plat'],
                report_fields: {},
                report_data: [],
                report_name: ''
            }
        },
        computed: {
            ...mapGetters(['API_URL'])
        },
        mounted() {

            let report_type = this.$route.params.slug;
            let from = this.$route.query.from;
            let to = this.$route.query.to;
            if (report_type !== undefined && this.report_types.includes(report_type) && from !== undefined && to !== undefined) {
                this.loading = true;
                axiosClient({
                    method: 'GET',
                    url: this.API_URL + 'report/' + report_type + '/',
                    params: {
                        date_from: from,
                        date_to: to
                    }
                }).then((response) => {
                    if (response.data !== null) {
                        this.report_fields = response.data.report_fields;
                        this.report_data = response.data.report_data;
                        this.report_name = response.data.report_name;
                    }
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }
        },
        filters: {
          splitDigits
        },
        components: {
            cLoading
        }
    }
</script>

<style scoped>

</style>
