<template>
    <div class="modal-internal-container">
        <h1>Вы уверены?</h1>
        <div class="modal-confirmation-buttons">
            <button @click="close" class="button button-red" aria-label="Exit">отмена</button>
            <button @click="confirmDelete" class="button" type="submit">да</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "c-adm-confirm-modal",
    props: {
        deleteFromModal: {
            type: Function,
            default() {
                return () => {}
            }
        }
    },
    methods: {
        close(){
            this.$modal.hide('modal_confirm_delete')
        },
        confirmDelete() {
            this.deleteFromModal();
            this.close()
        }
    }
}
</script>

<style scoped>

</style>
