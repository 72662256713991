<template>
    <div class="page admin">
        <div class="admin-header animate__animated animate__faster" :class="{'d-block animate__slideInLeft': MOBILE_MENU_OPEN}">
            <div
                v-for="page in page_list"
                :key="page.name"
                @click="setPage(page.name)"
                class="admin-header-item"
                :class="{'admin-header-item-current': current_page === page.name}"
            >
                <div class="admin-header-item-image">
                    <img :src="page.icon" alt="">
                </div>
                <p>{{page.title}}</p>
            </div>
        </div>
        <div class="admin-content">
            <c-adm-no-rest
                v-if="current_page==='no_rest'"
            >
            </c-adm-no-rest>
            <c-adm-restrict-days
                v-if="current_page==='restrict_days'"
            >
            </c-adm-restrict-days>
            <c-adm-expiration-warn
                v-if="current_page==='expiration_warn'"
            >
            </c-adm-expiration-warn>
            <c-adm-next-day-time
                v-if="current_page==='next_day_time'"
            >
            </c-adm-next-day-time>
            <c-adm-notification-emails
                v-if="current_page==='notification_emails'"
            >
            </c-adm-notification-emails>
            <c-adm-current-day-shipment
                v-if="current_page==='current_day_shipment'"
            >
            </c-adm-current-day-shipment>
            <c-adm-users
                v-if="current_page==='users'"
            >
            </c-adm-users>
            <c-adm-delivery-time
                v-if="current_page==='delivery_time'"
            >
            </c-adm-delivery-time>
        </div>
    </div>
</template>

<script>
import cAdmNoRest from "@/components/adm/pages/cAdmNoRest";
import cAdmRestrictDays from "@/components/adm/pages/cAdmRestrictDays";
import cAdmExpirationWarn from "@/components/adm/pages/cAdmExpirationWarn";
import cAdmNextDayTime from "@/components/adm/pages/cAdmNextDayTime";
import cAdmNotificationEmails from "@/components/adm/pages/cAdmNotificationEmails";
import cAdmCurrentDayShipment from "@/components/adm/pages/cAdmCurrentDayShipment";
import cAdmUsers from "@/components/adm/pages/users/cAdmUsers";
import axiosClient from "@/utils/axios/axios";
import eventHub from "@/utils/event_hub";
import {mapGetters, mapActions, mapMutations} from 'vuex';
import CAdmDeliveryTime from "@/components/adm/pages/cAdmDeliveryTime";

export default {
    name: "c-adm-main",
    props: {
        adm_page: {
            type: String,
            default() {
                return 'users'
            }
        }
    },
    data(){
        return {
            page_list: [
                {
                    name: 'users',
                    title: 'пользователи',
                    icon: require('@/assets/images/adm-header_01_create_user_blue.png')
                },
                {
                    name: 'notification_emails',
                    title: 'почтовые ящики',
                    icon: require('@/assets/images/adm-header_02_e-mail-boxes_blue.png')
                },
                {
                    name: 'no_rest',
                    title: 'правила работы с заказами',
                    icon: require('@/assets/images/adm-header_03_add_an_item_rule_blue.png')
                },
                {
                    name: 'delivery_time',
                    title: 'время выполнения заказа',
                    icon: require('@/assets/images/adm-header_04_shipping_time.png')
                },
                {
                    name: 'expiration_warn',
                    title: 'истечение срока лицензии и договора',
                    icon: require('@/assets/images/adm-header_05_license_and_contract_expired_blue.png')
                },
                {
                    name: 'next_day_time',
                    title: 'запрет отгрузки на следующий день',
                    icon: require('@/assets/images/adm-header_08_cutoff_time_for_the_next_day_blue.png')
                },
                {
                    name: 'restrict_days',
                    title: 'запрет отгрузки по дням',
                    icon: require('@/assets/images/adm-header_07_prohibited_days_for_shipment_blue.png')
                },
                {
                    name: 'current_day_shipment',
                    title: 'заказ и отгрузка день в день',
                    icon: require('@/assets/images/adm-header_09_same-day-shipment_blue.png')
                },
            ],
            current_page: ''
        }
    },
    computed: {
        ...mapGetters(
            [
                'API_URL',
                'USER_INFO',
                'MOBILE_MENU_OPEN'
            ])
    },
    methods: {
        ...mapActions(['GET_USER_INFO']),
        ...mapMutations(['TOGGLE_MOBILE_MENU_OPEN']),
        setPage(page) {
            this.$router.push({path: '/adm', query: {page: page}})
            this.current_page = page;
            if (this.MOBILE_MENU_OPEN) {
                this.TOGGLE_MOBILE_MENU_OPEN()
            }
        }
    },
    created() {
        if (this.$route.query.page === undefined) {
            this.current_page = this.adm_page
        } else {
            this.current_page = this.$route.query.page
        }
    },
    components: {
        CAdmDeliveryTime,
        cAdmNoRest,
        cAdmRestrictDays,
        cAdmExpirationWarn,
        cAdmNextDayTime,
        cAdmNotificationEmails,
        cAdmCurrentDayShipment,
        cAdmUsers
    }
}
</script>

<style scoped>

</style>
