let Product = {
    id: 0,
    code: '',
    name: '',
    picture_url: '',
    price: 0,
    base_price: 0,
    country: {
        id: 0,
        name: ''
    },
    category: {
        id: 0,
        name: ''
    },
    pack: 0,
    volume: 0,
    strength: 0,
    year: 0,
    rest: 0,
    rest_box: 0,
    rest_bottles: 0,
    is_personal: 0,
    ignore_strength: 0,
    comment: '',
    fill_field1: '',
    fill_field2: '',
    is_active: 1,
    is_favourite: 0,
    search_content: '',
    search_priority: 0,
    in_notification: 0,
    cart_add_params: {
        step: 1,
        allowed: 1
    }
};

export default Product