import axiosClient from "@/utils/axios/axios";
import eventHub from "@/utils/event_hub";

export default {
    GET_USER_INFO({commit, state}) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'user/get_info/',
        })
            .then(function(response) {
                commit('SET_USER_DATA', response.data);
            })
    },
    CHANGE_USER_DEFAULT_FIRM({commit, state}, company) {
        return axiosClient({
            method: 'POST',
            url: state.api + 'user/set_default_company/',
            data: {
              active_company_id: company.id
            },

        })
            .then(function(response) {
                commit('SET_USER_DEFAULT_FIRM', company);
            })
    },
    GET_ORDER_LIST({commit, state}, filter) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'orders/get_list/',
            params: filter,
        })
            .then(function(response) {
                commit('SET_ORDER_LIST', response.data);
            })
    },
    GET_ORDER_DETAIL({commit, state}, order_id) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'orders/get_detail/' + order_id + '/',
        })
            .then(function(response) {
                commit('SET_ORDER_DETAIL', response.data);
            })
    },
    GET_PRODUCT_LIST({commit, state}, filter) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'products/get_list/',
            params: filter,
        })
            .then(function(response) {
                let products = response.data;
                products.products.forEach((item) => {
                   item.qty = 1
                });
                commit('SET_PRODUCT_LIST', response.data);
            })
    },
    GET_FAVORITE_LIST({commit, state}, filter) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'fav/get_list/',
            params: filter,
        })
            .then(function(response) {
                commit('SET_FAVORITE_LIST', response.data);
            })
    },
    GET_FINANCE({commit, state}, daterange) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'user_get_finance/',
            params: {
                daterange: daterange
            },
        })
            .then(function(response) {
                commit('SET_FINANCE', response.data);
            })
    },
    GET_ORDER_DRAFTS({commit, state}) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'order_drafts/get_list/',
        })
            .then(function(response) {
                commit('SET_ORDER_DRAFTS', response.data);
            })
    },
    GET_ORDER_DRAFT_DETAIL({commit, state}, draft_id) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'order_drafts/get_detail/' + draft_id + '/',
        })
            .then(function(response) {
                commit('SET_ORDER_DETAIL', response.data);
            })
    },
    DELETE_ORDER_DRAFT({commit, state}, draft_id) {
        let formData = new FormData();
        formData.append('draft_id', draft_id);
        return axiosClient({
            method: 'POST',
            url: state.api + 'order_drafts/delete/',
            data: formData,
            headers: {
                'Content-type': 'multipart/form-data'
            }
        })
    },
    ADD_ORDER_DRAFT({commit, state}, draft) {
        return axiosClient({
            method: 'POST',
            url: state.api + 'order_drafts/create/',
            data: draft,
        })
    },

    GET_CART({commit, state}) {
        return axiosClient({
            method: 'GET',
            url: state.api + 'cart/'
        })
            .then(function (response) {
                commit('SET_CART', response.data)
            })
    }
}