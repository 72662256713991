import axios from "axios";
import axiosClient from "@/utils/axios/axios";
import store from "@/store/store";
import {loadFromStorage} from "@/store/local_storage/local_storage";

let result = function() {
    let api_key = loadFromStorage('key', String);
    return axiosClient({
        method: 'GET',
        url: store.state.api + 'check/',
    })
};

export default result