<template>
    <div class="h-100 d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column align-items-center">
            <img class="mb-2" style="width:150px" src="../assets/images/red-triangle-exclamation-mark.png">
            <p>Произошла ошибка при получении данных! Вы можете попробовать <a href="#" @click.prevent="logout">перезайти</a> на портал.</p>
        </div>
    </div>
</template>

<script>
    import axiosClient from "@/utils/axios/axios";
    import {mapGetters, mapMutations} from 'vuex';
    export default {
        name: "c-error",
        computed: {
          ...mapGetters(['API_URL'])
        },
        methods: {
            ...mapMutations(['SET_API_KEY', 'SET_LOGGED', 'SET_ERROR']),
            logout() {
                axiosClient({
                    method: 'GET',
                    url: this.API_URL + 'logout/',
                }).then(() => {
                    localStorage.removeItem('key');
                    this.SET_API_KEY('');
                    this.SET_LOGGED(false);
                    this.SET_ERROR(false);
                    this.$router.push({name: 'login'})
                });
            },
        }
    }
</script>

<style scoped>

</style>