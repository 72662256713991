<template>
    <div class="modal-internal-container">
        <h1 v-if="result.errors.length === 0">Изменения успешно сохранены!</h1>
        <h1 v-else class="modal-confirmation-text">Ошибка!</h1>
        <p class="text-center" v-for="(error, index) in result.errors" :key="index">{{error}}</p>
        <div class="modal-confirmation-buttons">
            <button @click="close" class="button">закрыть</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "c-adm-result-modal",
    props: {
        result: {
            type: Object,
            default() {
                return {errors: []}
            }
        }
    },
    methods: {
        close(){
            this.$modal.hide('modal_adm_result')
        }
    }
}
</script>

<style scoped>

</style>
