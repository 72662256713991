<template>
    <div class="admin-users">
        <h1 class="admin-content-title">Управление пользователями</h1>
        <div class="tabs">
            <ul>
                <li>
                    <input id="create_user" type="radio" value="create_user" v-model="active_tab" checked>
                    <label for="create_user">
                        Зарегистрировать
                    </label>
                    <div class="tabs-slider">
                        <div class="tabs-slider-indicator"></div>
                    </div>
                </li>
                <li>
                    <input id="edit_user" type="radio" value="edit_user" v-model="active_tab">
                    <label for="edit_user">
                        Изменить
                    </label>
                    <div class="tabs-slider">
                        <div class="tabs-slider-indicator"></div>
                    </div>
                </li>
                <li>
                    <input id="user_list" type="radio" value="user_list" v-model="active_tab">
                    <label for="user_list">
                        Список
                    </label>
                    <div class="tabs-slider">
                        <div class="tabs-slider-indicator"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="admin-users-content">
            <section v-if="active_tab === 'create_user'">
                <c-adm-user-edit-form
                    :action="'create'"
                    @show-result="showResultModal"
                >
                </c-adm-user-edit-form>
            </section>

            <section v-if="active_tab === 'edit_user'">
                <c-adm-user-edit></c-adm-user-edit>
            </section>

            <section v-if="active_tab === 'user_list'">
                <c-adm-user-companies></c-adm-user-companies>
            </section>
        </div>
    </div>
</template>

<script>
import cAdmUserEditForm from "@/components/adm/pages/users/cAdmUserEditForm";
import cAdmUserCompanies from "@/components/adm/pages/users/cAdmUserCompanies";
import cAdmUserEdit from "@/components/adm/pages/users/cAdmUserEdit";
import admSetValueMixin from "@/utils/mixins/adm_set_value";
export default {
    name: "c-adm-users",
    mixins: [admSetValueMixin],
    data() {
        return {
            active_tab: 'create_user'
        }
    },
    methods: {
      init(){}
    },
    components: {
        cAdmUserEditForm,
        cAdmUserEdit,
        cAdmUserCompanies
    }
}
</script>

<style scoped>

</style>
