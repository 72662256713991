<template>
    <div ref="_subheader_wrapper" class="catalogue-main-subheader-wrapper">
        <div ref="_subheader" class="catalogue-main-subheader">
            <div class="d-none">
                <p class="display-word">отображать:</p>
                <div class="subheader-display-container">
                    <label class="subheader-switch">
                        <input @change="togglePersonal" :name="'is_personal'" type="checkbox" checked>
                        <span class="subheader-slider round"></span>
                    </label>
                    <div>
                        <div>все товары</div>
                        <div class="display-special-offers-only">спец. предложения</div>
                    </div>
                </div>
            </div>
            <c-search
                @apply-filter="onApplyProductFilter"
            >
            </c-search>
            <div @click="toggleFilters" class="catalogue-main-subheader-filters">
<!--                <div v-if="filter_count > 0" class="catalogue-filters-block-title-count catalogue-main-subheader-filters-count">{{filter_count}}</div>-->
                <img src="../../../assets/images/icons_new/filters.svg" alt="">
            </div>
            <c-sort
                @apply-filter="onApplyProductFilter"
            >
            </c-sort>
            <div class="catalogue-main-subheader-fill">
            </div>
            <div class="catalogue-main-subheader-view">
                <div class="catalogue-main-subheader-view-container">
                    <img src="../../../assets/images/icons_new/view_line.svg">
                    <img src="../../../assets/images/icons_new/view_tile.svg">
                    <input id="toggle_view_input" v-model="catalog_view" @change="toggleCatalogView" type="checkbox">
                    <label for="toggle_view_input">
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import eventHub from "@/utils/event_hub";
    import cSearch from "@/components/products/subheader/cSearch";
    import cSort from "@/components/products/subheader/cSort";
    export default {
        name: "c-search-bar",
        props: {
            catalog_view_row: {
                type: Boolean,
                default(){
                    return false
                }
            }
        },
        computed: {
            ...mapGetters([
                'PRODUCT_LIST',
                'CURRENT_FILTER'
            ]),
            filter_count() {
                let count = 0;
                if (this.CURRENT_FILTER.categories !== undefined) {
                    count += this.CURRENT_FILTER.categories.split(';').length;
                }
                if (this.CURRENT_FILTER.countries !== undefined) {
                    count += this.CURRENT_FILTER.countries.split(';').length;
                }
                if (this.CURRENT_FILTER.volume_from !== undefined) {
                    count += 1;
                }
                if (this.CURRENT_FILTER.price_from !== undefined) {
                    count += 1;
                }
                if (this.CURRENT_FILTER.strength_from !== undefined) {
                    count += 1;
                }
                return count
            }

        },
        methods: {
            changeOnPage: function (value) {
                this.on_page_dropdown.current = value;
                this.$emit('apply-filter', {
                    name: 'on_page',
                    value: this.on_page_dropdown.current
                })
            },
            onApplyPageFilter(filter) {
                this.$emit('apply-filter', filter)
            },
            togglePersonal: function(event) {
                this.$emit('apply-filter', {
                    name: event.target.name,
                    value: event.target.checked === false ? '1' : ''
                });
            },
            toggleCatalogView(event) {
                if (event.target.checked) {
                    localStorage['catalog_view'] = 'row'
                } else {
                    localStorage['catalog_view'] = 'col'
                }
                this.$emit('toggle-view', event.target.checked)
            },
            toggleFilters() {
              eventHub.$emit('toggle-filters')
            },
            orderBy() {
                this.$emit('apply-filter', {
                    name: 'order_by',
                    value: this.order_by
                });
            },
            onApplyProductFilter(filter) {
                this.$emit('apply-filter', filter)
            },
            fixPositionOnScroll() {
                let header = document.querySelector('.header');
                let header_bottom = header.getBoundingClientRect().bottom;
                let subheader_bottom = this.$refs._subheader_wrapper.getBoundingClientRect().bottom
                if (subheader_bottom < header_bottom) {
                    this.$refs._subheader.classList.add('catalogue-main-subheader-fixed')
                    header.classList.add('header-scrolled');
                } else {
                    this.$refs._subheader.classList.remove('catalogue-main-subheader-fixed')
                    header.classList.remove('header-scrolled');
                }
            }
        },
        data: function(){
            return {
                on_page_dropdown: {
                    values: [36, 60, 104],
                    current: 36,
                },
                search_string: '',
                timer: null,
                catalog_view: this.catalog_view_row,
                order_by: ''
            }
        },
        mounted() {
            this.fixPositionOnScroll();
            window.addEventListener('scroll', this.fixPositionOnScroll)
        },
        destroyed() {
            window.removeEventListener('scroll', this.fixPositionOnScroll)
            let header = document.querySelector('.header');
            header.classList.remove('header-scrolled');
        },
        components: {
            cSearch,
            cSort
        }
    };
</script>

<style lang="scss">
</style>
