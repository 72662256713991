<template>
    <div class="adm-create-user-block">
        <p>Для того, чтобы найти пользователя, которого Вы хотите отредактировать, введите его номер телефона в соответствующее поле и нажмите кнопку "Искать".</p>
        <div class="admin-users-edit-form-item mt-3">
            <img src="@/assets/images/Phone_Number.png" alt="">
            <imask-input
                :mask="'+{7} (000) 000-00-00'"
                :lazy="true"
                :unmask="true"
                @accept="setSearchPhone"
                type="tel"
                class="input-line"
                placeholder="+7 ("
            ></imask-input>
            <button class="button" @click="searchUser">искать</button>
        </div>
        <c-adm-user-edit-form
            v-if="found_user!==null"
            :action="'update'"
            :user_data="found_user"
            :key="edit_user_key"
            @show-result="showResultModal"
            @user-deleted="found_user = null"
        >
        </c-adm-user-edit-form>
    </div>
</template>

<script>
import {IMaskComponent} from 'vue-imask';
import cAdmUserEditForm from "@/components/adm/pages/users/cAdmUserEditForm";
import axiosClient from "@/utils/axios/axios";
import admSetValueMixin from "@/utils/mixins/adm_set_value";
import {mapGetters} from "vuex";
export default {
    name: "c-adm-user-edit",
    mixins: [admSetValueMixin],
    data() {
        return {
            search_phone: '',
            found_user: null,
            edit_user_key: 1,
            timer: null
        }
    },
    computed: {
      ...mapGetters(['API_URL'])
    },
    methods: {
        searchUser() {
            this.found_user = null;
            axiosClient({
                method: 'GET',
                url: this.API_URL + 'user/search/?login=' + this.search_phone
            }).then((res) => {
                if (res.data.errors !== undefined) {
                    if (res.data.errors.length > 0) {
                        this.showResultModal(res.data)
                    } else {
                        this.found_user = res.data.user
                    }
                } else {
                    this.showResultModal({errors:['Ошибка получения данных.']})
                }
            }).catch(()=>{
                this.showResultModal({errors:['Ошибка получения данных.']})
            }).finally(()=>{
                this.edit_user_key++
            })
        },
        setSearchPhone(value) {
            this.search_phone = value
        },
    },
    components: {
        cAdmUserEditForm,
        'imask-input': IMaskComponent,
    }

}
</script>

<style scoped>

</style>
