<template>
    <div class="admin-next-day">
        <h1 class="admin-content-title">Время запрета отгрузки на следующий день</h1>
        <p class="text-center">Задайте время, позже которого осуществить отгрузку на следующий день невозможно</p>
        <div class="admin-next-day-form">
            <div class="admin-delivery-time-form">
                <imask-input :mask="'00'" type="text" v-model="time_hours" class="input-line"></imask-input>
                :
                <imask-input :mask="'00'" type="text" v-model="time_minutes" class="input-line"></imask-input>
            </div>
            <button @click="setValue" class="button">сохранить</button>
        </div>
    </div>
</template>

<script>
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    import {IMaskDirective, IMaskComponent} from 'vue-imask';
    export default {
        name: "c-adm-next-day-time",
        mixins: [admSetValueMixin],
        directives: {
            IMaskDirective
        },
        data() {
            return {
                time_actual: null,
                time_hours: '',
                time_minutes: '',
                mask: {
                    mask: '00',
                },
            }
        },
        computed: {
            restrict_time(){
                let hours = this.time_hours.length === 1 ? '0' + this.time_hours : this.time_hours;
                let minutes = this.time_minutes.length === 1 ? '0' + this.time_minutes : this.time_minutes;
                return hours + ':' + minutes
            }
        },
        methods: {
            setValue(){
                this.setValueToApi({name: 'next_day_order_time_restrict', value: this.restrict_time})
                    .then(() => {
                        this.showResultModal();
                        this.init()
                    });
            },
            init(){
                this.time_hours = this.USER_INFO.supplier_parameters.next_day_order_time_restrict.split(':')[0];
                this.time_minutes = this.USER_INFO.supplier_parameters.next_day_order_time_restrict.split(':')[1];
                this.time_actual = this.USER_INFO.supplier_parameters.next_day_order_time_restrict
            }
        },
        components: {
            'imask-input': IMaskComponent
        }
    }
</script>

<style scoped>

</style>
