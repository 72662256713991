<template>
    <div class="modal-internal-container">
        <div class="companies-modal-head">
            <p class="companies-modal-head-title">ВЫБОР КОМПАНИИ </p>
            <p class="companies-modal-head-subtitle">пожалуйста, выберите компанию, от лица которой Вы будете действовать:</p>
            <button @click="close" role="button" type="button" class="close">
                <img class="close-cross" src="../../assets/images/x-mark-icon-w.png" alt="">
            </button>
        </div>
        <c-choose-company
            :in_modal="true"
        />
    </div>
</template>

<script>
import cChooseCompany from '@/components/common/cChooseCompany'
import {closeByHashChange} from "@/utils/mixins/close_by_history";
export default {
    name: "c-choose-company-modal",
    mixins: [closeByHashChange],
    components: {
        cChooseCompany
    },
    methods: {
        close(){
            this.$modal.hide('modal_choose_company')
        },
    },
    created() {
        this.window_state = {key: '#company_select'}
    }
}
</script>

<style scoped>

</style>
