<template>
    <c-base />
</template>

<script>
  import cBase from "@/components/cBase";
  import axios from 'axios';
  import {mapGetters} from 'vuex'

  export default {
    name: 'App',
    components: {
      cBase
    },
    computed: {
      ...mapGetters([
        'API_URL'
      ])
    }
  }
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
