<template>
    <div class="modal-internal-container admin-users-company-search">
        <div class="modal-title">
            <button @click="close" class="close" data-dismiss="modal" aria-label="Close">
                <img class="close-cross" src="../../../../assets/images/x-mark-icon-w.png" alt="">
            </button>
            <p>
                Добавить контрагента
            </p>
        </div>
        <div class="admin-users-company-search-container">
            <p class="text-center">Для того, чтобы найти контрагента, введите его ИНН и нажмите кнопку "Искать". Для того, чтобы добавить требуемого контрагента, просто кликните на него в списке.</p>
            <div class="admin-users-company-search-form">
                <input @keypress="pressEnterOnSearch" v-model="inn" ref="input_inn" type="text" class="input-line" placeholder="ИНН">
                <button @click="search" class="button">искать</button>
            </div>
        </div>
        <div class="admin-users-company-search-companies">
            <c-loading v-if="loading" :margin_top="false"></c-loading>
            <template v-if="loaded">
                <ul v-if="companies.length > 0" class="companies">
                    <li @click="select(company)" v-for="(company, index) in companies" :key="index" class="companies-item" :class="{'companies-item-invalid': company.is_problem === 1}">
                        <p class="companies-item-count">{{index + 1}}</p>
                        <div class="companies-item-title-wrapper">
                            <div class="companies-item-title">
                                <template v-if="company.fact_name !== ''">
                                    <p>
                                        {{company.fact_name}}
                                    </p>
                                    <p>
                                        {{'\xa0'}}/{{'\xa0'}}
                                    </p>
                                </template>
                                <p class="companies-item-title-official">
                                    {{company.legal_name}}
                                </p>
                            </div>
                            <p class="companies-item-address">
                                ИНН:{{company.inn}}, КПП: {{company.kpp}}
                            </p>
                        </div>
                        <div v-if="company.is_problem === 1" class="companies-item-warning">
                            <img src="../../../../assets/images/red-triangle-exclamation-mark.png" alt="">
                        </div>
                    </li>
                </ul>
                <p v-else class="text-center">Не найдено ни одной компании!</p>
            </template>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import cLoading from "@/components/common/loading/cLoading";
    import axiosClient from "@/utils/axios/axios";
    import {closeByHashChange} from "@/utils/mixins/close_by_history";

    export default {
        name: "c-adm-search-company-modal",
        props: {
            addCompany: {
                type: Function,
                default() {
                    return () => {}
                }
            }
        },
        mixins: [closeByHashChange],
        data() {
            return {
                inn: '',
                companies: [],
                loading: false,
                loaded: false
            }
        },
        computed: {
            ...mapGetters(['API_URL'])
        },
        methods: {
            pressEnterOnSearch(event) {
                if (event.keyCode === 13) {
                    this.search();
                }
            },
            search() {
                this.loading = true;
                this.loaded = false;
                axiosClient({
                    url: this.API_URL + 'company/search/?inn=' + this.inn,
                    method: 'GET'
                }).then((res) => {
                    this.companies = res.data;
                    this.loading = false;
                    this.loaded = true;
                }).catch(() => {
                    this.loading = false;
                    this.loaded = true;
                })
            },
            select(company){
                if (company.is_problem !== 1) {
                    this.addCompany(company);
                    this.close();
                }
            },
            close() {
                this.$modal.hide('modal_search_company')
            }
        },
        created() {
          this.window_state = {key: '#serch_company'}
        },
        mounted() {
          this.$refs.input_inn.focus();
        },
        components: {
            cLoading
        }
    }
</script>

<style scoped>

</style>
