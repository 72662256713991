<template>
    <div class="catalogue-filters-block-checkbox-wrapper">
        <div class="catalogue-filters-block catalogue-filters-block-checkbox" :class="{'catalogue-filters-block-checkbox-show': show}">
            <div class="catalogue-filters-block-title">
                <span>{{ caption }}</span><span v-if="checkedItem.length > 0" class="catalogue-filters-block-title-count">{{ checkedItem.length }}</span>
                <div @click="show = false" class="cross-close catalogue-filters-block-checkbox-close"></div>
            </div>
            <div class="catalogue-filters-block-checkbox-container">
                <div v-for="item in filter_items" :key="item.id" class="catalogue-filters-block-checkbox-option">
                    <label :for="filter_name + item.id">
                        <input type="checkbox" :id="filter_name + item.id" v-on:change="applyFilter" v-model="checkedItem" :value="name_to_value ? item.name : item.id">
                        <span class="catalogue-filters-block-checkbox-option-checkmark"></span>
                        <span>{{ item.name }}</span>
                    </label>
                </div>
            </div>
            <button @click="show = true" class="catalogue-filters-block-show-button">показать все</button>
        </div>
    </div>
</template>

<script>

export default {
    name: "c-checkbox-filter",
    props: {
        filter_items: {
            type: Array,
            default() {
                return []
            }
        },
        caption: {
            type: String,
            default() {
                return '';
            }
        },
        filter_name: {
            type: String,
            default() {
                return '';
            }
        },
        name_to_value: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    computed: {
        visible: function () {
            return this.filter_items.slice(0, 10)
        },
        hidden: function() {
            return this.filter_items.length > 10 ? this.filter_items.slice(10, this.filter_items.length) : []
        },
        itemCount: function () {
            return this.filter_items.length - 1;
        },
        filter_data: function () {
            return {
                name: this.filter_name,
                value: this.checkedItem.join(';')
            }
        }
    },
    data: function () {
        return {
            checkedItem: [],
            show: false
        }
    },
    methods: {
        applyFilter: function(event) {
            if(event.target.checked === true) {
                this.filter_data.checked = this.filter_name;
                delete this.filter_data.unchecked;
            } else {
                delete this.filter_data.checked;
                this.filter_data.unchecked = this.filter_name
            }
            //console.log(event.target.checked);
            this.$emit('apply-filter', this.filter_data)
        }

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
