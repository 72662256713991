<template>
    <div class="admin-delivery-time">
        <h1 class="admin-content-title">Время доставки</h1>
        <p class="text-center">Выберите возможные диапазоны времени доставки</p>
        <div class="admin-emails-content admin-delivery-time-content">
            <div class="admin-emails-content-block admin-delivery-time-content-block admin-delivery-time-content-block-form">
                <div class="admin-delivery-time-form">
                    С
                    <imask-input
                        :mask="'00'"
                        v-model="time_hours_from"
                        type="text"
                        class="input-line">
                    </imask-input>
                    :
                    <imask-input
                        :mask="'00'"
                        v-model="time_minutes_from"
                        type="text"
                        class="input-line">
                    </imask-input>
                    <span>до</span>
                    <imask-input
                        :mask="'00'"
                        v-model="time_hours_to"
                        type="text"
                        class="input-line">
                    </imask-input>
                    :
                    <imask-input
                        :mask="'00'"
                        v-model="time_minutes_to"
                        type="text"
                        class="input-line">
                    </imask-input>
                </div>
                <button @click="setValue" class="button">добавить</button>
            </div>
            <div class="admin-emails-content-block admin-delivery-time-content-block">
                <p>Выбранные диапазоны:</p>
                <div class="admin-delivery-time-content-block-list">
                    <div
                        v-for="(interval, index) in intervals_actual"
                        :key="index"
                        class="admin-delivery-time-content-block-list-item"
                    >
                        <template v-if="interval.start === ''">
                            <p>до {{interval.end}}</p>
                        </template>
                        <template v-else-if="interval.end === ''">
                            <p>c {{interval.start}}</p>
                        </template>
                        <template v-else>
                            <p>{{interval.start}} — {{interval.end}}</p>
                        </template>
                        <img @click="remove(index)" src="../../../assets/images/remove.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {IMaskComponent} from 'vue-imask';
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    export default {
        name: "c-adm-delivery-time",
        mixins: [admSetValueMixin],
        data() {
            return {
                time_hours_from: '',
                time_minutes_from: '',
                time_hours_to: '',
                time_minutes_to: '',
                intervals: [],
                intervals_actual: []
            }
        },
        methods: {
            setValue(){
                this.intervals.push({
                    start: this.time_hours_from !== '' && this.time_minutes_from !== '' ? this.time_hours_from + ':' + this.time_minutes_from : '',
                    end: this.time_hours_to !== '' && this.time_minutes_to !== '' ? this.time_hours_to + ':' + this.time_minutes_to : ''
                });
                this.setValueToApi({name: 'delivery_time_intervals', value: JSON.stringify(this.intervals)})
                    .then(() => {
                        this.init()
                    });
            },
            remove(index){
                this.intervals.splice(index, 1);
                this.setValueToApi({name: 'delivery_time_intervals', value: JSON.stringify(this.intervals)})
                    .then(() => {
                        this.init()
                    });
            },
            init() {
                this.time_hours_from = '';
                this.time_minutes_from = '';
                this.time_hours_to = '';
                this.time_minutes_to = '';
                this.intervals = [];
                this.intervals_actual = [];
                this.USER_INFO.supplier_parameters.delivery_time_intervals.forEach((el) => {
                    this.intervals.push(el);
                    this.intervals_actual.push(el);
                })
            }
        },
        components: {
            'imask-input': IMaskComponent
        }
    }
</script>

<style scoped>

</style>
