let inputRestrictInt = function(event) {
    let unrestricted = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    let regexp = /[0-9]/;
    if (event.key.match(regexp) === null && unrestricted.indexOf(event.key) === -1) {
        event.preventDefault();
    }
};

let inputRestrictFloat = function(event) {
    let unrestricted = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    let regexp = /[0-9.]/;
    if (event.key.match(regexp) === null && unrestricted.indexOf(event.key) === -1) {
        event.preventDefault();
    }
};

export {inputRestrictInt, inputRestrictFloat}