let Company = {
    id: 0,
    fact_name: '',
    legal_name: '',
    fact_address: '',
    legal_address: '',
    inn: 0,
    kpp: 0,
    active: 0,
    contract: {
        id: 0,
        number: '',
        date: '',
        expire: '',
        strength: 0
    },
    license: {
        id: 0,
        number: '',
        date: '',
        expire: ''
    },
    payment_type: {
        id: 0,
        name: ''
    },
    payment_condition: {
        id: 0,
        name: ''
    },
    manager: {
        id: 0,
        name: '',
        phone: '',
        email: ''
    },
    price_list_id: 0,
    delivery_zone: {
        zone_id: 0,
        name: '',
        delivery_days: [
            0
        ]
    },
    ignore_strength: 0
};

export default Company