<template>
    <div class="page-loading">
        <div class="spinner-border" :class="{'mt-5': margin_top}">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "c-loading",
        props: {
            margin_top: {
                type: Boolean,
                default() {
                    return true
                }
            }
        }
    }
</script>

<style scoped>
    .page-loading {
        display: flex;
        justify-content: center;
        height: 100%;
        background-color: transparent;
    }
    .page-loading .spinner-border {
        width: 4rem;
        height: 4rem;
        color: #2196f3;
    }
</style>