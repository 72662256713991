<template>
    <div class="admin-expiration">
        <h1 class="admin-content-title">Предупреждение об истечении срока лицензии и договора</h1>
        <p class="text-center">Выберите, за какое количество дней пользователь должен получить уведомление об истечении срока лицензии и договора</p>
        <div class="admin-users-edit-form-item admin-orders-sum-form admin-expiration-form">
            <input @keydown="inputRestrictInt" v-model="warn_days" type="text" class="input-line">
            <button @click="setValue" class="button">сохранить</button>
        </div>
    </div>
</template>

<script>
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    import {inputRestrictInt} from "@/utils/input_restrict";
    import {IMaskDirective} from 'imask';
    export default {
        name: "c-adm-expiration-warn",
        mixins: [admSetValueMixin],
        data() {
            return {
                warn_days: '',
                warn_days_actual: '',
            }
        },
        methods: {
            setValue(){
                this.setValueToApi({name: 'license_expire_notification_days', value: parseInt(this.warn_days)})
                    .then(() => {
                        this.showResultModal();
                        this.init()
                    });
            },
            init(){
                this.warn_days = this.USER_INFO.supplier_parameters.license_expire_notification_days.toString();
                this.warn_days_actual = this.warn_days
            },
            inputRestrictInt: inputRestrictInt
        }
    }
</script>

<style scoped>

</style>
