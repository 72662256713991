<template>
    <div class="admin-current-day">
        <h1 class="admin-content-title">Заказ и отгрузка день в день</h1>
        <p class="text-center">Задайте день недели и время, не позднее которого и когда пользователь сможет осуществить отгрузку в этот же день</p>
        <div class="admin-current-day-form">
            <div class="admin-current-day-form-days">
                <div v-for="item in weekdays" :key="item.value" class="checkbox">
                    <label>
                        <input @change="handleCheck" type="checkbox" :value="item.value">
                        <span class="checkbox-checkmark"></span>
                        <span>{{item.name}}</span>
                    </label>
                </div>
            </div>
            <div class="admin-next-day-form">
                <div class="admin-delivery-time-form">
                    <imask-input :mask="'00'" v-model="time_hours" type="text" class="input-line"></imask-input>
                    :
                    <imask-input :mask="'00'" v-model="time_minutes" type="text" class="input-line"></imask-input>
                </div>
                <button @click="setValue" class="button">сохранить</button>
            </div>
        </div>
        <div class="admin-current-day-values">
            <p class="text-center">Текущие значения</p>
            <div class="admin-restrict-days-selected-container">
                <div v-for="(item, index) in weekdays_actual" :key="index" class="admin-restrict-days-selected-item">
                    <p>
                        <span class="admin-restrict-days-selected-item-year">{{getDayName(item.weekday)}}</span>
                    </p>
                    <p class="nowrap">до {{item.time}}</p>
                    <img @click="remove(item.weekday)" src="../../../assets/images/remove.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    import {IMaskComponent} from 'vue-imask';
    export default {
        name: "c-adm-current-day-shipment",
        mixins: [admSetValueMixin],
        data() {
            return {
                weekdays: [
                    {name: 'пн', value: '1'},
                    {name: 'вт', value: '2'},
                    {name: 'ср', value: '3'},
                    {name: 'чт', value: '4'},
                    {name: 'пт', value: '5'},
                    {name: 'сб', value: '6'},
                    {name: 'вс', value: '7'},
                ],
                selected_weekdays: [],
                weekdays_actual: [],
                time_hours: '',
                time_minutes: ''
            }
        },
        methods: {
            handleCheck(event) {
                if (event.target.checked) {
                    if (this.selected_weekdays.includes(event.target.value) === false) {
                        this.selected_weekdays.push(event.target.value)
                    }
                } else {
                    this.selected_weekdays = this.selected_weekdays.filter((el) => {return el !== event.target.value})
                }
            },
            getDayName(number) {
              return this.weekdays.filter((el) => {return el.value === number.toString()})[0].name
            },
            setValue(){
                if (this.selected_weekdays.length > 0 && this.time_minutes !== '' && this.time_hours !== '') {
                    let data = [];
                    this.selected_weekdays.forEach((el) => {
                        let new_obj = {weekday: parseInt(el), time: this.time_hours + ':' + this.time_minutes};
                        data.push(new_obj);
                    });
                    this.weekdays_actual.forEach((el) => {
                       if (data.filter(d_el => d_el.weekday === el.weekday).length === 0) {
                           data.push(el)
                       }
                    });
                    data.sort((first, second) => parseInt(first.weekday) >= parseInt(second.weekday) ? 1 : -1);
                    this.setValueToApi({name: 'current_day_shipment_cond', value: JSON.stringify(data)})
                        .then(() => {
                            this.init()
                        });
                }
            },
            remove(item) {
                let data = this.weekdays_actual;
                data = data.filter((el) => el.weekday.toString() !== item.toString());
                this.setValueToApi({name: 'current_day_shipment_cond', value: JSON.stringify(data)})
                    .then(() => {
                        this.init()
                    });
            },
            init(){
                this.weekdays_actual = this.USER_INFO.supplier_parameters.current_day_shipment_cond
            }
        },
        components: {
            'imask-input': IMaskComponent
        }
    }
</script>

<style scoped>

</style>
