<template>
    <div class="modal-internal-container profile-change-password">
        <div class="profile-change-password-title">
            <p>Создайте новый пароль:</p>
            <button @click="close" class="close" data-dismiss="modal" aria-label="Close">
                <img class="close-cross" src="../../assets/images/x-mark-icon-w.png" alt="">
            </button>
        </div>

        <div class="profile-change-password-form">
            <input @input="saved = false" :class="{'input-invalid-input': !success && current_password === ''}" v-model="current_password" type="password" class="input" placeholder="Текущий пароль">
            <input @input="saved = false" :class="{'input-invalid-input': !success && new_password === ''}" v-model="new_password" type="password" class="input" placeholder="Новый пароль">
            <input @input="saved = false" :class="{'input-invalid-input': !success && confirm_password === ''}" v-model="confirm_password" type="password" class="input" placeholder="Новый пароль ещё раз">
        </div>
        <p v-if="saved && success" class="mt-1 text-center">Пароль успешно сохранен!</p>
        <p v-else-if="errors.length > 0" class="mt-1 text-center login-failed">{{errors.join(' ')}}</p>
        <div class="profile-change-password-button">
            <button @click="savePassword" v-if="!saved || errors.length > 0" class="button button-fixed-150">
                <span v-if="loading" class="spinner-border spinner-in-button" role="status" aria-hidden="true"></span>
                <template v-else>сохранить</template>
            </button>
        </div>
    </div>
</template>

<script>
import axiosClient from "@/utils/axios/axios";
import cLoadingOverlayPage from "@/components/common/loading/cLoadingOverlayPage";
import {mapGetters} from 'vuex';

export default {
    name: "c-password-change-modal",
    data(){
        return {
            current_password: '',
            new_password: '',
            confirm_password: '',
            errors: [],
            saved: false,
            success: true,
            loading: false,
        }
    },
    computed: {
        ...mapGetters(['API_URL'])
    },
    methods: {
        savePassword(){
            this.saved = false;
            this.success = false;
            if (this.current_password !== '' && this.new_password !== '' && this.confirm_password !== '') {
                let formData = new FormData();
                formData.append('current_password', this.current_password);
                formData.append('new_password', this.new_password);
                formData.append('confirm_password', this.confirm_password);
                this.loading = true;
                axiosClient({
                    method: 'POST',
                    url: this.API_URL + 'user/change/',
                    data: formData
                }).then((res) => {
                    if (res.data.errors.length > 0) {
                        this.errors = res.data.errors
                    } else {
                        this.current_password = '';
                        this.new_password = '';
                        this.confirm_password = '';
                        this.success = true;
                        this.errors = []
                        this.$modal.hide('modal_save_password')
                    }
                    this.saved = true;
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                })
            } else {
                this.success = false
            }
        },
        close() {
            this.$modal.hide('modal_save_password')
        },
    },
    components: {
        // cLoadingOverlayPage
    }
}
</script>

<style scoped>

</style>
