import moment from "moment";
import cLicenceNotificationModal from "@/components/common/cLicenceNotificationModal";

let checkLicenceMixin = {
    methods: {
        checkLicence() {
            let expire = moment(this.USER_INFO.active_company_info.license.expire, 'DD.MM.YYYY');
            let days_diff = Math.round(moment.duration(expire.diff(moment())).asDays());
            let notification = days_diff <= this.USER_INFO.supplier_parameters.license_expire_notification_days;
            if (notification === true) {
                this.$modal.show(
                    cLicenceNotificationModal,
                    {
                        expire_date: expire,
                        days_diff: days_diff
                    },
                    {
                        name: 'modal_licence_notification',
                        resizable: true,
                        scrollable: true,
                        height: 'auto',
                        classes: ['modal-external-container', 'modal-thank-you-error-message']
                    }
                )
            }
        }
    }
};

export default checkLicenceMixin