<template>
    <div class="select" :class="{'select-open': select_open}" ref="select_container">
        <div @click="toggle" class="select-current" :class="{'select-open': select_open}">
            <img v-if="current_option.picture !== ''" class="select-current-img" :src="current_option.picture" alt="">
            <div v-if="caption !== ''" class="select-current-title">{{caption + ': ' + current_option.caption}}</div>
            <div v-else class="select-current-title">{{current_option.caption}}</div>
            <svg class="select-current-dropdown-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z" fill="#000000"/>
            </svg>
        </div>
        <div ref="select_options" class="select-options" :class="{'d-none': !select_open}">
            <div
                v-for="(option, index) in options"
                :key="index"
                @click="select(option)"
                class="select-options-option"
            >
                <img v-if="option.picture !== ''" :src="option.picture" alt="">
                <div class="sort-by-option-parameter">
                    <p class="">{{option.caption}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eventHub from "@/utils/event_hub";
export default {
    name: "c-select",
    props: {
        options: {
            type: Array,
            default() {
                return []
            }
        },
        current_value: {
            type: [String, Number, Object],
            default() {
                return null
            }
        },
        caption: {
            type: String,
            default() {
                return ''
            }
        },
    },
    data() {
        return {
            select_open: false,
            current_option: {
                picture: '',
                caption: '',
                value: null
            }
        }
    },
    methods: {
        select(option) {
            this.current_option = option;
            this.select_open = false;
            this.$emit('option-selected', option)
        },
        closeByOuterClick(event) {
            if (!event.composedPath().includes(this.$refs.select_options) &&
                !event.composedPath().includes(this.$refs.select_container) &&
                this.select_open === true
            ) {
                this.select_open = false;
            }
        },
        toggle() {
            this.select_open = !this.select_open;
        }
    },
    mounted() {
        if (this.options.length > 0) {
            if (this.current_value !== null) {
                this.current_option = this.options.filter(item => item.value === this.current_value)[0]
            } else {
                this.current_option = this.options[0];
            }
        }
        document.addEventListener('mousedown', this.closeByOuterClick);
        eventHub.$on('toggle-select', this.toggle)
    },
    destroyed() {
        document.removeEventListener('mousedown', this.closeByOuterClick);
        eventHub.$off('toggle-select', this.toggle)
    }
}
</script>

<style scoped>

</style>
