<template>
    <div class="admin-emails">
        <h1 class="admin-content-title">Почтовые ящики</h1>
        <p class="text-center">Почтовые ящики для отправки сообщений формы обратной связи:</p>
        <div class="admin-emails-content">
            <div class="admin-emails-content-block admin-emails-content-form">
                <div class="admin-users-edit-form-item">
                    <img src="../../../assets/images/E-mail_.png" alt="">
                    <input v-model="new_email" type="text" class="input-line" name="E-mail" placeholder="name@mail.com">
                    <button class="button" @click="setValue">добавить</button>
                </div>
            </div>

            <div class="admin-emails-content-block admin-emails-content-list">
                    <p>Сейчас указаны следующие почтовые ящики:</p>
                    <ul v-if="emails.length > 0" class="admin-users-edit-companies-list">
                        <li
                                v-for="(email, index) in emails"
                                :key="index"
                        >
                            <p>{{email}}</p>
                            <img @click="remove(email)" src="../../../assets/images/remove.png" alt="">
                        </li>
                    </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import admSetValueMixin from "@/utils/mixins/adm_set_value";
    export default {
        name: "c-adm-notification-emails",
        mixins: [admSetValueMixin],
        data() {
            return {
                emails: [],
                emails_actual: [],
                new_email: ''
            }
        },
        methods: {
            setValue(){
                if (this.new_email !== '') {
                    this.emails.push(this.new_email);
                }
                this.new_email = '';
                this.setValueToApi({name: 'notification_emails', value: JSON.stringify(this.emails)})
                    .then(() => {
                        this.init()
                    });
            },
            remove(email) {
              this.emails = this.emails.filter((el) => {return el !== email});
              this.setValue(false)
            },
            init(){
                this.emails = this.USER_INFO.supplier_parameters.notification_emails;
                this.emails_actual = this.USER_INFO.supplier_parameters.notification_emails
            }
        }
    }
</script>

<style scoped>

</style>
