export function initStorage(name, default_value) {
    if (localStorage[name] === undefined) {
        saveToStorage(name, default_value)
    }
}

export function saveToStorage(name, value) {
    if (value !== null && typeof(value) === 'object') {
        localStorage[name] = JSON.stringify(value)
    } else {
        localStorage.setItem(name, value);
    }
}

export function loadFromStorage(name, type) {
    if (type === Object) {
        return JSON.parse(localStorage.getItem(name))
    } else {
        return localStorage.getItem(name)
    }
}
