import cProductDetail from "@/components/products/cProductDetail";

let openDetailMixin = {
    methods: {
        openDetailProduct(product_id, onClose = function(){}) {
            this.$modal.show(
                cProductDetail,
                {
                    product_id: product_id
                },
                {
                    name: 'modal_' + product_id,
                    resizable: true,
                    scrollable: true,
                    height: 'auto',
                    classes: ['modal-external-container', 'modal-product-details', 'modal-mobile-fullscreen']
                },
                {
                    'closed': onClose
                }
            )
        },
    }
}
export default openDetailMixin
