<template>
    <div class="modal-internal-container">
        <h1>Спасибо за Ваше обращение!</h1>
        <p class="text-center">Мы обязательно свяжемся с вами в ближайшее время.</p>
        <div class="modal-confirmation-buttons">
            <button @click="close" class="button" aria-label="Exit">закрыть</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "c-order-result-modal",
        methods: {
            close() {
                this.$modal.hide('modal_feedback_result')
            },
        }
    }
</script>

<style scoped>

</style>
