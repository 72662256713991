<template>
    <c-loading v-if="first_load">
    </c-loading>
    <div v-else class="catalogue">
        <c-side-filters
                :filter-sets="PRODUCT_LIST.filter_sets"
                :key="filtersKey"
                v-on:apply-filter="onApplyProductFilter"
                v-on:clear-filters="clearFilters"
        ></c-side-filters>
        <div class="catalogue-main">
            <c-search-bar
                    v-on:apply-filter="onApplyProductFilter"
                    v-on:toggle-view="toggleCatalogView"
                    :catalog_view_row="rowView"
                    :key="filtersKey"
            >
            </c-search-bar>
            <c-loading v-if="first_load">
            </c-loading>
            <div v-else class="catalogue-main-container" :class="{'catalogue-main-container-lineview' : rowView}">
                <c-loading-overlay-page :fixed="true" v-if="loading"></c-loading-overlay-page>
                <template v-if="!rowView && PRODUCT_LIST.products.length > 0">
                    <c-product-item
                            v-for="product_item in PRODUCT_LIST.products"
                            :key="product_item.id"
                            v-bind:product_item="product_item"
                    >
                    </c-product-item>
                    <div
                            v-for="n in dummyCardCount"
                            :key="Math.random()*n"
                            class="catalogue-main-container-item catalogue-main-container-item-dummy">
                    </div>
                </template>
                <template v-else-if="rowView && PRODUCT_LIST.products.length > 0">
                    <c-product-item-row
                            v-for="product_item in PRODUCT_LIST.products"
                            :key="product_item.id"
                            v-bind:product_item="product_item"
                    >
                    </c-product-item-row>
                </template>
                <template v-else>
                    <div class="catalogue-main-container-empty">
                        <p>Нет товаров, удовлетворяющих условиям поиска!</p>
                        <button class="button" type="button" role="button" @click="resetCatalog">перейти в каталог</button>
                    </div>
                </template>
            </div>
            <div v-if="PRODUCT_LIST.total_pages > 1" class="pagination">
                <div class="pagination-show-more">
                    <button @click="showMore" type="button" role="button" class="button button-fixed-200">
                        <span v-if="show_more_loading" class="spinner-border spinner-in-button" role="status" aria-hidden="true"></span>
                        <span v-else class="pagination-show-more-text">показать еще</span>
                    </button>
                </div>
                <p>Страницы: </p>
                <c-pagination
                        v-on:apply-filter="onApplyProductFilter"
                        :current_page="PRODUCT_LIST.current_page"
                        :total_pages="PRODUCT_LIST.total_pages"
                ></c-pagination>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters, mapActions, mapMutations} from 'vuex';
    import cSideFilters from "@/components/products/filters/cSideFilters";
    import cSearchBar from "@/components/products/subheader/cSearchBar";
    import cLoading from "@/components/common/loading/cLoading";
    import cLoadingOverlayPage from "@/components/common/loading/cLoadingOverlayPage";
    import cProductItem from "@/components/products/cProductItem";
    import cProductItemRow from "@/components/products/cProductItemRow";
    import cPagination from "@/components/common/cPagination";
    import eventHub from "@/utils/event_hub";
    import chatraMixin from "@/utils/mixins/chatra";
    import checkLicenceMixin from "@/utils/mixins/check_licence";

    export default {
        name: "c-product-list",
        computed: {
            ...mapGetters([
                'PRODUCT_LIST',
                'WINDOW_WIDTH',
                'USER_INFO',
                'CURRENT_FILTER'
            ]),

        },
        methods: {
            ...mapActions([
                'GET_PRODUCT_LIST'
            ]),
            ...mapMutations([
                'SET_ERROR',
                'SET_CURRENT_FILTER'
            ]),

            /**
             * Применение фильтров. filter может быть массивом объектов {name: '', value: ''}, а может и отдельным
             * объектом.
             * Далее формируется объект this.filter (из data компонента). В качестве ключей - имена фильтров, в качестве
             * значений - значения фильтров. Этот объект отправляется в запросе к API.
             * Этот метод вызывается из дочерних компонентов с филттрами посредством событий.
             * @param filter
             */
            onApplyProductFilter(filter) {
                this.loading = true;
                if (Array.isArray(filter)) {
                    for (let i=0; i<filter.length; i++) {
                        if (filter[i].value !== '' && filter[i].value !== undefined) {
                            this.filter[filter[i].name] = filter[i].value
                        } else {
                            delete this.filter[filter[i].name]
                        }
                    }
                } else {
                    if (filter.value !== '' && filter.value !== undefined) {
                        this.filter[filter.name] = filter.value;
                    } else {
                        delete this.filter[filter.name]
                    }
                    if (filter.checked !== undefined && filter.checked !== '') {
                        this.filter.checked = filter.name;
                    } else {
                        delete this.filter.checked
                    }
                    if (filter.unchecked !== undefined && filter.unchecked !== '') {
                        this.filter.unchecked = filter.name;
                    } else {
                        delete this.filter.unchecked
                    }
                }
                this.SET_CURRENT_FILTER(this.filter);
                this.loadData();
            },

            /**
             * Вызывается при первой загрузке страницы
             */
            firstLoad() {
                this.filter = {};
                this.first_load = true;
                this.loadData()
            },
            /**
             * Загрузка данных из API с использованием фильтров
             * А также перезагрузка виджета чатры - иначе у него глючит позиционирование.
             * В случае ошибки сервера (500), показываем сообщение об ошибке на весь экран.
             */
            loadData() {
                let search_string = this.$route.query.search;
                if (search_string !== undefined && search_string !== '') {
                    this.filter.search = search_string
                }
                this.GET_PRODUCT_LIST(this.filter)
                    .then((response) => {
                            if (this.first_load) {
                                window.Chatra('hide');
                                window.Chatra('show');
                            }
                            this.first_load = false;
                            this.loading = false;
                            this.show_more_loading = false;
                            this.updateDummyCount();
                        }
                    )
                    .catch((error) => {
                        if (error.response.status >= 500) {
                            this.SET_ERROR(true)
                        }
                    });
            },
            /**
             * Сброс фильтров. Перемонтровнаие компонентов с фильтрами посредством увеличения значения ключа компонента.
             */
            clearFilters() {
                this.filter = {};
                this.CURRENT_FILTER = {};
                this.loading = true;
                this.loadData();
                this.filtersKey++
            },
            /**
             * Хак с карточками-заглушками в режиме tile-view. Если в последней строке карточек меньше, чем максимальное
             * количество в строке, добавляем заглушки, чтобы карточки позиционировались от левого края.
             */
            updateDummyCount() {
                let productsOnCurrentPage = this.PRODUCT_LIST.products.length;
                if (this.WINDOW_WIDTH >= 1400) {
                    let rowCount = 4;
                    this.dummyCardCount = productsOnCurrentPage % rowCount > 0 ? rowCount - productsOnCurrentPage % rowCount : 0
                } else if (
                    this.WINDOW_WIDTH >= 993 && this.WINDOW_WIDTH < 1401 ||
                    this.WINDOW_WIDTH >= 577 && this.WINDOW_WIDTH < 769
                ) {
                    let rowCount = 3;
                    this.dummyCardCount = productsOnCurrentPage % rowCount > 0 ? rowCount - productsOnCurrentPage % rowCount : 0
                } else if (
                    this.WINDOW_WIDTH >= 769 && this.WINDOW_WIDTH < 993 ||
                    this.WINDOW_WIDTH >= 441 && this.WINDOW_WIDTH < 577
                ) {
                    let rowCount = 2;
                    this.dummyCardCount = productsOnCurrentPage % rowCount > 0 ? rowCount - productsOnCurrentPage % rowCount : 0

                } else {
                    this.dummyCardCount = 0
                }
            },
            /**
             * Переключение tile-view/line-view
             * За оба этих представления отвечают разные компоненты карточки товара.
             * @param isRow
             */
            toggleCatalogView(isRow) {
                this.rowView = isRow
            },
            search: function (e) {
                clearTimeout(this.timer);
                this.timer = setTimeout(function(){
                    this.onApplyProductFilter({
                        name: 'search',
                        value: e.target.value
                    })
                }.bind(this), 500)
            },
            showMore() {
                this.show_more_loading = true;
                this.filter.on_page = this.PRODUCT_LIST.on_page + 36;
                this.loadData();
            },
            resetCatalog() {
                this.filter = {};
                this.SET_CURRENT_FILTER(this.filter);
                this.$router.push({name: 'products'})
            }
        },
        data() {
            return {
                filter: {},
                loading: false,
                show_more_loading: false,
                first_load: false,
                filter_show: null,
                filtersKey: 0,
                dummyCardCount: 0,
                rowView: localStorage['catalog_view'] === 'row',
                search_string: '',
                timer: undefined,
                product_key: 1,
            }
        },
        components: {
            cSearchBar,
            cLoading,
            cLoadingOverlayPage,
            cProductItem,
            cProductItemRow,
            cSideFilters,
            cPagination
        },
        mounted() {
            //Начинаем загрузку товаров только после того, как загружена информация о пользователе
            eventHub.$on('user-loaded', this.firstLoad);
            this.updateDummyCount();
        },
        destroyed() {
            eventHub.$off('user-loaded', this.firstLoad);
        }
    }
</script>

<style scoped>

</style>
