export default {
    TOKEN(state) {
        return state.token
    },
    /**
     * @return {string}
     */
    API_URL(state) {
      return state.api
    },
    /**
     * @return {boolean}
     */
    LOGGED(state) {
      return state.logged;
    },
    ERROR(state) {
        return state.error;
    },
    /**
     * @return {number}
     */
    WINDOW_WIDTH(state) {
        return state.window_width
    },
    USER_INFO(state) {
        return state.user_info
    },
    ORDER_LIST(state) {
        return state.order_list
    },
    ORDER_DETAIL(state) {
        return state.order_detail
    },
    PRODUCT_LIST(state) {
        return state.product_list
    },
    FAVORITE_LIST(state) {
      return state.favorite_list
    },
    CART(state) {
        return state.cart
    },
    CART_LOADING(state) {
        return state.cart_loading
    },
    FINANCE(state) {
        return state.finance
    },
    ORDER_DRAFTS(state) {
        return state.order_drafts
    },
    ORDER_DRAFT_DETAIL(state) {
        return state.order_draft
    },
    CART_PAGE(state) {
        return state.cart_page
    },
    MOBILE_MENU_OPEN(state) {
        return state.mobile_menu_open
    },
    CURRENT_FILTER(state) {
        return state.current_filter
    }
}
