<template>
    <c-loading v-if="first_load"></c-loading>
    <div v-else class="page orders">
        <div class="tabs">
            <ul>
                <li>
                    <input type="radio" id="created_orders" name="tab-control" value="placed" v-model="active_tab" checked>
                    <label for="created_orders">
                        Оформленные заказы
                    </label>
                    <div class="tabs-slider">
                        <div class="tabs-slider-indicator"></div>
                    </div>
                </li>
                <li>
                    <input type="radio" id="draft_orders" name="tab-control" value="drafts" v-model="active_tab">
                    <label for="draft_orders">
                        Отложенные заказы
                    </label>
                    <div class="tabs-slider">
                        <div class="tabs-slider-indicator"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="orders-content">
            <section v-if="active_tab === 'placed'">
                <div class="orders-search">
                    <div class="orders-search-filter">
                        <c-select
                            :options="select_items"
                            @option-selected="applyConditionFilter"
                            :caption="'Статус'"
                        ></c-select>
                    </div>
                    <div class="orders-search-search">
                        <input
                            v-model="search"
                            @input="applySearch"
                            type="text"
                            class="input"
                            placeholder="Поиск"
                            name="search"
                        >
                        <svg data-v-20a05a50="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="input-icon"><path data-v-20a05a50="" d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
                    </div>
                </div>
                <p class="orders-content-title">Выберите диапазон:</p>
                <div class="orders-dates">
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :wrapper-class="'orders-dates-item'"
                        :format="'dd.MM.yyyy'"
                        :language="ru"
                        :monday-first="true"
                        v-model="dateFrom"
                        @input="applyDateFilter"
                        @opened="fixDatePicker"
                    > </datepicker>
                    <datepicker
                        :calendar-class="'datepicker-calendar-fix'"
                        :input-class="'input input-date'"
                        :wrapper-class="'orders-dates-item'"
                        :format="'dd.MM.yyyy'"
                        :language="ru"
                        :monday-first="true"
                        v-model="dateTo"
                        @input="applyDateFilter"
                        @opened="fixDatePicker"
                    > </datepicker>
                </div>
                <div class="orders-content-table-wrapper position-relative">
                    <div v-if="loading" class="cart-loading">
                        <div class="spinner-border mt-5" style="width: 2rem; height: 2rem; color: #2196f3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <table class="orders-content-table">
                        <thead>
                        <tr>
                            <th>номер заказа</th>
                            <th>дата создания</th>
                            <th>дата заказа</th>
                            <th>дата доставки</th>
                            <th>состояние заказа</th>
                            <th>стоимость, руб.</th>
                            <th>долг, руб.</th>
                        </tr>
                        </thead>
                        <tbody>
                        <c-order-item
                            v-for="order in ORDER_LIST.orders"
                            :key="order.id"
                            :order="order"
                        >
                        </c-order-item>
                        </tbody>
                    </table>
                </div>
            </section>
            <section v-if="active_tab === 'drafts'">
                    <div class="orders-content-table-wrapper">

                        <table class="orders-content-table">
                            <thead>
                            <tr>
                                <th>номер заказа</th>
                                <th>дата создания</th>
                                <th>кол-во товаров</th>
                                <th>удалить</th>
                            </tr>
                            </thead>
                            <tbody>
                            <c-order-draft-item
                                v-for="draft in ORDER_LIST.drafts"
                                :key="draft.id"
                                :order_draft="draft"
                                v-on:delete-draft="deleteDraft"
                            >
                            </c-order-draft-item>
                            </tbody>
                        </table>
                    </div>
            </section>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import cOrderItem from "@/components/orders/cOrderItem";
import cOrderDraftItem from "@/components/orders/cOrderDraftItem";
import cSelect from "@/components/common/cSelect";
import eventHub from "@/utils/event_hub";
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale';
import formatDateFromDate from "@/utils/filters/formatDateFromDate";
import cLoading from "@/components/common/loading/cLoading";

export default {
    name: "c-order-list",
    computed: {
        ...mapGetters([
            'ORDER_LIST',
            'WINDOW_WIDTH'
        ])
    },
    methods: {
        ...mapActions([
            'GET_ORDER_LIST',
            'DELETE_ORDER_DRAFT'
        ]),
        ...mapMutations(['SET_ERROR']),
        onApplyOrderFilter(filter) {
            if (filter.value !== undefined && filter.value !== '') {
                this.loading = true;
                this.filter[filter.name] = filter.value;
            } else {
                delete this.filter[filter.name]
            }
            this.loadData()
        },
        /**
         * Фильтр по статусу
         */
        applyConditionFilter(selected_option) {
            this.loading = true;
            if (selected_option.value) {
                this.filter.conditions = selected_option.value;
            } else {
                delete this.filter.conditions;
            }
            this.loadData()
        },
        /**
         * Фильтр по датам
         */
        applyDateFilter() {
            this.loading = true;
            this.filter.date_from = moment(this.dateFrom).format('DD.MM.YYYY');
            this.filter.date_to = moment(this.dateTo).format('DD.MM.YYYY');
            this.loadData()

        },
        /**
         * Поиск по номеру заказа. Используется задержка при отправке запроса, чтобы не спамить сервер на ввод каждой буквы.
         */
        applySearch: function (e) {
            this.loading = true;
            clearTimeout(this.timer);
            this.filter.search = e.target.value;
            this.timer = setTimeout(this.loadData, 500)
        },
        firstLoad() {
            this.first_load = true;
            this.conditionFilter = '';
            this.search = '';
            this.dateFrom = moment().subtract(3, 'months').toDate();
            this.dateTo = moment().toDate();
            this.filter = {
                date_from: moment(this.dateFrom).format('DD.MM.YYYY'),
                date_to: moment(this.dateTo).format('DD.MM.YYYY')
            };
            this.loadData()
        },
        /**
         * Получение данных из API. В случае ошибки 500 появляется сообщение об ошибке на весь экран.
         */
        loadData() {
            this.GET_ORDER_LIST(this.filter)
                .then((response) => {
                    this.first_load = false;
                    this.loading = false;
                    this.select_items = [{
                        caption: 'Любой',
                        picture: '',
                        value: ''
                    }];
                    this.ORDER_LIST.filter_sets.checkboxes[0].items.forEach((item) => {
                        this.select_items.push({
                            picture: '',
                            caption: item.name,
                            value: item.id
                        })
                    })
                })
                .catch((error) => {
                    if (error.response.status >= 500) {
                        this.SET_ERROR(true)
                    }
                });
        },
        /**
         * Удаление отложенного заказа. Вызывается с помощью события из компонента cOrderDraftItem
         * @param draft_id
         */
        deleteDraft(draft_id) {
            this.loading = true;
            this.DELETE_ORDER_DRAFT(draft_id)
                .then(() => {
                    this.loadData()
                })
        },
        fixDatePicker(event) {
            console.log('asfdgad');
        }
    },
    data() {
        return {
            filter: {},
            loading: false,
            first_load: false,
            conditionFilter: '',
            dateFrom: moment().subtract(3, 'months').toDate(),
            dateTo: moment().toDate(),
            ru: ru,
            timer: null,
            search: '',
            select_items: [],
            active_tab: 'placed'
        }
    },
    filters: {
        formatDateFromDate
    },
    mounted() {
        eventHub.$on('user-loaded', this.firstLoad);
    },
    destroyed() {
        eventHub.$off('user-loaded', this.firstLoad);
    },
    components: {
        // cCheckboxFilter,
        cOrderItem,
        cOrderDraftItem,
        Datepicker,
        cLoading,
        cSelect
        // cSearchBar,
        // cPagination
    }

}
</script>

<style scoped>

</style>
