<template>
    <tr :class="{'admin-users-list-table-blocked': user_company_item.user_active === 0, 'admin-users-list-table-invalid': user_company_item.active === 0}">
        <td>{{user_company_item.fact_name}}</td>
        <td>{{user_company_item.legal_name}}</td>
        <td>{{user_company_item.inn}}</td>
        <td>{{user_company_item.user_phone | formatPhone}}</td>
        <td>{{user_company_item.user_email}}</td>
        <td>{{user_company_item.user_name}}</td>
        <td>{{user_company_item.manager_name}}</td>
    </tr>
</template>

<script>
    import formatPhone from "@/utils/filters/formatPhone";
    export default {
        name: "c-adm-user-company-item",
        props: {
            user_company_item: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        filters: {
            formatPhone
        }
    }
</script>

<style scoped>

</style>
